import React, { useContext, useEffect, useState } from 'react';
import classes from './workRightTable.module.css';
import WorkRightTableRow from './workRightTableRow';
import WorkRightTableHeader from './workRightTableHeader';
import { wrapContext } from '../../../Contexts/contexts';
import ImagePopupComponent from '../../../Widgets/ImagePopup/ImagePopupComponent';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import WidgetDateTimePicker from '../../../Widgets/WidgetDateTimePicker';
import dayjs from 'dayjs';
import { getTypeSamples } from '../../../../services/list.services';
import Tree from '../../WorkRightStorageDir/WorkRightStorageAddMenu/Tree/tree';
import dropStyles from '../../WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css';
import arrowSVG from '../../../../assets/svg/arrow-01.svg';
import { getStorages } from '../../../../services/storage.services';
import { COLORS } from '../../../../config';
import Icon from '../../../../helpers/components/Icon';
import { getSampleTypes } from '../../../../services/sample.services';
import ExportModal from './exportModal';
import PrintSizeModal from './printSizeModal';
import { getProfile } from '../../../../services/user.services';

const filterFieldsDefault = [
  { id: 'name', name: 'Наименование', type: 'string' },
  { id: 'volume', name: 'Количество', type: 'float' },
  { id: 'created_at', name: 'Дата забора', type: 'date' },
  { id: 'expire_date', name: 'Срок годности', type: 'date' },
  { id: 'updated_at', name: 'Изменен', type: 'date' },
  { id: 'type', name: 'Тип образца', type: 'string' },
  { id: 'location', name: 'Хранилище', type: 'string' },
  { id: 'barcode', name: 'Штрихкод', type: 'string' },
  { id: 'position', name: 'Позиция', type: 'string' },
  { id: 'pack', name: 'Штатив', type: 'string' },
  { id: 'id_general', name: 'ID образца', type: 'integer' },
  { id: 'status', name: 'Статус', type: 'string' },
  { id: 'source', name: 'Источник образца', type: 'string' },
];

const TreeDropDown = ({ treeLocation, setValue, value }) => {
  const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = useState(false);

  return (
    <div className={classes.filterSelector}>
      <input
        className={dropStyles.infoItemValue}
        value={value ?? ''}
        readOnly={true}
        onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}
      />
      <button
        className={dropStyles.infoItemArrow}
        onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}
      >
        <img src={arrowSVG} alt="" />
      </button>
      {isVisiblePositionDropdown && (
        <>
          <div
            className={dropStyles.DropdownBlockAll}
            onClick={() => setIsVisiblePositionDropdown(false)}
          ></div>
          <div className={dropStyles.TreeBlock}>
            <Tree
              storages={treeLocation}
              setVisibleTreeLocation={setIsVisiblePositionDropdown}
              setLocation={setValue}
            />
          </div>
        </>
      )}
    </div>
  );
};

const WorkRightTable = ({
  tableRef,
  visiblePrintMenu,
  setVisiblePrintMenu,
  mode,
  struct,
  rows,
  rowsButton,
  setVisibleAdd,
  setSelectItem,
  setWidget,
  sortField,
  setRows,
  setSortField,
  fromOther = false,
  showColumnSelector = false,
  page,
  loadTSamples,
  typePackItems,
  changeTypePack,
  setSelectItemsToTransfer,
  showExpadedRow = false,
  changeColumns = () => {},
}) => {
  const { wrap } = useContext(wrapContext);

  const [imageModalView, setImageModalView] = React.useState(false);
  const [images, setImages] = React.useState();
  const [structHeader, setStructHeader] = useState(struct);
  const [rowsSource, setRowsSource] = useState(rows);
  const [sharedFiltersData, setSharedFiltersData] = useState([
    {
      field: 'name',
      operator: 'contains',
      value: '',
      type: 'string',
    },
  ]);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [treeLocation, setTreeLocation] = useState([]);
  const [height, setHeight] = useState({});
  const [filterFields, setFilterFields] = useState([...filterFieldsDefault]);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isOpenPrintSizeModal, setIsOpenPrintSizeModal] = useState(false);
  const [countColInPage, setCountColInPage] = React.useState();
  const [selectedItemsToPrint, setSelectedItemsToPrint] = useState([]);
  const [selectedItemsToImport, setSelectedItemsToImport] = useState([]);
  const [statusSample, setStatusSample] = useState(
    mode.path == 'Архив'
      ? [{ id: 'archived', name: 'Утилизирован' }]
      : [
          { id: 'packed', name: 'В хранилище' },
          { id: 'extracted', name: 'Извлечен' },
        ],
  );
  const [isSelectAll, setIsSelectAll] = useState(false);

  let count = struct?.length;

  useEffect(() => {
    const loadTypes = async () => {
      const res = await getSampleTypes();
      setFilterFields([
        ...filterFieldsDefault,
        ...res?.map((val) => {
          return { id: val.id, name: val.name, type: val.type };
        }),
      ]);
    };
    loadTypes();
  }, []);

  useEffect(() => {
    const loadSettings = async () => {
      const res = await getProfile(0);
      setCountColInPage(+res?.settings.find((val) => val.name == 'table_columns_count')?.value + 1);

      let count = 0;
      const colInPage = +res?.settings.find((val) => val.name == 'table_columns_count')?.value + 1;

      setStructHeader(
        struct.map((val) => {
          if (val.showColumn) count++;
          if (count > colInPage) val.showColumn = false;
          return val;
        }),
      );
    };
    loadSettings();
  }, [struct]);

  const handleSetImages = (imgs) => {
    setImages(imgs);
    setImageModalView(true);
  };

  struct?.forEach((item) => {
    if (item.type === 'image') count--;
  });

  useEffect(() => {
    getTypeSamples().then((res) => setTypes(res));
    getStorages(false, '').then((res) => {
      setTreeLocation(res);
    });
  }, []);

  useEffect(() => {
    if (wrap) setHeight({ height: 'calc(100% - 160px)' });
  }, [wrap]);

  useEffect(() => {
    const fieldsShow = structHeader
      ?.filter((i) => i.showColumn || i.systemColumn)
      .map((i) => i.field);
    const oldRows = JSON.parse(JSON.stringify(rows ?? []));
    const newRows = oldRows.map((i) => {
      let row = JSON.parse(JSON.stringify(i));
      row.value = {};
      fieldsShow?.forEach((j) => {
        if (i.value[`user_field[${j}]`] != undefined && i.value[`user_field[${j}]`] != null) {
          row.value[`user_field[${j}]`] = i.value[`user_field[${j}]`];
        } else {
          row.value[j] = i.value[j];
        }
      });
      return row;
    });
    setRowsSource(newRows);
  }, [structHeader, rows]);

  const addFilterField = () => {
    const filters = JSON.parse(JSON.stringify(sharedFiltersData));
    const filterField = {
      field: '',
      operator: '',
      value: '',
      type: '',
    };
    filters.push(filterField);

    setSharedFiltersData(filters);
  };

  const getSamplesT = (val = null, setFilter = false) => {
    if (val != null) {
      const columns = val.filter((val) => val.showColumn == true);

      let mapArray = columns.map((val) => (typeof val.field != 'string' ? val.name : val.field));
      let arrCol = [...mapArray];
      loadTSamples(JSON.stringify(arrCol));
    }
    if (setFilter) {
      const columns = structHeader.filter((val) => val.showColumn == true);
      let mapArray = columns.map((val) => (typeof val.field != 'string' ? val.name : val.field));
      let arrCol = [...mapArray];
      loadTSamples(
        JSON.stringify(arrCol),
        sharedFiltersData.map((item) => {
          if (item.type == 'type') {
            item.value = types.find((val) => val.id == item.value)?.name ?? item.value;
          }
          return item;
        }),
      );
    }
  };

  const getOptions = (type) => {
    if (type === 'date' || type === 'volume' || type === 'integer' || type === 'float') {
      return [
        { id: '=', name: 'Равно' },
        { id: '>', name: 'Больше' },
        { id: '<', name: 'Меньше' },
        { id: '>=', name: 'Больше либо равно' },
        { id: '<=', name: 'Меньше либо равно' },
      ];
    } else {
      return [
        { id: '=', name: 'Равно' },
        { id: 'contains', name: 'Содержит' },
      ];
    }

    return [];
  };

  const openPrintModal = (items) => {
    if (items != undefined) {
      setSelectedItemsToPrint(items);
      setVisiblePrintMenu(true);
    }
  };

  const onExportData = () => {
    setIsOpenExportModal(true);
  };

  const buildFieldFilter = (val, index) => {
    switch (val.type) {
      case 'integer':
      case 'float': {
        return (
          <input
            className={classes.infoItemValue}
            type="number"
            style={{ width: '100%' }}
            value={val.value}
            onChange={(e) => {
              let fields = JSON.parse(JSON.stringify(sharedFiltersData));
              let field = fields[index];
              field.value = e.target.value;
              setSharedFiltersData(fields);
            }}
          />
        );
      }
      case 'date': {
        return (
          <WidgetDateTimePicker
            selected={val.value}
            onChange={(item) => {
              let date = dayjs(item).format('YYYY-MM-DD');
              let fields = JSON.parse(JSON.stringify(sharedFiltersData));
              let field = fields[index];
              field.value = date;
              setSharedFiltersData(fields);
            }}
            isDate={true}
          />
        );
      }
      case 'type': {
        return (
          <div className={classes.filterSelector}>
            <DropdownComponent
              keyItem={'id'}
              valueItem={'name'}
              selectedKey={selectedType}
              onChange={(e) => {
                let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                let field = fields[index];
                field.value = e;
                setSelectedType(e);
                setSharedFiltersData(fields);
              }}
              items={types}
            />
          </div>
        );
      }
      case 'status': {
        return (
          <div className={classes.filterSelector}>
            <DropdownComponent
              keyItem={'id'}
              valueItem={'name'}
              selectedKey={selectedStatus}
              onChange={(e) => {
                let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                let field = fields[index];
                field.value = e;
                setSelectedStatus(e);
                setSharedFiltersData(fields);
              }}
              items={statusSample}
            />
          </div>
        );
      }
      case 'location': {
        return (
          <TreeDropDown
            value={val.value == 0 ? 'Выберите хранилище...' : val.value}
            setValue={(e) => {
              let fields = JSON.parse(JSON.stringify(sharedFiltersData));
              let field = fields[index];
              field.value = e?.path;
              setSharedFiltersData(fields);
            }}
            treeLocation={treeLocation}
          />
        );
      }
      default: {
        //name
        return (
          <input
            className={classes.infoItemValue}
            type="text"
            style={{ width: '100%' }}
            value={val.value}
            onChange={(e) => {
              let fields = JSON.parse(JSON.stringify(sharedFiltersData));
              let field = fields[index];
              field.value = e.target.value;
              setSharedFiltersData(fields);
            }}
          />
        );
      }
    }
  };

  const selectItem = (selectedValue) => {
    if (selectedItemsToPrint?.find((val) => val.id == selectedValue.id))
      setSelectedItemsToPrint((prevValue) =>
        prevValue?.filter((val) => val.id != selectedValue.id),
      );
    else setSelectedItemsToPrint((prevValue) => [selectedValue, ...prevValue]);
  };

  const selectClick = (selectedValue) => {
    if (selectedItemsToImport?.find((val) => val.id == selectedValue.id))
      setSelectedItemsToImport((prevValue) =>
        prevValue?.filter((val) => val.id != selectedValue.id),
      );
    else setSelectedItemsToImport((prevValue) => [selectedValue, ...prevValue]);
  };

  const selectAllClick = () => {
    if (!isSelectAll) {
      const allItem = rows?.map((val, index) => {
        return { id: index };
      });
      setSelectedItemsToImport(allItem);
      setIsSelectAll(true);
    } else {
      setSelectedItemsToImport([]);
      setIsSelectAll(false);
    }
  };

  useEffect(() => {
    if (mode.path == 'Импорт') {
      if (selectedItemsToImport?.length == rows?.length && rows?.length != 0) {
        setSelectItemsToTransfer(selectedItemsToImport);
        setIsSelectAll(true);
      } else {
        setSelectItemsToTransfer(selectedItemsToImport);
        setIsSelectAll(false);
      }
    }
  }, [selectedItemsToImport]);

  return (
    <>
      {mode.sharedFilter &&
        (mode.path === 'Образцы' ||
          mode.path === 'Архив' ||
          mode.path === 'Образцы в хранилище') && (
          <div className={classes.sharedContainer}>
            <button className={`button_default save_style`} onClick={addFilterField}>
              Добавить поле
            </button>

            <div className={classes.sharedBody}>
              {sharedFiltersData.map((field, index) => (
                <div className={classes.filterRow} key={index}>
                  <div className={classes.filterSelector}>
                    <DropdownComponent
                      keyItem={'id'}
                      valueItem={'name'}
                      selectedKey={field.field}
                      onChange={(e) => {
                        let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                        let field = fields[index];
                        field.field = e;
                        switch (e) {
                          case 'volume': {
                            field.operator = '=';
                            field.value = 0;
                            field.type = 'float';
                            break;
                          }
                          case 'date': {
                            field.operator = '=';
                            field.value = 0;
                            field.type = 'date';
                            break;
                          }
                          case 'type': {
                            setSelectedType('');
                            field.operator = '=';
                            field.value = 0;
                            field.type = 'type';
                            break;
                          }
                          case 'status': {
                            setSelectedType('');
                            field.operator = '=';
                            field.value = 0;
                            field.type = 'status';
                            break;
                          }
                          case 'location': {
                            field.operator = '=';
                            field.value = 0;
                            field.type = 'location';
                            break;
                          }
                          default: {
                            const type = filterFields.find((val) => val.id == e).type;
                            field.operator = '=';
                            field.value = '';
                            field.type = type;
                            break;
                          }
                        }
                        setSharedFiltersData(fields);
                      }}
                      items={filterFields.filter(
                        (item1) =>
                          !sharedFiltersData.some(
                            (item2) => item1.id === item2.field && item1.id !== field.field,
                          ),
                      )}
                    />
                  </div>
                  {field.type != 'location' && field.type != 'type' && field.type != 'status' && (
                    <div className={classes.filterSelector}>
                      <DropdownComponent
                        keyItem={'id'}
                        valueItem={'name'}
                        selectedKey={field.operator}
                        onChange={(e) => {
                          let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                          let field = fields[index];
                          field.operator = e;
                          setSharedFiltersData(fields);
                        }}
                        items={getOptions(field.type)}
                      />
                    </div>
                  )}

                  <div>{buildFieldFilter(field, index)}</div>
                  <Icon
                    src={'/svg/delete-01.svg'}
                    color={COLORS.color_hover}
                    width={'20px'}
                    className={classes.deleteIco}
                    onClick={() => {
                      let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                      fields.splice(index, 1);
                      setSharedFiltersData(fields);
                    }}
                    height={'20px'}
                  />
                </div>
              ))}
            </div>

            <div className={classes.footer}>
              <button
                className={`button_default save_style`}
                onClick={() => getSamplesT(null, true)}
              >
                Поиск
              </button>
              <button className={`button_default save_style`} onClick={onExportData}>
                Экспорт
              </button>
            </div>
          </div>
        )}
      {showColumnSelector && (
        <div
          style={{
            marginLeft: '20px',
            marginBottom: '40px',
            width: '300px',
          }}
        >
          <DropdownComponent
            keyItem={'field'}
            customComponent={'checkbox2'}
            onChange={(e) => {
              let newStr = JSON.parse(JSON.stringify(structHeader));
              let filterStr = newStr.filter((val) => val.showColumn);
              if (filterStr.length == countColInPage && e.value == true) {
                setWidget({
                  status: 'Alarm',
                  text: `Больше ${countColInPage} колонок выбрать нельзя`,
                  fun: async () => {},
                });
              } else if (filterStr.length == 3 && e.value == false) {
                setWidget({
                  status: 'Alarm',
                  text: `Меньше 3 колонок выбрать нельзя`,
                  fun: async () => {},
                });
              } else {
                let item = newStr.find((i) => i.field === e.key);
                if (item) {
                  item.showColumn = e.value;
                }

                if (
                  mode.path === 'Образцы' ||
                  mode.path === 'Архив' ||
                  mode.path === 'Образцы в хранилище'
                ) {
                  getSamplesT(newStr);
                  return;
                }
              }

              changeColumns(newStr);
            }}
            label={'Столбцы для отображения'}
            valueItem={'showColumn'}
            items={structHeader.filter((val) => val.field != 'selectToPrint' && !val.systemColumn)}
          />
        </div>
      )}
      <table
        width={100}
        className={
          mode.path === 'Образцы' ||
          mode.path === 'Архив' ||
          mode.path === 'Образцы в хранилище' ||
          mode.path === 'Источники образцов'
            ? classes.workRightTable_sample +
              ' ' +
              (mode.sharedFilter ? classes.workRightTable_sample_filter : '')
            : classes.workRightTable
        }
        style={height}
      >
        <tbody>
          <tr>
            {showExpadedRow && <td width={'2% !important'}></td>}
            {!showColumnSelector &&
              structHeader?.map((structItem, index) => (
                <WorkRightTableHeader
                  data={structItem}
                  selectAllClick={selectAllClick}
                  isSelectAll={isSelectAll}
                  key={index}
                  sortField={sortField}
                  setSortField={setSortField}
                  count={count}
                  showColumnSelector={showColumnSelector}
                />
              ))}
            {showColumnSelector &&
              structHeader?.map(
                (structItem, index) =>
                  structItem.showColumn && (
                    <WorkRightTableHeader
                      data={structItem}
                      selectAllClick={selectAllClick}
                      isSelectAll={isSelectAll}
                      key={index}
                      sortField={sortField}
                      setSortField={setSortField}
                      count={count}
                      showColumnSelector={showColumnSelector}
                    />
                  ),
              )}
            <td></td>
          </tr>
          {rowsSource?.map((rowsItem, index) => (
            <WorkRightTableRow
              mode={mode}
              struct={structHeader}
              selectItem={selectItem}
              selectClick={selectClick}
              selectedItemsToPrint={selectedItemsToPrint}
              selectedItemsToImport={selectedItemsToImport}
              rowsItem={rowsItem}
              openPrintModal={openPrintModal}
              key={index}
              indexInData={index}
              typePackItems={typePackItems}
              rowsButton={rowsButton}
              setVisibleAdd={setVisibleAdd}
              setSelectItem={setSelectItem}
              setWidget={setWidget}
              fromOther={fromOther}
              changeTypePack={changeTypePack}
              showColumnSelector={showColumnSelector}
              handleSetImages={handleSetImages}
              showExpadedRow={showExpadedRow}
            />
          ))}
        </tbody>
      </table>
      {imageModalView && (
        <ImagePopupComponent
          images={images}
          onClosePopup={setImageModalView}
          isPopupOpen={imageModalView}
        />
      )}
      {isOpenExportModal && (
        <ExportModal
          filterSettings={sharedFiltersData}
          setModalOpened={setIsOpenExportModal}
          mode={mode}
          defaultFields={filterFieldsDefault}
        />
      )}
      {visiblePrintMenu && (
        <PrintSizeModal
          mode={mode}
          items={selectedItemsToPrint}
          setModalOpened={setVisiblePrintMenu}
        />
      )}
    </>
  );
};

export default WorkRightTable;
