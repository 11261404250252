import React, { useRef } from 'react';
import classes from './AboutProgrammModal.module.css';
import styles from '../Widgets/Modal/modal.module.css';
import Icon from '../../helpers/components/Icon';
import close from './../../assets/svg/close-01.svg';
import { appVersion } from '../../version';

const AboutProgrammModal = ({ openModal, onClose, version }) => {
  const selfRef = useRef(null);
  const modalBGRef = useRef(null);

  const closeModalTappedOnBg = (ev) => {
    if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
      onClose();
    }
  };

  const modal = (
    <>
      <div ref={modalBGRef} onClick={(ev) => closeModalTappedOnBg(ev)} className={styles.modalBG}>
        <div className={classes.model_copy} ref={selfRef}>
          <div className={classes.header}>
            <div className={classes.closeButton} onClick={() => onClose()}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={classes.body} style={{ margin: '10px 20px' }}>
            <div className={classes.ico}>
              <img src="/svg/logo-01.svg" alt="logo" />
              <span>Версия {appVersion}</span>
            </div>
            <div className={classes.sites}>
              <div>
                <span>Сайт: </span>
                <a href="#">SmartBio.pro</a>
              </div>
              <div>
                <span>Техническая поддержка: </span>
                <a href="#">support@smartbio.ru</a>
              </div>
            </div>

            <div className={classes.text}>
              Виртуальная система хранения биологических образцов и ассоциированных данных
              «Смартбио»
              <br />
              Интеллектуальная собственность компании ООО "Смартбиотек"
              <br />
              Свидетельство о государственной регистрации программы для ЭВМ № 2023610092 Все права
              защищены Copyright ©2022 - 2023 SmartBiotech, Inc.
            </div>
            <div className={classes.footer}>
              <button className={`button_default cancel_style`} onClick={() => onClose()}>
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return openModal ? modal : <></>;
};

export default AboutProgrammModal;
