import { action, observable, makeAutoObservable, runInAction, computed, configure } from 'mobx';
import { createContext, useContext } from 'react';
import { getListTypeFields } from '../services/list.services';
import { modeContext } from '../components/Contexts/contexts';
import update from 'immutability-helper';

class AppStore {
  constructor() {
    makeAutoObservable(this, {
      fields: observable,
      setFields: action,
      fieldsList: computed,
      makeNewField: action,
      deleteField: action,
      clearFields: action,
      changeFieldOrder: action,

      activeStructureId: observable,
      hasErrorsInFields: observable,
      editingStructure: observable,
      activeStructureName: observable,

      deleteFieldId: observable,
      setDeleteFieldId: action,

      fieldTypes: observable,
      getListFieldTypes: action,
    });
    this.setListFieldTypes();

    configure({
      enforceActions: 'never',
    });
  }

  fields = [];
  activeStructureId = 0;
  createdItemId = 0;
  editingStructure = false;
  copiedStructure = false;
  hasErrorsInFields = false;
  activeStructureName = '';
  activeStructureNameErrors = {
    required: null,
    same: null,
  };
  deleteFieldId = 0;
  deleteStructureId = 0;

  getStore = () => {
    return {
      fields: this.fields,
      activeStructureId: this.activeStructureId,
      createdItemId: this.createdItemId,
      editingStructure: this.editingStructure,
      copiedStructure: this.copiedStructure,
      hasErrorsInFields: this.hasErrorsInFields,
      activeStructureName: this.activeStructureName,
      activeStructureNameErrors: this.activeStructureNameErrors,
      deleteFieldId: this.deleteFieldId,
      deleteStructureId: this.deleteStructureId,
    };
  };

  setStore = (data) => {
    this.fields = data.fields;
    this.activeStructureId = data.activeStructureId;
    this.createdItemId = data.createdItemId;
    this.editingStructure = data.editingStructure;
    this.copiedStructure = data.copiedStructure;
    this.hasErrorsInFields = data.hasErrorsInFields;
    this.activeStructureName = data.activeStructureName;
    this.activeStructureNameErrors = data.activeStructureNameErrors;
    this.deleteFieldId = data.deleteFieldId;
    this.deleteStructureId = data.deleteStructureId;
  };

  setCreatedItemId = (id) => {
    runInAction(() => {
      this.createdItemId = id;
    });
  };
  changeActiveStructureName = (name) => {
    runInAction(() => {
      this.activeStructureName = name;
      this.activeStructureNameErrors.required = null;
      this.activeStructureNameErrors.same = null;
    });
  };
  get showStructureNameErrors() {
    return Object.values(this.activeStructureNameErrors).reduce((acc, item) => {
      if (item !== null) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  setActiveStructureId = (id) => {
    runInAction(() => {
      this.activeStructureId = id;
    });
  };

  setEditingStructure = (bool) => {
    runInAction(() => {
      this.editingStructure = bool;
    });
  };

  setCopiedStructure = (bool) => {
    runInAction(() => {
      this.copiedStructure = bool;
    });
  };

  get fieldsList() {
    return this.fields;
  }

  setFieldVal = (val, fieldName, order_field) => {
    runInAction(() => {
      let item = this.fields.find((field) => field.order_field === parseInt(order_field));
      const itemIndex = this.fields.findIndex(
        (field) => field.order_field === parseInt(order_field),
      );
      item[fieldName] = val;
      if (fieldName === 'type') {
        item['setting'] = [];
      }
      this.fields[itemIndex] = item;
    });
  };
  getFieldVal = (fieldName, order_field) => {
    runInAction(() => {
      const item = this.fields.find((field) => field.order_field === parseInt(order_field));

      return item[fieldName];
    });
  };

  setDeleteFieldId = (order_field) => {
    runInAction(() => {
      this.deleteFieldId = order_field;
    });
  };

  setDeleteStructureId = (id) => {
    runInAction(() => {
      this.deleteStructureId = id;
    });
  };

  checkEqualNames = () => {
    runInAction(() => {
      const repeatNames = [...this.fields].reduce((acc, field) => {
        if (!acc[`'${field.name}'`]) {
          acc[`'${field.name}'`] = [field.order_field];
        } else {
          acc[`'${field.name}'`].push(field.order_field);
        }
        return acc;
      }, []);

      Object.keys(repeatNames).map((name) => {
        if (repeatNames[name].length > 1) {
          repeatNames[name].map((order_field) => {
            const fieldIndex = [...this.fields].findIndex(
              (item) => order_field === item.order_field,
            );
            let newField = [...this.fields].find((item) => order_field === item.order_field);
            newField.errors = {
              ...newField.errors,
              name: true,
            };
            newField.hasError = true;
            this.fields[fieldIndex] = newField;
          });
        } else {
          repeatNames[name].map((order_field) => {
            const fieldIndex = [...this.fields].findIndex(
              (item) => order_field === item.order_field,
            );
            let newField = [...this.fields].find((item) => order_field === item.order_field);
            if (newField.errors ? newField.errors.name : false) {
              delete newField.errors.name;
            }
            if (newField.errors ? !Object.keys(newField.errors).length : false) {
              delete newField.errors;
              newField.hasError = false;
            }
            this.fields[fieldIndex] = newField;
          });
        }
      });

      this.findSomeFieldErrors();
    });
  };

  checkNotFilledFieldName = () => {
    runInAction(() => {
      const newFields = [...this.fields].map((field) => {
        if (field.name === '') {
          field.errors = {
            ...field.errors,
            required: true,
          };
        } else {
          if (field.errors ? field.errors.required : false) {
            delete field.errors.required;
          }
          if (field.errors ? !Object.keys(field.errors).length : false) {
            delete field.errors;
            field.hasError = false;
          }
        }
        return field;
      });
      this.fields = newFields;
      this.findSomeFieldErrors();
    });
  };

  checkSameStructureName = () => {
    runInAction(() => {
      const sameNamedStructure = [...this.otherStructures].find((structure) => {
        return (
          structure.name === this.activeStructureName && structure.id !== this.activeStructureId
        );
      });
      if (sameNamedStructure !== undefined) {
        this.activeStructureNameErrors.same = 'Поле должно быть уникальным';
      } else {
        this.activeStructureNameErrors.same = null;
      }
      this.findSomeFieldErrors();
    });
  };

  checkNotFilledTypes = () => {
    const haveTypeFields = [...this.fields].reduce((acc, field) => {
      field.type ? (acc[field.order_field] = true) : (acc[field.order_field] = false);

      return acc;
    }, []);

    Object.keys(haveTypeFields).map((order_field) => {
      if (haveTypeFields[order_field]) {
        this.clearTypeFieldError(order_field);
      } else {
        this.addTypeFieldError(order_field);
      }
    });

    this.findSomeFieldErrors();
  };

  clearTypeFieldError = (order_field) => {
    runInAction(() => {
      const field = [...this.fields].find((item) => item.order_field === order_field);
      const fieldIndex = [...this.fields].findIndex((item) => item.order_field === order_field);

      if (field?.errors ? field.errors.type : false) {
        delete field.errors.type;
      }
      if (field?.errors ? !Object.keys(field.errors).length : false) {
        delete field.errors;
      }

      this.fields[fieldIndex] = field;
    });
  };

  addTypeFieldError = (order_field) => {
    runInAction(() => {
      const field = this.fields.find((item) => item.order_field === parseInt(order_field));
      const fieldIndex = this.fields.findIndex(
        (item) => item.order_field === parseInt(order_field),
      );
      if (field) {
        field.errors = {
          ...field.errors,
          type: true,
        };
      }
      this.fields[fieldIndex] = field;
    });
  };

  findSomeFieldErrors = () => {
    runInAction(() => {
      this.hasErrorsInFields = !![...this.fields].reduce((acc, field) => {
        acc += field.errors ? 1 : 0;
        return acc;
      }, 0);
    });
  };

  hasFieldErrors = (field) => {
    runInAction(() => {
      this.hasErrorsInFields = ![...this.fields].reduce((acc, field) => {
        acc += field.errors ? 1 : 0;
        return acc;
      }, 0);
    });
  };

  checkFilledStructureName = () => {
    runInAction(() => {
      if (this.activeStructureName === '') {
        this.activeStructureNameErrors.required = 'Поле обязательно для заполнения';
      } else {
        this.activeStructureNameErrors.required = null;
      }
    });
  };

  get validateFields() {
    this.checkEqualNames();
    this.checkNotFilledFieldName();
    this.checkFilledStructureName();
    this.checkSameStructureName();
    this.checkNotFilledTypes();
    this.findSomeFieldErrors();
    if (
      this.activeStructureNameErrors.required !== null ||
      this.activeStructureNameErrors.same !== null
    )
      return 1;
    return this.hasErrorsInFields;
  }

  setFields = (fields) => {
    runInAction(() => {
      this.fields = fields;
    });
  };

  deleteField = () => {
    runInAction(() => {
      const newStruct = [...this.fields].filter((item) => {
        return this.deleteFieldId !== item.order_field;
      });

      this.deleteFieldId = null;
      this.setFields(newStruct);
      this.checkEqualNames();
    });
  };
  clearFields = () => {
    runInAction(() => {
      this.fields = [];
    });
  };

  clearStructure = () => {
    runInAction(() => {
      this.fields = [];
      this.activeStructureId = 0;
      this.deleteStructureId = 0;
      this.deleteFieldId = 0;
      this.activeStructureName = '';
      this.editingStructure = false;
      this.copiedStructure = false;
    });
  };

  changeFieldOrder = (destinationIndex, sourceIndex) => {
    runInAction(() => {
      let items = Array.from(this.fields);
      const [reorderedItem] = items.splice(sourceIndex, 1);
      items.splice(destinationIndex, 0, reorderedItem);
      items.map((item, index) => {
        item.order_field = index + 1;
        return item;
      });
      this.fields = items;
    });
  };

  toggleFieldActive = (order_field, activate = true) => {
    runInAction(() => {
      const newStruct = [...this.fields].map((item) => {
        item.opened = false;
        item.active = false;

        if (item.order_field === order_field) {
          item.active = activate;
          item.opened = true;
        }
        return item;
      });

      this.setFields(newStruct);
    });
  };
  toggleOpenField = (order_field, open = true) => {
    runInAction(() => {
      this.checkNotFilledTypes();
      const newStruct = [...this.fields].map((item) => {
        if (item.order_field === order_field) {
          item.opened = open;
        }
        return item;
      });

      this.setFields(newStruct);
    });
  };

  makeNewField = () => {
    runInAction(() => {
      this.closeAllFields();

      const lastId = [...this.fields].reduce((acc, field) => {
        if (acc < field.order_field) acc = field.order_field;
        return acc;
      }, 0);

      this.fields = [
        ...this.fields,
        {
          name: '',
          type: null,
          search: false,
          sorting: false,
          description: '',
          setting: [],
          active: true,
          opened: true,
          required: false,
          unique: false,
          order_field: lastId + 1,
        },
      ];
    });
  };
  updateField = (newData, orderField) => {
    const changeItemIndex = [...this.fields].findIndex((field) => orderField === field.order_field);
    const changeItem = [...this.fields].find((field) => orderField === field.order_field);
    this.fields[changeItemIndex] = Object.assign(changeItem, newData);
  };

  closeAllFields = () => {
    runInAction(() => {
      this.fields = [...this.fields].map((item) => {
        item.opened = false;
        item.active = false;
        return item;
      });
    });
  };

  setCopyStructure = (structure) => {
    runInAction(() => {
      this.clearFields();
      this.changeActiveStructureName(structure.name);
      this.setCopiedStructure(true);
      this.setActiveStructureId(0);
      this.setEditingStructure(false);
      const fields = structure.fields.sort(function (a, b) {
        return a.order_field - b.order_field;
      });
      this.setFields(fields);
    });
  };

  setEditStructure = (structure) => {
    runInAction(() => {
      this.clearFields();
      this.setActiveStructureId(structure.id);
      this.changeActiveStructureName(structure.name);
      this.setCopiedStructure(false);
      this.setEditingStructure(true);
      const fields = structure.fields.sort(function (a, b) {
        return a.order_field - b.order_field;
      });
      this.setFields(fields);
    });
  };

  fieldTypes = [];

  setListFieldTypes = (list) => {
    runInAction(() => {
      this.fieldTypes = list;
    });
  };

  otherStructures = [];

  setOtherStructures = (catalog) => {
    runInAction(() => {
      this.otherStructures = catalog;
    });
  };
}

export const FieldsStore = createContext(new AppStore());
