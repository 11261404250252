import React, { useEffect, useState } from 'react';
import classes from './tree.module.css';
import strelka from '../../../../../assets/svg/arrow-01.svg';
import { address_server_short, COLORS } from '../../../../../config';

const active = false;

const TreeItem = ({
  path,
  value,
  visibleTop,
  last,
  first,
  type,
  setLocation,
  setVisibleTreeLocation,
}) => {
  const [visible, setVisible] = useState(false);
  const styleVisible = [{ fontSize: '12pt' }, { fontSize: '0pt' }];
  const styleName = [{ padding: '0 0 0 0' }, {}];
  const styleVisibleSVG = [{ height: '16px' }, { height: '0' }];
  const styleBlock = [{}, { height: '0', width: '0', opacity: '0' }];
  const styleStrelka = [
    [{ height: '8px', transform: 'rotate(-90deg)' }, { height: '8px' }],
    [
      { height: '0', margin: '0 0 0 0' },
      { height: '0', margin: '0 0 0 0' },
    ],
  ];
  const [isHover, setIsHover] = useState(false);
  const styleHoverBlock = [
    {
      color: isHover ? COLORS.color_brand : COLORS.color_hover2,
    },
    {},
  ];

  useEffect(() => {
    if (!visibleTop) setVisible(false);
  }, [visibleTop]);

  return (
    <div className={classes.Item}>
      {!first && <div className={classes.Line} style={styleBlock[visibleTop ? 0 : 1]}></div>}
      {!last && <div className={classes.Line2} style={styleBlock[visibleTop ? 0 : 1]}></div>}
      <div onClick={() => {}} className={classes.ItemName}>
        {value.nodes && value.nodes.length === 0 ? (
          <div style={{ width: '0px' }}></div>
        ) : (
          <img
            className={classes.Strelka}
            style={styleStrelka[visibleTop ? 0 : 1][visible ? 1 : 0]}
            src={strelka}
            alt={''}
            onClick={() => setVisible(!visible)}
          />
        )}
        <div
          className={classes.ItemNameBlock + ' ' + (active && classes.ItemNameSelect)}
          style={styleName[visibleTop ? 1 : 0]}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => {
            setLocation({ parent: value.id, path: path.slice(3) + ' / ' + value.name });
            setVisibleTreeLocation(false);
          }}
        >
          {value.icon && (
            <img
              alt={''}
              className={classes.SVG}
              src={address_server_short + value.icon}
              style={styleVisibleSVG[visibleTop ? 0 : 1]}
            />
          )}
          <div
            style={styleHoverBlock[type.parent === value.type ? 0 : 1]}
            className={classes.ItemNameText}
          >
            {value.name}
          </div>
        </div>
      </div>
      {value.nodes && value.nodes.length > 0 && (
        <div style={styleVisible[visible ? 0 : 1]}>
          {value.nodes.map((item, i, arr) => (
            <TreeItem
              path={path + ' / ' + value.name}
              key={item.id}
              value={item}
              visibleTop={visible}
              last={i === arr.length - 1}
              first={false}
              type={type}
              setLocation={setLocation}
              setVisibleTreeLocation={setVisibleTreeLocation}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeItem;
