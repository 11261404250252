import TreeSampleChildrenItem from './TreeSampleChildrenItem/TreeSampleChildrenItem';

const TreeSampleChildren = ({ tree }) => {
  return (
    tree &&
    tree.length > 0 && (
      <div>
        {tree?.map((val, index) => {
          return (
            <div key={index}>
              <TreeSampleChildrenItem
                child={val}
                first={true}
                last={true}
                width={0}
              ></TreeSampleChildrenItem>
            </div>
          );
        })}
      </div>
    )
  );
};

export default TreeSampleChildren;
