import {
  getAllTypeIcons,
  getSamplesByIconId,
  sampleUpsert,
} from '../../../../services/sample.services';
import { getListUnits } from '../../../../services/list.services';
import classes from '../sampleType.module.css';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import { address_server_short } from '../../../../config';
import error from '../../../../assets/svg/attention-01.svg';
import baseClasses from '../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css';
import { useEffect, useState } from 'react';

export const DefaultSampleFields = ({
  mode,
  isClickAdd,
  setTypeSampleField,
  typeSampleSelected,
}) => {
  const [icons, setIcons] = useState([]);
  const [folders, setFolders] = useState([]);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const loadAllFolders = async () => {
      let allFolders = await getAllTypeIcons();
      if (allFolders.success) {
        setFolders(allFolders.result);

        if (typeSampleSelected?.icon == null) {
          if (typeSampleSelected.folder == null) {
            setTypeSampleField(
              'folder',
              allFolders.result.map((i) => ({ id: i, name: i }))[0].name,
            );
          }
        } else {
          setTypeSampleField('folder', typeSampleSelected.icon.split('/')[2]);
        }
      } else
        mode.Widget.setWidget({
          status: 'Error',
          text: 'Наборы картинок не найдены',
          fun: async () => {},
        });
    };
    loadAllFolders();
  }, []);

  useEffect(() => {
    loadAllTypeIcons();
  }, [typeSampleSelected.folder]);

  const loadAllTypeIcons = async () => {
    let res = await getSamplesByIconId(typeSampleSelected.folder);
    if (res.success) {
      setIcons(res.result);
    } else
      mode.Widget.setWidget({
        status: 'Error',
        text: 'Данные по картинкам не найдены',
        fun: async () => {},
      });
  };

  useEffect(() => {
    const loadAllUnits = async () => {
      let res = await getListUnits();
      setUnits(res);
      if (!typeSampleSelected.unit) {
        setTypeSampleField('unit', res[0].id);
      }
    };
    loadAllUnits();
  }, []);

  useEffect(() => {
    if (typeSampleSelected.folder != null) {
      const loadAllTypeIcons = async () => {
        let res = await getSamplesByIconId(typeSampleSelected.folder);
        if (res.success) {
          setIcons(res.result);
        } else
          mode.Widget.setWidget({
            status: 'Error',
            text: 'Данные по картинкам не найдены',
            fun: async () => {},
          });
      };
      loadAllTypeIcons();
    }
  }, [typeSampleSelected.folder]);

  return (
    <>
      <div className={classes.dropDown_container}>
        <div className={classes.lable}>
          Набор <br />
          картинок
        </div>
        <div className={classes.dropDown}>
          <DropdownComponent
            key={'folders_1'}
            selectedKey={typeSampleSelected?.folder}
            items={folders.map((i) => ({ id: i, name: i }))}
            onChange={(val) => setTypeSampleField('folder', val)}
          />
        </div>
      </div>
      {icons != undefined && (
        <div className={classes.iconsContainer}>
          {icons.map((val) => (
            <div
              key={val.id_icon}
              className={
                (val.id_icon == typeSampleSelected.id_icon ? classes.bg_blue : '') +
                ' ' +
                classes.itemIcon
              }
              onClick={() => setTypeSampleField('id_icon', val.id_icon)}
            >
              <div className={classes.containerImg}>
                <img src={address_server_short + val.file} className={classes.itemImg} />
              </div>
            </div>
          ))}
        </div>
      )}
      {typeSampleSelected?.id_icon == null && isClickAdd && (
        <div className={baseClasses.dataErrorBlock}>
          <img src={error} alt={'error'} />
          <div className={baseClasses.dataError}>Выбор картинки обязателен</div>
        </div>
      )}
      <div className={classes.dropDown_container}>
        <div className={classes.lable}>
          Единицы <br />
          измерения
        </div>
        <div className={classes.dropDown}>
          <DropdownComponent
            key={'packs_1'}
            disabled={typeSampleSelected?.id ? true : false}
            selectedKey={typeSampleSelected.unit}
            items={units.map((i) => ({ id: i.id, name: i.name }))}
            onChange={(val) => setTypeSampleField('unit', val)}
          />
        </div>
      </div>

      <div className={classes.dropDown_container}>
        <div className={classes.lable}>
          Название <br />
          типа образца
        </div>

        <div className={classes.inputContainer}>
          <input
            className={classes.input}
            value={typeSampleSelected.name ?? ''}
            onChange={(e) => setTypeSampleField('name', e.target.value)}
            type="text"
            placeholder="Введите название..."
          />
        </div>
      </div>
      {(typeSampleSelected?.name == null ||
        typeSampleSelected?.name == '' ||
        typeSampleSelected?.name.trim() == '') &&
        isClickAdd && (
          <div className={baseClasses.dataErrorBlock}>
            <img src={error} alt={'error'} />
            <div className={baseClasses.dataError}>Поле обязательно для заполнения</div>
          </div>
        )}
      <div className={classes.dropDown_container}>
        <div className={classes.lable}>Количество циклов разморозки</div>
        <div className={classes.inputContainer}>
          <input
            className={classes.inputNumber}
            type={'number'}
            onWheel={(e) => e.target.blur()}
            value={typeSampleSelected.defreeze ?? ''}
            onChange={(e) => {
              if (e.target.value >= 0) setTypeSampleField('defreeze', e.target.value);
            }}
            placeholder="Кол-во циклов..."
          />
        </div>
      </div>
      <div className={classes.dropDown_container}>
        <div className={classes.lable}>Описание</div>
        <div className={classes.dropDown}>
          <textarea
            className={classes.textarea}
            placeholder={'Описание объекта'}
            value={typeSampleSelected.description ?? ''}
            onChange={(val) => setTypeSampleField('description', val.target.value)}
          ></textarea>
        </div>
      </div>
      {(typeSampleSelected.description == null ||
        typeSampleSelected.description == '' ||
        typeSampleSelected?.description.trim() == '') &&
        isClickAdd && (
          <div className={baseClasses.dataErrorBlock}>
            <img src={error} alt={'error'} />
            <div className={baseClasses.dataError}>Поле обязательно для заполнения</div>
          </div>
        )}
    </>
  );
};
