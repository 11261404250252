import React, { createRef, useEffect, useState } from 'react';
import classes from '../../WorkRightManualDir/WorkRightManual/workRightManual.module.css';
import WorkRightHeader from '../../WorkRightCommon/WorkRightHeader/workRightHeader';
import WorkRightSelectPage from '../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage';
import WorkRightTable from '../../WorkRightCommon/WorkRightTable/workRightTable';
import { getTemplates } from '../../../../services/report.services';
import TemplateModal from './templateModal';

const WorkRightReports = ({ sizeX, setWidget, mode }) => {
  const tableRef = createRef();
  const [page, setPage] = useState(1);
  const [pageAll, setPageAll] = useState(0);
  const [countAll, setCountAll] = useState(0);
  const [filter, setFilter] = useState('');
  const [sortField, setSortField] = useState({ type: '', field: '' });
  const [structHeader, setStructHeader] = useState({
    name: true,
    search: true,
    add: mode?.rules?.report?.add,
    filter: false,
    print: false,
    wrap: true,
  });
  const [rows, setRows] = useState([]);
  const [struct, setStruct] = useState([]);
  const [selectItem, setSelectItem] = useState({ id: 0, fields: [] });

  const [rowsButton, setRowsButton] = useState({
    print: false,
    copy: false,
    edit: mode?.rules?.report?.update,
    delete: mode?.rules?.report?.delete,
  });

  React.useEffect(() => {
    setStructHeader({
      name: true,
      search: true,
      add: mode?.rules?.report?.add,
      filter: false,
      print: false,
      wrap: true,
    });
    setRowsButton({
      print: false,
      copy: false,
      edit: mode?.rules?.report?.update,
      delete: mode?.rules?.report?.delete,
    });
  }, [mode]);

  useEffect(() => {
    getListRep();
  }, [mode.type, filter, sortField, page, mode.visibleAdd]);

  const getListRep = async () => {
    const sortFields = sortField.type != '' ? JSON.stringify(sortField) : '';
    const res = await getTemplates(mode.type.item.id, sortFields, page, filter);
    const items = res.result;
    setStruct(items.table);
    if (items.pagination?.pages) setPageAll(items.pagination?.pages);
    if (items.pagination?.total) setCountAll(items.pagination.total);

    let newArray = JSON.parse(JSON.stringify(items.templates));

    items?.templates?.map((val, index) => {
      for (let key of Object.keys(val)) {
        try {
          newArray[index][key] = JSON.parse(val[key]);
        } catch {
          newArray[index][key] = val[key];
        }
      }
    });

    newArray = newArray?.map((val) => {
      val.settings = val.settings.map((item) => item.value).join(', ');
      return { value: val, id: val.id_report_template };
    });

    setRows(newArray);
  };

  return (
    <div className={classes.workRightManual}>
      <WorkRightHeader
        setVisibleAdd={mode.setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        setSelectItem={setSelectItem}
        filter={filter}
        setFilter={setFilter}
      />
      <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>
      <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
      <WorkRightTable
        tableRef={tableRef}
        mode={mode}
        struct={struct}
        rows={rows}
        rowsButton={rowsButton}
        setVisibleAdd={mode.setVisibleAdd}
        setSelectItem={setSelectItem}
        setWidget={setWidget}
        sortField={sortField}
        setSortField={setSortField}
        fromOther={true}
      />
      {mode.visibleAdd && (
        <TemplateModal
          mode={mode}
          setModalOpened={mode.setVisibleAdd}
          id={selectItem != null && selectItem.id != 0 ? selectItem.id : null}
        ></TemplateModal>
      )}
    </div>
  );
};

export default WorkRightReports;
