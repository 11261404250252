import React from 'react';
import classes from './workRightStorageTable.module.css';
import { observer } from 'mobx-react-lite';
import { SampleItem } from './sampleItem';
import { EmptySampleItem } from './emptySampleItem';
import { CtrlCMode, CtrlXMode } from 'consts/storage.consts';

const parseTable = (struct, storage, samples, pack) => {
  let temp = [];
  let res = [];
  temp.push({ type: 'header', value: '' });
  struct.columns.forEach((i) => temp.push({ type: 'header', value: i }));
  res.push(temp);

  struct.lines.forEach((i) => {
    temp = [{ type: 'header', value: i }];
    struct.columns.forEach((j) =>
      temp.push({
        type: 'item',
        value: '',
        column: j,
        line: i,
        coords: `${j}${i}`,
        pack,
        storage,
        status: i.status,
      }),
    );
    res.push(temp);
  });

  const findItemIndexesByCoordinats = (coords) => {
    let item = null;

    for (let i = 0; i < res.length; i++) {
      for (let j = 0; j < res[i].length; j++) {
        if (res[i][j].coords === coords) return { column: j, line: i };
      }
    }
    return item;
  };

  Array.isArray(samples) &&
    samples?.forEach((i) => {
      let item = findItemIndexesByCoordinats(`${i.column}${i.line}`);
      if (item) {
        res[item.line][item.column] = {
          type: 'item',
          value: i,
          coords: `${i.column}${i.line}`,
          line: i.line,
          column: i.column,
          pack,
          storage,
          status: i.status,
        };
      }
    });

  return res;
};

const WorkRightStorageTable = observer(({ mode, path, storageId, pack, samples, setViewMode }) => {
  const struct = pack.structura;
  const table = parseTable(struct, storageId, samples ?? [], struct.id);
  React.useEffect(() => {
    document.addEventListener('keydown', onCopyModeHandler);

    return () => document.removeEventListener('keydown', onCopyModeHandler);
  }, []);

  const onCopyModeHandler = (e) => {
    let key = e.which || e.keyCode;
    let ctrl = e.ctrlKey ? e.ctrlKey : key === 17;

    if (ctrl && key === 88) {
      mode.setSelectedCopyMode(CtrlXMode);
    } else if (ctrl && key === 67) {
      mode.setSelectedCopyMode(CtrlCMode);
    }
  };

  const renderTable = React.useMemo(() => {
    return table.map((item, index) => {
      return item.map((cell, cell_index) => {
        if (cell.type === 'header') {
          return (
            <div key={cell_index}>
              <div>{cell.value}</div>
            </div>
          );
        } else {
          return cell.value.icons ? (
            <SampleItem
              cell={cell}
              sampleId={cell.value.id}
              column={cell.column}
              line={cell.line}
              packId={pack.id}
              index={cell_index}
              storageId={storageId}
              mode={mode}
              key={cell.value.id}
              setViewMode={setViewMode}
            />
          ) : (
            <EmptySampleItem
              cell={cell}
              column={cell.column}
              line={cell.line}
              packId={pack.id}
              index={cell_index}
              key={pack.id + '-' + cell_index}
              storageId={storageId}
              mode={mode}
              setViewMode={setViewMode}
            />
          );
        }
      });
    });
  }, [table]);

  return (
    <div className={classes.table}>
      <div className={classes.header_text} dangerouslySetInnerHTML={{ __html: path }}></div>

      {pack.name}
      <div
        className={classes.table_body}
        style={{
          gridTemplateColumns: `repeat(${table[0]?.length}, 50px)`,
          gridTemplateRows: '20px',
          alignItems: 'center',
        }}
      >
        {renderTable}
      </div>
    </div>
  );
});

export default WorkRightStorageTable;
