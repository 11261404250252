import React from 'react';
import classes from './widgets.module.css';
import close from '../../assets/svg/close-01.svg';
import ok from '../../assets/svg/window_ok-01.svg';
import attention from '../../assets/svg/window_attention-01.svg';
import info from '../../assets/svg/window_info-01.svg';
import sure from '../../assets/svg/window_sure-01.svg';

const Widget = ({ widget, setWidget }) => {
  return (
    <div className={classes.WidgetBlock}>
      {widget.status === 'Ok' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => {
              widget.fun();
              setWidget({ status: '', text: '', fun: null });
            }}
          />
          <div className={classes.WidgetBody}>
            <div className={`${classes.WidgetIcon} modalOk_icon`}> </div>
            <div className={classes.WidgetBodyBlock}>
              {widget.title && (
                <>
                  <div className={classes.WidgetHeader}>{widget.title}</div>
                  <div className={classes.WidgetText}>{widget.text}</div>
                </>
              )}
              {!widget.title && <div className={classes.WidgetHeader}>{widget.text}</div>}
              <div></div>
              <div
                className={classes.WidgetButton}
                onClick={() => {
                  widget.fun();
                  setWidget({ status: '', text: '', fun: null });
                }}
              >
                Закрыть
              </div>
            </div>
          </div>
        </div>
      )}
      {widget.status === 'Attention' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => setWidget({ status: '', text: '', fun: null })}
          />
          <div className={classes.WidgetBody}>
            <div className={`${classes.WidgetIcon} modalAttention_icon`}> </div>
            <div className={classes.WidgetBodyBlock}>
              <div className={classes.WidgetHeader}>Внимание!</div>
              <div>{widget.text}</div>
              <div className={classes.WidgetButtonBlock}>
                <div
                  className={classes.WidgetButtonCancel}
                  onClick={() => setWidget({ status: '', text: '', fun: null })}
                >
                  Отмена
                </div>
                <div
                  className={classes.WidgetButtonYesOrange}
                  onClick={() => {
                    widget.fun();
                    setWidget({ status: '', text: '', fun: null });
                  }}
                >
                  Продолжить
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {widget.status === 'Alarm' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => setWidget({ status: '', text: '', fun: null })}
          />
          <div className={classes.WidgetBody}>
            <div className={`${classes.WidgetIcon270} modalAttention_icon`}> </div>
            <div className={classes.WidgetBodyBlock}>
              <div className={classes.WidgetHeader}>Внимание!</div>
              <div>{widget.text}</div>
              <div className={classes.WidgetButtonBlock}>
                <div
                  className={classes.WidgetButtonYesOrange}
                  onClick={() => setWidget({ status: '', text: '', fun: null })}
                >
                  Закрыть
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {widget.status === 'Info' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => {
              widget.fun();
              setWidget({ status: '', text: '', fun: null });
            }}
          />
          <div className={classes.WidgetBody}>
            <div className={`${classes.WidgetIcon} modalInfo_icon`}> </div>
            <div className={classes.WidgetBodyBlock}>
              <div className={classes.WidgetHeader}>{widget.text}</div>
              <div></div>
              <div
                className={classes.WidgetButtonInfo}
                onClick={() => {
                  widget.fun();
                  setWidget({ status: '', text: '', fun: null });
                }}
              >
                Закрыть
              </div>
            </div>
          </div>
        </div>
      )}
      {widget.status === 'Sure' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => setWidget({ status: '', text: '', fun: null })}
          />
          <div className={classes.WidgetBody}>
            <div className={`${classes.WidgetIcon} modalSure_icon`}> </div>
            <div className={classes.WidgetBodyBlock2}>
              <div className={classes.WidgetHeader}>{widget.text}</div>
              <div className={classes.WidgetButtonBlock}>
                <div
                  className={classes.WidgetButtonCancel}
                  onClick={() => setWidget({ status: '', text: '', fun: null })}
                >
                  Отмена
                </div>
                <div
                  className={classes.WidgetButtonYesRed}
                  onClick={() => {
                    widget.fun();
                    setWidget({ status: '', text: '', fun: null });
                  }}
                >
                  Да
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {widget.status === 'Error' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => {
              widget.fun();
              setWidget({ status: '', text: '', fun: null });
            }}
          />
          <div className={classes.WidgetBody}>
            <img src={attention} alt={'ok'} className={classes.WidgetIcon} />
            <div className={classes.WidgetBodyBlock}>
              <div className={classes.WidgetHeader}>Внимание!</div>
              <div>Произошла ошибка! Сообщите код ошибки администратору: {widget.text}</div>
              <div className={classes.WidgetButtonBlock}>
                <div
                  className={classes.WidgetButtonYesOrange}
                  onClick={() => {
                    widget.fun();
                    setWidget({ status: '', text: '', fun: null });
                  }}
                >
                  Закрыть
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {widget.status === 'SimpleError' && (
        <div className={classes.Widget}>
          <img
            className={classes.WidgetClose}
            src={close}
            alt={'close'}
            onClick={() => {
              widget.fun();
              setWidget({ status: '', text: '', fun: null });
            }}
          />
          <div className={classes.WidgetBody}>
            <img src={attention} alt={'ok'} className={classes.WidgetIcon} />
            <div className={classes.WidgetBodyBlock}>
              <div className={classes.WidgetHeader}>Внимание!</div>
              <div>{widget.text}</div>
              <div className={classes.WidgetButtonBlock}>
                <div
                  className={classes.WidgetButtonYesOrange}
                  onClick={() => {
                    widget.fun();
                    setWidget({ status: '', text: '', fun: null });
                  }}
                >
                  Закрыть
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Widget;
