import React, { useContext, useEffect, useState } from 'react';
import classes from './workLeftHeader.module.css';
import searchSVG from '../../../assets/svg/serch-01.svg';
import { getListTypeStorages } from '../../../services/list.services';
import { getUserReports } from '../../../services/user.services';
import { getIdSection } from '../../../utils/section';
import { observer } from 'mobx-react-lite';
import { FieldsStore } from '../../../store/FieldsStore';
import { StructureStore } from '../../../store/StructureStore';
import { StorageStore } from '../../../store/StorageStore';
import { address_server_short } from '../../../config';
import { toJS } from 'mobx';
import { STORAGE_TYPE, Store } from '../../../store/Store';
import { getStorage } from '../../../services/storage.services';

const WorkLeftHeader = observer(({ mode, filter, setFilter, favorite, setFavorite }) => {
  const store = useContext(FieldsStore);
  const structureStore = useContext(StructureStore);
  const storageStore = useContext(StorageStore);
  const {
    loadTree,
    selectedTreeNode,
    __tree,
    selectedStorageType,
    setSelectedStorageType,
    getPath,
    setSelectedStorage,
  } = React.useContext(Store);
  const [struct, setStruct] = useState({
    favorite: true,
    search: true,
    add: true,
    add_text: 'Добавить',
    add_plus: true,
    copy: true,
    print: false,
  });
  const [listAdd, setListAdd] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [visiblePrintMenu, setVisiblePrintMenu] = useState(false);
  const [menuPrint, setMenuPrint] = useState([]);
  const [filterHeader, setFilterHeader] = useState('');
  const rules = mode.rules;

  useEffect(() => {
    const { user, group, storage, report, report_group, setting, catalog, sample } = rules ?? {};

    if (mode.path === 'Отчеты')
      setStruct({
        favorite: false,
        search: true,
        add: report_group?.upsert,
        add_text: 'Добавить группу',
        add_plus: false,
        copy: false,
        print: false,
      });
    if (mode.path === 'Справочники')
      setStruct({
        favorite: false,
        search: true,
        add: catalog?.upsert,
        add_text: 'Добавить',
        add_plus: false,
        copy: false,
        print: false,
      });
    if (mode.path === 'Пользователи')
      setStruct({
        favorite: false,
        search: true,
        add: group?.upsert,
        add_text: 'Добавить группу',
        add_plus: false,
        copy: false,
        print: false,
      });
    if (mode.path === 'Хранилище')
      setStruct({
        favorite: storage?.upsert_favorites,
        search: true,
        add: storage?.upsert,
        add_text: 'Добавить',
        add_plus: storage?.upsert,
        copy: storage?.upsert,
        print: false,
      });
  }, [mode]);

  useEffect(() => {
    const getStorageTypeT = async () => {
      const res = await getListTypeStorages();
      setListAdd(res);
    };
    if (mode.path === 'Хранилище') {
      getStorageTypeT();
    }
  }, []);

  const setSearch = (event) => {
    setFilterHeader(event.target.value);
  };

  const setSearchOnPressEnter = (event) => {
    if (event.key == 'Enter') setFilter(filterHeader);
  };

  function add_button() {
    if (mode.path === 'Пользователи') {
      mode.setType({ mode: 'edit', item: { name: '', id: 0 } });
    }
    if (mode.path === 'Справочники') {
      store.setFields([]);
      store.changeActiveStructureName('');
      store.setEditingStructure(false);
      store.setActiveStructureId(0);
      mode.setType({ mode: 'edit', item: { name: '', id: 0 } });
    }
    if (mode.path === 'Отчеты') {
      mode.setType({ mode: 'edit', item: { name: '', id: 0 } });
    }
    if (mode.path === 'Хранилище') {
      if (selectedTreeNode?.type != 'pack' || !selectedTreeNode) setOpenDropdown(!openDropdown);
    }
  }

  const copy = async () => {
    if (mode.path === 'Хранилище') {
      let res = await getStorage(selectedTreeNode.id);
      const types = await getListTypeStorages();
      let type = types.find((i) => i.id === res.type);
      let storage = {
        name: res.name + '__копия',
        id: 0,
        barcodes: [],
        packs: [
          {
            id: null,
            type: 0,
          },
        ],
        comment: res.comment,
        icon: res.icon?.file ?? '',
        location: { parent: res.location.parent, path: getPath(res.id) },
        color: res.color,
        type: type.id,
        parentType: type,
      };
      setSelectedStorage(storage);
      mode.setType({ mode: 'edit', item: storage });
    }
  };
  return (
    <div className={classes.WorkLeftHeader}>
      {struct.favorite && favorite && (
        <div className={classes.tooltipContainer}>
          <img
            className={classes.WorkLeftHeader_favorite}
            alt={'favorite'}
            src={'/svg/star-01.svg'}
            onClick={() => setFavorite(false)}
          />
          <div className={classes.tooltip}>Показать избранное</div>
        </div>
      )}

      {struct.favorite && !favorite && (
        <div className={classes.tooltipContainer}>
          <img
            className={classes.WorkLeftHeader_favorite}
            alt={'favorite'}
            src={'/svg/star2-01.svg'}
            onClick={() => setFavorite(true)}
          />
          <div className={classes.tooltip}>Показать избранное</div>
        </div>
      )}
      {struct.search && (
        <div className={classes.WorkLeftHeader_search}>
          <img className={classes.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'} />
          <input
            className={classes.WorkLeftHeader_search_input}
            placeholder={'Отобрать'}
            value={filterHeader}
            onKeyDown={setSearchOnPressEnter}
            onChange={setSearch}
          />
        </div>
      )}
      {struct.add && (
        <div
          className={
            classes.WorkLeftHeader_add +
            ' ' +
            (selectedTreeNode?.type == 'pack' && mode.path == 'Хранилище'
              ? classes.WorkLeftHeader_add_disabled
              : '')
          }
          onClick={add_button}
        >
          {struct.add_text}
          {struct.add_plus && <div className={classes.WorkLeftHeader_add_plus}></div>}
          {struct.add_plus && openDropdown && (
            <>
              <div className={classes.DropdownBlock} onClick={() => setOpenDropdown(false)}></div>
              <div className={classes.WorkLeftHeader_add_plus_Dropdown}>
                {listAdd.map((item, index) => {
                  if (
                    (selectedTreeNode
                      ? selectedTreeNode?.available?.find((val) => val.name == item.name)
                      : true) &&
                    item.name == 'Штатив'
                  ) {
                    return (
                      <div
                        key={item.id}
                        onClick={(e) => {
                          mode.setPuckSelectModal(true);
                          mode.setType({ mode: 'view', item: selectedStorageType });
                          e.stopPropagation();
                          setSelectedStorageType(item);
                          setOpenDropdown(!openDropdown);
                        }}
                        className={classes.WorkLeftHeader_add_plus_Dropdown_item}
                      >
                        <img src={`${address_server_short}${item.icon}`} alt={item.name} />
                        <span>{item.name} </span>
                      </div>
                    );
                  } else if (
                    (selectedTreeNode
                      ? selectedTreeNode?.available?.find((val) => val.name == item.name)
                      : true) &&
                    item.name != 'Штатив'
                  ) {
                    return (
                      <>
                        <div
                          key={item.id}
                          className={classes.WorkLeftHeader_add_plus_Dropdown_item}
                          onClick={async (e) => {
                            e.stopPropagation();
                            setSelectedStorageType(item);
                            mode.setType({ mode: 'edit', item: selectedStorageType });
                            setOpenDropdown(!openDropdown);
                          }}
                        >
                          <img src={`${address_server_short}${item.icon}`} alt={item.name} />
                          <span>{item.name} </span>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </>
          )}
          {mode.path === 'Хранилище' && (
            <div className={classes.tooltip + ' ' + classes.tooltipAdd}>Добавить хранилище</div>
          )}
        </div>
      )}
      {mode.path === 'Хранилище' && (
        <div
          className={classes.workRightHeader_add_block}
          onClick={async () => {
            await loadTree(favorite, filter);
          }}
        >
          <div className={classes.workRightHeader_refresh}></div>
          <div className={classes.tooltip + ' ' + classes.tooltipRefresh}>Обновить</div>
        </div>
      )}
      {struct.copy && (
        <div className={classes.tooltipContainer + ' ' + classes.tooltipContainerCopy}>
          <img
            className={classes.WorkLeftHeader_copy}
            alt={'copy'}
            src={'/svg/copy-01.svg'}
            onClick={copy}
          />
          <div className={classes.tooltip + ' ' + classes.tooltipRefresh}>Копировать</div>
        </div>
      )}
      {struct.print && (
        <>
          <img
            className={classes.WorkLeftHeader_copy}
            alt={'print'}
            src={'/svg/print-01.svg'}
            onClick={() => setVisiblePrintMenu(!visiblePrintMenu)}
          />
          {visiblePrintMenu && menuPrint.length > 0 && (
            <>
              <div
                className={classes.WorkRightHeader_filter_printBlock}
                onClick={() => setVisiblePrintMenu(false)}
              />

              <div className={classes.PrintBlock}>
                {menuPrint.map((item) => (
                  <div>
                    <div className={classes.PrintBlockHeader}>{item.name}</div>
                    {item.reports.map((item2) => (
                      <div className={classes.PrintBlockItem}>{item2.name}</div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
});

export default WorkLeftHeader;
