import React from 'react';
import srtyles from './contextMenu.module.css';
import useOutside from '../../helpers/useOutside';
import { OutsideContext } from 'widgets/Layout';

const ContextMenu = ({ children, top, left, close }) => {
  const outerRef = React.useContext(OutsideContext);
  const contextMenuRef = React.useRef(null);

  useOutside(outerRef, contextMenuRef, close);

  return (
    <div
      ref={contextMenuRef}
      className={srtyles.contextMenu}
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      {children}
    </div>
  );
};

export default ContextMenu;
