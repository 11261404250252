import React from 'react';
import ReactDOM from 'react-dom';
import classes from '../SampleTypeFields/fieldModalStyle.module.css';
import classesModalDict from '../../WorkRightManualDir/WorkRightEditManual/workRightEditManual.module.css';
import styles from '../../../Widgets/ModalInfo/modal.module.css';
import Icon from '../../../../helpers/components/Icon';
import close from '../../../../assets/svg/close-01.svg';
import { AttentionIcon } from '../../../../helpers/icons';
import classes2 from '../../WorkRightCommon/WorkRight/workRight.module.css';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import ParametersComponent from '../../WorkRightManualDir/WorkRightEditManual/components/ParametersComponent';
import TextareaAutosize from 'react-textarea-autosize';
import { getListTypeFields } from '../../../../services/list.services';
import { fieldUpsert } from '../../../../services/sample.services';
import { modeContext } from '../../../Contexts/contexts';

const AddFieldModal = ({ setModalOpened, mode, idType, setIsAddSuccess, isAddSuccess }) => {
  const [field, setField] = React.useState({
    idType: idType,
    showed: false,
    unique: false,
    required: false,
    sorting: false,
  });
  const [types, setTypes] = React.useState([]);
  const [typeSettings, setTypeSettings] = React.useState([]);
  const [isClickAdd, setIsClickAdd] = React.useState(false);

  const mainMode = React.useContext(modeContext);
  const { setWidget } = mainMode.widget;

  React.useEffect(() => {
    const loadAllTypes = async () => {
      let res = await getListTypeFields();
      setTypes(res);
    };
    loadAllTypes();
  }, []);

  React.useEffect(() => {
    setNewField('setting', typeSettings);
  }, [typeSettings]);

  const setNewField = (key, value) => {
    const obj = JSON.parse(JSON.stringify(field));
    obj[key] = value;
    setField(obj);
  };

  const addClick = () => {
    setIsClickAdd(true);
    if (field.name != undefined && field.name != '') {
      const filedUpsertAsync = async () => {
        const res = await fieldUpsert(field);
        if (res.success) {
          setModalOpened(false);
          setWidget({
            status: 'Ok',
            text: `Поле добавлено успешно!`,
            fun: async () => {
              setIsAddSuccess(!isAddSuccess);
            },
          });
        } else {
          if (res.error_code != 2015)
            setWidget({
              status: 'Error',
              text: res.error_code,
              fun: () => {},
            });
          else
            setWidget({
              status: 'Alarm',
              text: 'Поле с таким именем уже существет!',
              fun: async () => {},
            });
        }
      };
      filedUpsertAsync();
    }
  };

  const cancelClick = () => {
    setModalOpened(false);
  };

  const selectedTypeHaveSetting = (type) => {
    if (type) {
      if (
        type.type_name === 'select' ||
        type.type_name === 'range' ||
        type.type_name === 'location' ||
        type.type_name === 'switch_variants' ||
        type.type_name === 'integer'
      ) {
        return true;
      }
      return Object.keys(type.parameters).length;
    } else {
      return false;
    }
  };

  const modal = (
    <div className={classes.modalContainer}>
      <div className={classes.modal}>
        <div className={styles.header}>
          <div className={styles.closeButton} onClick={() => setModalOpened(false)}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div>
          <div className={classesModalDict.workRightEditInputs}>
            <div
              className={`${classesModalDict.manualRow}  ${classesModalDict.manualSmallRow}  ${classesModalDict.manualClosedTab}`}
              style={{
                display: 'flex',
              }}
            >
              <div className={classesModalDict.workRightEditItemShortButtons}>
                <div className={classesModalDict.toggleStructure}></div>
              </div>
            </div>
            <div className={classesModalDict.manualRow}>
              <span className={classesModalDict.manualLabel}>Наименование</span>
              <div className={classesModalDict.workRightInputBlock}>
                <input
                  className={`${classesModalDict.workRightInput} ""}`}
                  type={'text'}
                  placeholder={'Новое поле'}
                  onChange={(e) => setNewField('name', e.target.value)}
                />
                {isClickAdd && (field.name == '' || field.name == undefined) ? (
                  <>
                    <div className={classesModalDict.workRightInputErrorMessage}>
                      <Icon color={'#c12d2d'} src={AttentionIcon} width={'10px'} height={'10px'} />
                      <span>{'Поле обязательно для заполнения'}</span>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={classesModalDict.manualRow}>
              <span className={classesModalDict.manualLabel}>Тип поля</span>
              <div>
                <DropdownComponent
                  cols={2}
                  items={types}
                  hasIcons={true}
                  onChange={(val) => setNewField('idType', val)}
                  selectedKey={field.idType}
                />
              </div>
            </div>
            {types &&
            field &&
            selectedTypeHaveSetting(types.find((val) => val.id == field.idType)) ? (
              <div className={classes.rowType}>
                <div></div>
                <ParametersComponent
                  type={types.find((val) => val.id == field.idType).type_name}
                  parameters={types.find((val) => val.id == field.idType).parameters}
                  output={[]}
                  setOutput={(data) => setTypeSettings(data)}
                />
              </div>
            ) : null}
            <div className={classesModalDict.manualRow}>
              <span className={classesModalDict.manualLabel}>Описание</span>
            </div>
            <div className={`${classesModalDict.manualRow} ${classesModalDict.manualRowMono}`}>
              <TextareaAutosize
                className={
                  false
                    ? classesModalDict.workRightEditTextArea
                    : classesModalDict.workRightEditTextAreaWitoutScrollbar
                }
                onChange={(e) => setNewField('description', e.target.value)}
                maxRows={5}
                placeholder={'Текст описания объекта'}
              />
            </div>
          </div>
          <div className={classesModalDict.workRightEditSwitchers}>
            <div
              className={`${classesModalDict.manualRow} ${classesModalDict.manualRowAlignCenter} `}
            >
              <span className={classesModalDict.manualLabel}>Возможность сортировки</span>
              <div className={classesModalDict.workRightEditSwitch}>
                <label className={classes2.workRightSwitch}>
                  <input
                    onChange={(val) => setNewField('sorting', !field.sorting)}
                    type={'checkbox'}
                  />
                  <span className={classes2.workRightSlider}></span>
                </label>
              </div>
            </div>
            <div
              className={`${classesModalDict.manualRow} ${classesModalDict.manualRowAlignCenter} `}
            >
              <span className={classesModalDict.manualLabel}>Обязательно для заполнения</span>
              <div>
                <label className={classes2.workRightSwitch}>
                  <input
                    type={'checkbox'}
                    onChange={(val) => setNewField('required', !field.required)}
                  />
                  <span className={classes2.workRightSlider}></span>
                </label>
              </div>
            </div>
            <div
              className={`${classesModalDict.manualRow} ${classesModalDict.manualRowAlignCenter} `}
            >
              <span className={classesModalDict.manualLabel}>Уникальность</span>
              <div>
                <label className={classes2.workRightSwitch}>
                  <input
                    type={'checkbox'}
                    onChange={(val) => setNewField('unique', !field.unique)}
                  />
                  <span className={classes2.workRightSlider}></span>
                </label>
              </div>
            </div>
            <div
              className={`${classesModalDict.manualRow} ${classesModalDict.manualRowAlignCenter} `}
            >
              <span className={classesModalDict.manualLabel}>Отображать в таблице образцов</span>
              <div>
                <label className={classes2.workRightSwitch}>
                  <input
                    type={'checkbox'}
                    onChange={(val) => setNewField('showed', !field.showed)}
                  />
                  <span className={classes2.workRightSlider}></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <button className={`button_default save_style`} onClick={() => addClick()}>
            Добавить
          </button>
          <button className={`button_default cancel_style`} onClick={() => cancelClick()}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default AddFieldModal;
