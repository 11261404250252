import React, { useState } from 'react';
import dropdownStyles from './dropdown.module.css';
import Icon from '../../../helpers/components/Icon';
import closeSvg from '../../../assets/svg/close-01.svg';
import { address_server_short } from '../../../config';
import { modeContext } from '../../Contexts/contexts';

/*
 * item: object = {
 *      id: int
 *      icon: string
 *      title: string
 * }
 *
 * selected: bool
 * onClick: function
 */

const DropdownComponentItem = ({
  item,
  selected,
  valueItem,
  onClick,
  hasIcons,
  iconPathPrefix = '',
  isDelete = false,
  onDeleteClick = null,
  deleteMessageConfirm = '',
}) => {
  const itemClassName = `${dropdownStyles.item} ${selected ? dropdownStyles.itemSelected : ''}`;
  const iconPath = `${iconPathPrefix}${item.icon}`;

  const mode = React.useContext(modeContext ?? {});

  const onDelete = () => {
    mode?.widget?.setWidget({
      status: 'Sure',
      text: deleteMessageConfirm,
      fun: async () => {
        await onDeleteClick(item.id);
      },
    });
  };

  return (
    <div className={itemClassName}>
      <div onClick={onClick} style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }}>
        {/*{ hasIcons ? <Icon src={iconPath} className={dropdownStyles.itemIcon} color={"#13392b"}/> : ""}*/}
        {hasIcons ? (
          <img src={iconPath} alt="" className={dropdownStyles.itemIcon} color={'#13392b'} />
        ) : (
          ''
        )}
        <span>{item[valueItem]}</span>
      </div>
      {isDelete && (
        <Icon
          onClick={onDelete}
          src={closeSvg}
          className={dropdownStyles.itemIcon}
          color={'#13392b'}
        />
      )}
    </div>
  );
};
export default DropdownComponentItem;
