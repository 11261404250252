import { authStore } from 'store/auth.store';
import { address_server } from '../config';
import { error } from '../utils/handlerError';

export async function getTypeSampleSources(page, parameters) {
  const token = authStore.token;
  let optionalParams = '';
  if (parameters?.search) {
    optionalParams += '&search=' + parameters.search;
  }

  if (parameters?.sorting) {
    optionalParams += '&sorting=' + parameters.sorting;
  }

  const res = await (
    await fetch(
      address_server + 'type-sample-sources?token=' + token + '&page=' + page + optionalParams,
    )
  ).json();

  if (res.success) {
    return res;
  }
  error('getTypeSampleSources', res);
  return res;
}

export async function getTypeSampleSourceById(id) {
  const token = authStore.token;
  let res = '';
  res = await (
    await fetch(address_server + 'type-sample-sources/' + id + '?token=' + token)
  ).json();

  if (res.success) {
    return res;
  }
  error('getTypeSampleSources', res);
  return res;
}

export async function createTypeSampleSource(params) {
  const token = authStore.token;
  const data = {
    name: params.name?.trim(),
    description: params.description?.trim(),
    user_fields: [],
  };

  if (params.user_fields && params.user_fields.length) {
    params.user_fields.map((value, index) =>
      data.user_fields.push({ id: value.id_user_field, order: index }),
    );
  }
  return (
    await fetch(address_server + 'type-sample-sources' + '?token=' + token, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).json();
}

export async function updateTypeSampleSource(id, params) {
  const token = authStore.token;
  const data = {
    name: params.name?.trim(),
    description: params.description?.trim(),
    user_fields: [],
  };

  if (params.user_fields && params.user_fields.length) {
    params.user_fields.map((value, index) =>
      data.user_fields.push({ id: value.id_user_field, order: index }),
    );
  }
  return (
    await fetch(address_server + 'type-sample-sources/' + id + '?token=' + token, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).json();
}

export async function deleteTypeSampleSource(id) {
  const token = authStore.token;
  return (
    await fetch(address_server + 'type-sample-sources/' + id + '?token=' + token, {
      method: 'DELETE',
    })
  ).json();
}

export async function getTypeSampleSourcesList() {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'type-sample-sources-list?token=' + token)
  ).json();

  if (res.success) {
    return res;
  }
  error('getTypeSampleSourcesList', res);
  return res;
}

export async function toggleActivateTypeSampleSource(id) {
  const token = authStore.token;
  return (
    await fetch(address_server + 'type-sample-sources/' + id + '/toggle-activate?token=' + token, {
      method: 'POST',
    })
  ).json();
}
