import { useEffect, useState } from 'react';
import styles from './resetPassword.module.css';
import logo from '../../assets/svg/logo-01.svg';
import logo2 from '../../assets/svg/logo_corner-01.svg';
import error from '../../assets/svg/attention-01.svg';
import pass2 from '../../assets/svg/eye_open-01.svg';
import pass from '../../assets/svg/eye_closed-01.svg';
import { observer } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authStore } from 'store/auth.store';
import { FetchState } from 'consts/axios.consts';
import classNames from 'classnames';

//TODO: убрать в модуле ненужные стили
const ResetPassword = observer(() => {
  const { setRecoveryToken, fetchRecovery, fetchSetNewPassword, setNewPassword } = authStore;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [passOpen, setPassOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [errorPass, setErrorPass] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) setRecoveryToken(token);
    navigate('/recovery?badToken=true');
  }, []);

  useEffect(() => {
    if (fetchRecovery === FetchState.Rejected) navigate('/recovery?badToken=true');
  }, [fetchRecovery]);

  useEffect(() => {
    if (fetchSetNewPassword === FetchState.Rejected) setErrorPass(true);
  }, [fetchSetNewPassword]);

  return (
    <div className={styles.LoginBlock}>
      <div className={styles.Login}>
        <img src={logo} alt={'Logo'} className={styles.LoginLogoTop} />
        <div className={styles.LoginForm}>
          <div className={styles.ForgetText2}>
            Для завершения восстановления доступа необходимо задать новый пароль для Вашей учетной
            записи.
          </div>
          <div className={styles.LoginInputBlock2}>
            <div style={{ margin: '0 0 0 12px' }}>
              <div className={styles.LoginInputBlock}>
                <div className={styles.LoginText}>Пароль</div>
                <input
                  className={classNames(styles.LoginInput, {
                    [styles.LoginInputError]: errorPass,
                  })}
                  autoComplete={passOpen ? 'off' : 'new-password'}
                  name="passwordInput2"
                  id="passwordInput2"
                  type={passOpen ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorPass(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') setNewPassword(password);
                  }}
                />
              </div>
            </div>
            {passOpen ? (
              <img
                onClick={() => setPassOpen(false)}
                src={pass2}
                alt={'pass'}
                className={styles.LoginButtonPass}
              />
            ) : (
              <img
                onClick={() => setPassOpen(true)}
                src={pass}
                alt={'pass'}
                className={styles.LoginButtonPass}
              />
            )}
          </div>
          {errorPass && (
            <div className={styles.errorBlock2}>
              <img src={error} alt={'error'} />
              <div className={styles.LoginErrorText}>Пароль должен быть больше 3 символов</div>
            </div>
          )}
          <button
            className={classNames(styles.LoginButton, styles.LoginButtonNewPass)}
            onClick={() => setNewPassword(password)}
          >
            Отправить
          </button>
        </div>
        <img src={logo2} alt={'logo'} className={styles.LoginLogo} />
      </div>
    </div>
  );
});

export default ResetPassword;
