import styles from './css/SimplePicker.module.css';
import DropdownComponent from '../Dropdown/DropdownComponent';
import React, { useState } from 'react';

const SimplePicker = ({ selected, onChange, item }) => {
  const [statusList, setStatusList] = useState([
    { id: 'yes', name: 'Да' },
    { id: 'no', name: 'Нет' },
  ]);

  return (
    <div className={styles.container}>
      <DropdownComponent
        items={statusList}
        keyItem={'id'}
        valueItem={'name'}
        selectedKey={selected}
        arrowColor={'#086C22'}
        onChange={onChange}
      />
    </div>
  );
};

export default SimplePicker;
