import { useEffect, useState } from 'react';
import logo from '../../assets/svg/logo-01.svg';
import logo2 from '../../assets/svg/logo_corner-01.svg';
import error from '../../assets/svg/attention-01.svg';
import pass2 from '../../assets/svg/eye_open-01.svg';
import pass from '../../assets/svg/eye_closed-01.svg';
import { ReactComponent as Arrow } from '../../assets/svg/arrow-01.svg';
import styles from './loginPage.module.css';
import { observer } from 'mobx-react-lite';
import { authStore } from 'store/auth.store';
import classNames from 'classnames';
import NewPassword from 'pages/ResetPasswordPage';
import { FetchState } from 'consts/axios.consts';
import { useNavigate } from 'react-router-dom';

const LoginPage = observer(() => {
  const {
    setEmailList,
    emailList,
    startLogin,
    fetchLogin,
    isNotFoundToken,
    msgLoginError,
    isNewPassword,
    clear,
  } = authStore;

  const navigate = useNavigate();

  const [passOpen, setPassOpen] = useState(false);
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setEmailList();
    clear();
  }, []);

  useEffect(() => {
    if (fetchLogin === FetchState.Fulfilled) navigate('/');
  }, [fetchLogin]);

  useEffect(() => {
    if (isNotFoundToken) setPassword('');
  }, [isNotFoundToken]);

  const twoFunct = (_, login) => {
    setLoginValue(login.name);
    setEmail(login.login);
    setIsLoginDropdownOpen(!isLoginDropdownOpen);
  };

  return (
    <div className={styles.LoginBlock} onClick={() => setIsLoginDropdownOpen(false)}>
      <div className={classNames(styles.Login, { [styles.LoginErrorWight]: isNotFoundToken })}>
        <img src={logo} alt={'Logo'} className={styles.LoginLogoTop} />
        <div className={styles.LoginForm}>
          <div className={styles.LoginInputBlock2}>
            <div style={{ margin: '0 0 0 12px' }}>
              {emailList.length === 0 ? (
                <div className={styles.LoginInputBlock}>
                  <div className={styles.LoginText}>Email</div>
                  <input
                    className={styles.LoginInput}
                    type={'email'}
                    value={email}
                    onChange={(e) =>
                      setEmail(e.target.value.replace(/[^[a-zA-Z0-9@.()/?<>!#$%^&*{}\d]/gi, ''))
                    }
                  />
                </div>
              ) : (
                <div className={styles.LoginInputBlock} onClick={(e) => e.stopPropagation()}>
                  <div className={styles.LoginText}>Логин</div>
                  <form>
                    <input
                      className={styles.LoginInput}
                      autoComplete={'off'}
                      name="loginInput"
                      id="loginInput"
                      type="text"
                      value={loginValue}
                      onClick={() => {
                        setIsLoginDropdownOpen(!isLoginDropdownOpen);
                      }}
                    />
                  </form>
                  <Arrow
                    className={styles.arrowImg}
                    onClick={() => setIsLoginDropdownOpen(!isLoginDropdownOpen)}
                  ></Arrow>
                  {isLoginDropdownOpen ? (
                    <div className={styles.LoginDropdown}>
                      {emailList.map((item) => (
                        <div className={styles.dropdownGroup} key={item.group}>
                          <p className={styles.dropdownGroupName}>{item.group}</p>
                          {item.users.map((item2) => (
                            <p
                              key={item2.login}
                              className={styles.dropdownItemName}
                              onClick={(event) => twoFunct(event, item2)}
                            >
                              {item2.name}
                            </p>
                          ))}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
              <div className={styles.LoginInputBlock}>
                <div className={styles.LoginText}>Пароль</div>
                <input
                  className={classNames(styles.LoginInput, {
                    [styles.LoginInputError]: isNotFoundToken,
                  })}
                  autoComplete={passOpen ? 'off' : 'new-password'}
                  name="passwordInput"
                  id="passwordInput"
                  type={passOpen ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') startLogin(email, password);
                  }}
                />
              </div>
            </div>
            {passOpen ? (
              <img
                onClick={() => setPassOpen(false)}
                src={pass2}
                alt={'pass'}
                className={styles.LoginButtonPass}
              />
            ) : (
              <img
                onClick={() => setPassOpen(true)}
                src={pass}
                alt={'pass'}
                className={styles.LoginButtonPass}
              />
            )}
          </div>
          <div className={styles.LoginButtonForget} onClick={() => navigate('/recovery')}>
            Забыли пароль?
          </div>
          {isNotFoundToken && (
            <div className={styles.LoginError}>
              <img src={error} alt={'error'} />
              <div className={styles.LoginErrorText}>
                Пароль неверный, попробуйте еще раз или обратитесь к администратору.
              </div>
            </div>
          )}
          <button className={styles.LoginButton} onClick={() => startLogin(email, password)}>
            Вход
          </button>
        </div>

        {fetchLogin === FetchState.Rejected && (
          <div className={styles.LoginError2}>
            <img src={error} alt={'error'} />
            <div className={styles.LoginErrorText}>{msgLoginError}</div>
          </div>
        )}
        <img src={logo2} alt={'logo'} className={styles.LoginLogo} />
      </div>
      {isNewPassword && <NewPassword />}
    </div>
  );
});

export default LoginPage;
