import update from 'immutability-helper';
import React, { useEffect, useState, useCallback, useContext, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import classes from './workRightEditManual.module.css';
import classes2 from '../WorkRightManual/workRightManual.module.css';
import classes3 from '../../WorkRightCommon/WorkRight/workRight.module.css';

import WorkRightEditManualItem from './workRightEditManualItem';
import ModalInfoComponent, { StatusError } from '../../../Widgets/ModalInfo/ModalInfoComponent';

import { FieldsStore } from '../../../../store/FieldsStore';
import { StructureStore } from '../../../../store/StructureStore';

import { StatusSuccess, StatusWarning } from '../../../Widgets/ModalInfo/ModalInfoComponent';
import { modeContext } from '../../../Contexts/contexts';
import { AttentionIcon } from '../../../../helpers/icons';
import { create as createCatalog, getCatalog } from '../../../../services/catalog.services';
import { getListTypeFields } from '../../../../services/list.services';
import Icon from '../../../../helpers/components/Icon';
import { v4 as uuid } from 'uuid';
import { toJS } from 'mobx';

const WorkRightEditManual = observer(({ item }) => {
  const store = useContext(FieldsStore);
  const structureStore = useContext(StructureStore);
  const { mode } = useContext(modeContext);

  store.setOtherStructures(structureStore.fullCatalog);

  const [name] = useState(store.activeStructureName ?? item.name);

  const [modalOpened, setModalOpened] = useState(false);
  const [modalActionOnAccept, setModalActionOnAccept] = useState(() => () => setModalOpened(false));
  const [modalText, setModalText] = useState('');
  const [modalAcceptButtonText, setModalAcceptButtonText] = useState('Закрыть');
  const [modalTitle, setModalTitle] = useState('Новая структура справочника создана успешно!');
  const [modalStatus, setModalStatus] = useState(StatusSuccess);

  const openModalFromField = (order_field) => {
    store.setDeleteFieldId(order_field);
    const field = store.fieldsList.find((fld) => fld.order_field === order_field);
    setModalStatus(StatusError);
    setModalTitle('Вы уверены?');
    setModalText(
      `После удаления будет невозможно вернуть поле ${field.name !== '' ? `"${field.name}"` : '(Без названия)'}.`,
    );
    setModalAcceptButtonText('Удалить');
    setModalActionOnAccept('delete_field');
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const modalFunc = useMemo(() => {
    return async () => {
      if (modalStatus === StatusSuccess) {
        const item = await getCatalog(store.createdItemId);
        store.clearFields();
        mode.setType({ mode: 'view', item });
      } else if (modalStatus === StatusWarning) {
        if (modalActionOnAccept === 'error') {
          closeModal();
          setModalActionOnAccept(null);
        } else if (modalActionOnAccept === 'exit') {
          if (store.editingStructure || store.copiedStructure) {
            mode.setType({ mode: 'view', item });
          } else {
            mode.setType({ mode: 'view', item: '' });
          }
          setModalActionOnAccept(null);
        } else if (modalActionOnAccept === 'update') {
          setModalStatus(StatusSuccess);
          setModalActionOnAccept(null);
          await saveStructure();
        }
      } else if (modalStatus === StatusError) {
        store.deleteField();
        closeModal();
        setModalActionOnAccept(null);
      }
    };
  }, [modalOpened, modalStatus, modalTitle, modalText, modalAcceptButtonText, modalActionOnAccept]);

  const openModal = (type, code = 'exit') => {
    setModalActionOnAccept(code);
    if (type === StatusSuccess) {
      setModalStatus(StatusSuccess);
      setModalTitle(
        store.editingStructure
          ? 'Структура справочника обновлена успешно!'
          : 'Новая структура справочника создана успешно!',
      );
      setModalText(null);
      setModalAcceptButtonText('Закрыть');
      setModalOpened(true);
    }
    if (type === StatusWarning) {
      setModalStatus(StatusWarning);
      if (code === 'error') {
        setModalTitle('Ошибка!');
        setModalText('Проверьте валидность введённых данных.');
        setModalAcceptButtonText(`Ок`);
      } else if (code === 'exit') {
        setModalTitle('Вы уверены?');
        setModalText('Вы потеряете данные если отмените сохранение.');
        setModalAcceptButtonText(`Да!`);
      } else if (code === 'update') {
        setModalTitle('Внимание!');
        setModalText('Вы действительно хотите изменить данные?');
        setModalAcceptButtonText(`Продолжить`);
      }
      setModalOpened(true);
    }
  };

  const saveStructure = async () => {
    if (store.validateFields) {
      return;
    }
    let fields = [...store.fields].map((item, iterator) => {
      const keys = Object.keys(item);
      return keys.reduce((acc, key, i) => {
        if (key === 'opened' || key === 'active' || key === 'error' || key === 'hasErrors') {
          return acc;
        } else if (key === 'order_field') {
          acc[key] = iterator;
        }
        acc[key] = item[key];
        return acc;
      }, {});
    });

    const data = store.editingStructure
      ? { id: store.activeStructureId, name: store.activeStructureName, fields }
      : { name: store.activeStructureName, fields };

    const response = await createCatalog(data);

    if (response.success) {
      store.setCreatedItemId(response.result);
      structureStore.setSelectedStructure(response.result);
      openModal(StatusSuccess);
    } else {
      openModal(StatusWarning, 'error');
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const destinationIndex = result.destination.index;
    const sourceIndex = result.source.index;

    store.changeFieldOrder(destinationIndex, sourceIndex);
  };

  const listItems = store.fieldsList.map((item, index) => {
    return (
      <Draggable key={item.order_field} draggableId={`${item.order_field}`} index={index}>
        {(provided, snapshot) => (
          <WorkRightEditManualItem
            provided={provided}
            snapshot={snapshot}
            innerRef={provided.innerRef}
            item={item}
            modalOpen={(order_field) => openModalFromField(order_field, name)}
          />
        )}
      </Draggable>
    );
  });

  const dndItems = (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={'order_field'}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {listItems}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );

  useEffect(() => {
    getListTypeFields().then((res) => store.setListFieldTypes(res));
  }, []);

  return (
    <form className={classes2.workRightManual}>
      <div className={classes.workRightEditHeader}>
        <div className={classes.workRightEditHeaderText}> Структура справочника </div>
        <div style={{ position: 'relative', width: '100%' }}>
          <input
            className={`${classes.workRightEditHeaderInput} ${store.showStructureNameErrors.length ? classes.workRightEditHeaderInputError : ''}`}
            name={'name'}
            type={'text'}
            value={store.activeStructureName}
            onChange={(e) => store.changeActiveStructureName(e.target.value)}
            placeholder={'Название структуры'}
          />
          {store.showStructureNameErrors.length ? (
            <>
              <div className={classes.workRightEditHeaderError}>
                <Icon color={'#c12d2d'} src={AttentionIcon} width={'10px'} height={'10px'} />
                <span>{store.showStructureNameErrors}</span>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={classes.workRightEditBody}>
        {dndItems}

        <div className={classes.workRightEditAddItemBlock} onClick={store.makeNewField}>
          <div className={classes.workRightEditAddItem}>Добавить поле</div>
        </div>
        <div className={classes.workRightAddMenuSaveBlock}>
          <div
            className={classes3.workRightAddMenu_save}
            onClick={() =>
              store.activeStructureId ? openModal(StatusWarning, 'update') : saveStructure()
            }
          >
            Сохранить
          </div>
          <div
            className={classes3.workRightAddMenu_cancel}
            onClick={() => openModal(StatusWarning, 'exit')}
          >
            Отменить
          </div>
        </div>
      </div>
      <ModalInfoComponent
        key={uuid()}
        openModal={modalOpened}
        onClose={() => closeModal()}
        onAccept={() => modalFunc()}
        title={modalTitle}
        text={modalText}
        acceptButtonText={modalAcceptButtonText}
        status={modalStatus}
      />
    </form>
  );
});

export default WorkRightEditManual;
