import { address_server, address_server_short } from '../config';
import { error } from '../utils/handlerError';
import { transliterate } from '../helpers/utils';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { authStore } from 'store/auth.store';

export async function getSamples(storage, mode, filter = '', limit, page, sorting, pack = 0) {
  const token = authStore.token;
  let url = address_server + 'sample/samples?token=' + token + '&mode=' + mode; //+'&limit='+limit
  if (storage !== undefined) url += '&storage=' + storage;
  if (pack !== 0) url += '&pack=' + pack;
  if (filter !== '') url += '&search=' + filter;
  if (limit) url += '&offset=' + (page - 1) * limit;
  if (sorting?.field)
    url += '&sorting=' + JSON.stringify([{ field: sorting.field, type: sorting.type }]);
  // if (storageId !== 0) {
  //     url += '&storageId='+storageId
  // }

  const res = await (await fetch(url)).json();
  if (res.success) {
    return res.result;
  }
  error('getSamples', res);
  return [];
}

export async function getSamples2(storage = 0, mode, filter, limit, page, sorting, pack = 0) {
  const token = authStore.token;
  let url = address_server + 'sample/samples?token=' + token + '&mode=' + mode; //+'&limit='+limit
  if (storage !== undefined && storage != 0) url += '&storage=' + storage;
  if (pack !== 0) url += '&pack=' + pack;
  if (filter !== '') url += '&search=' + filter;
  if (limit) url += '&offset=' + (page - 1) * limit;
  if (sorting?.field)
    url += '&sorting=' + JSON.stringify([{ field: sorting.field, type: sorting.type }]);
  // if (storageId !== 0) {
  //     url += '&storageId='+storageId
  // }

  const res = await (await fetch(url)).json();
  if (res.success) {
    return res.result;
  }
  error('getSamples', res);
  return [];
}

export async function getTableSamples(parameters) {
  const token = authStore.token;
  let res = '';
  const formData = new FormData();
  formData.append('token', token);
  formData.append('page', parameters.page);

  if (parameters.columns != null && parameters.columns != undefined)
    formData.append('columns', parameters.columns);

  if (parameters.filterFields != null && parameters.filterFields.length > 0)
    formData.append('filter', JSON.stringify(parameters.filterFields));

  if (parameters.isSearchBarcode && parameters.filter != (null || undefined || ''))
    formData.append('is_barcode', parameters.isSearchBarcode);

  if (parameters.filter != (null || undefined || '')) formData.append('search', parameters.filter);

  if (parameters.sortField != (null || undefined || ''))
    formData.append('sorting', parameters.sortField);

  if (parameters.mode) formData.append('mode', 'archived');

  res = await (
    await fetch(address_server + 'samples/table', {
      method: 'POST',
      body: formData,
    })
  ).json();

  if (res.success) {
    return res;
  }
  error('getTableSamples', res);
  return res;
}

export async function getSample(id, mode) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'sample?token=' + token + '&id=' + id + '&mode=' + mode)
  ).json();
  if (res.success) {
    return res.result;
  }
  error('getSample', res);
  return [];
}

export async function getSampleTypes() {
  const token = authStore.token;
  const res = await (await fetch(address_server + 'list/user/fields?token=' + token)).json();
  if (res.success) {
    return res.result;
  }
  error('getSample', res);
  return [];
}

export async function getFileSample(item) {
  const token = authStore.token;
  return await (await fetch(address_server + 'files/load?path=' + item + '&token=' + token)).blob();
}

export async function editSample(id, params) {
  const token = authStore.token;
  let res = '';
  const formData = new FormData();
  formData.append('token', token);
  if (params?.name?.length !== 0) formData.append('name', params.name);
  formData.append('type', params.type);
  formData.append('mode', params.form);
  formData.append('storage', params.storage);
  formData.append('line', params.line);
  formData.append('column', params.column);
  formData.append('pack', params.pack);
  formData.append('description', params.description);
  formData.append('patient_description', params.patient_description);
  formData.append('barcodes', params.barcodes);
  formData.append('volume', params.volume === '' ? 0 : params.volume);
  formData.append('unit', params.unit);
  formData.append('catalogs', params.catalogs);

  formData.append('date_create', params.date);
  if (id) {
    formData.append('id', id);
  }
  res = await (
    await fetch(address_server + 'sample', {
      method: 'POST',
      body: formData,
    })
  ).json();

  if (res.success) {
    return res;
  }
  error('editSample', res);
  return res;
}

export async function editSampleUpdatedVersion(id, params) {
  const token = authStore.token;
  let res = '';
  const formData = new FormData();
  formData.append('token', token);
  if (params?.name?.length !== 0 && params.name !== 'null' && params.name !== null)
    formData.append('name', params.name);
  try {
    const fields = JSON.parse(params.user_fields);
    formData.append('user_fields', JSON.stringify(fields));
  } catch {
    formData.append('user_fields', JSON.stringify(params.user_fields));
  }

  formData.append('type', params.type);
  formData.append('mode', params.form);
  formData.append('storage', params.storage);
  formData.append('line', params.line);
  formData.append('column', params.column);
  if (params.defreeze && params.defreeze != 0) formData.append('defreeze', params.defreeze);
  formData.append('pack', params.pack);
  formData.append('description', params.description);
  formData.append(
    'patient_description',
    params.patient_description != null
      ? JSON.stringify(params.patient_description)
      : JSON.stringify([]),
  );

  if (params?.barcodes?.length != 0) formData.append('barcodes', JSON.stringify(params.barcodes));

  formData.append('volume', params.volume === '' ? 0 : params.volume);
  formData.append('unit', params.unit);
  formData.append('catalogs', JSON.stringify(params.catalogs));
  if (params.id_sample_source)
    formData.append('id_sample_source', JSON.stringify(params.id_sample_source));

  if (params.files?.length > 0) {
    params.files?.map((file) => {
      file?.item?.map((val) => {
        if (typeof val != 'string') formData.append(file.key, val, transliterate(val.name));
      });
    });
  }

  formData.append('date_create', dayjs(params.date).format('YYYY-MM-DD HH:mm:ss'));

  if (params.expire_date)
    formData.append('expire_date', dayjs(params.expire_date).format('YYYY-MM-DD HH:mm:ss'));

  if (id) {
    formData.append('id', id);
  }
  res = await (
    await fetch(address_server + 'sample', {
      method: 'POST',
      body: formData,
    })
  ).json();

  if (res.success) {
    return res;
  }
  error('editSample', res);
  return res;
}

export async function deleteSample(id) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'sample/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, id: id }),
    })
  ).json();

  if (res.success) {
    return res;
  }
  error('deleteSample', res);
  return [];
}
export async function moveSample(id, settings = {}) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', settings.id);
  if (settings.volume != null) formData.append('volume', settings.volume);
  formData.append('new_storage', settings.new_storage);
  formData.append('new_pack', settings.new_pack);
  formData.append('column', settings.column);
  formData.append('line', settings.line);

  return (
    await fetch(address_server + 'sample/move', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function copySample(id, packs, mode) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);
  if (packs) {
    formData.append(
      'packs',
      JSON.stringify(
        packs.map((val) => {
          return {
            id: val.packId,
            positions: [{ column: val.column, line: val.line }],
          };
        }),
      ),
    );
  }
  formData.append('mode', mode);

  return (
    await fetch(address_server + 'sample/copy', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function getAllTypeIcons() {
  const token = authStore.token;
  const res = (
    await fetch(address_server + 'type-sample/icons?token=' + token, {
      method: 'GET',
    })
  ).json();

  if (!res.success) {
    error('getAllTypeIcons', res);
  }
  return res;
}

export async function getSamplesByIconId(folder) {
  const token = authStore.token;
  const res = (
    await fetch(address_server + 'type-sample/icons/folder?token=' + token + '&folder=' + folder, {
      method: 'GET',
    })
  ).json();

  if (!res.success) {
    error('getSamplesByIconId', res);
  }
  return res;
}

export async function sampleUpsert(params) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('name', params.name.trim());
  formData.append('id_icon', params.id_icon);
  formData.append('id_unit', params.unit);
  if (params.defreeze) formData.append('defreeze', params.defreeze);

  formData.append(
    'fields',
    JSON.stringify(
      params.user_fields.map((val, index) => {
        return {
          id_field:
            val.id_field != null && val.id_field != undefined ? val.id_field : val.id_user_field,
          order: index,
        };
      }),
    ),
  );
  if (params.description != '' && params.description != undefined)
    formData.append('description', params.description.trim());

  if (params.id != null) formData.append('id', params.id);

  if (params.deleted_fields.length > 0) {
    const deleteArray = [];
    params.deleted_fields.map((val) => {
      if (
        !deleteArray.find(
          (val) =>
            val ==
            (val.id_field != null && val.id_field != undefined ? val.id_field : val.id_user_field),
        )
      ) {
        deleteArray.push(
          val.id_field != null && val.id_field != undefined ? val.id_field : val.id_user_field,
        );
      }
    });
    formData.append('deleted_fields', JSON.stringify(deleteArray));
  }

  return (
    await fetch(address_server + 'type-sample/upsert', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function getAllUserFields(page, limit, sorting, search) {
  const token = authStore.token;
  const res = await (
    await fetch(
      address_server +
        `type-sample/fields?token=${token}&page=${page}&limit=${limit}&sorting=${sorting}&search=${search}`,
    )
  ).json();
  if (res.success) {
    return res.result;
  }
  error('getAllUserFields', res);
  return {};
}

export async function fieldUpsert(params) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('description', params.description);
  formData.append('id_type', params.idType);
  formData.append('name', params.name.trim());
  formData.append('required', params.required);
  formData.append('setting', JSON.stringify(params.setting));
  formData.append('showed', params.showed);
  formData.append('sorting', params.sorting);
  formData.append('token', token);
  formData.append('unique', params.unique);

  return (
    await fetch(address_server + 'type-sample/fields/upsert', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function getTypeSampleById(params) {
  const token = authStore.token;
  const res = (
    await fetch(address_server + 'type-sample/getById?token=' + params.token + '&id=' + params.id, {
      method: 'GET',
    })
  ).json();

  if (!res.success) {
    error('getTypeSampleById', res);
  }
  return res;
}

export async function outSamples(sampleIds, comment) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append('samples_ids', JSON.stringify(sampleIds));

  if (comment) formData.append('comment', comment);

  return (
    await fetch(address_server + 'sample/extract', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function intoSamples(sampleIds, comment, volume) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append('samples_ids', JSON.stringify(sampleIds));

  if (comment) formData.append('comment', comment);

  if (volume) formData.append('volume', volume);

  return (
    await fetch(address_server + 'sample/return', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function restoreSample(id, column, line, pack) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append('id', id);

  if (column) formData.append('column', column);

  if (line) formData.append('line', line);

  if (pack) formData.append('pack', pack);

  return (
    await fetch(address_server + 'sample/restore', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function getSampleHistory(id) {
  const token = authStore.token;
  const res = (
    await fetch(address_server + 'sample/history?token=' + token + '&id=' + id, {
      method: 'GET',
    })
  ).json();

  if (!res.success) {
    error('getSampleHistory', res);
  }
  return res;
}

export async function createChildren(packs, type_sample, id, volume, unique, copy_fields) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append(
    'packs',
    JSON.stringify(
      packs.map((val) => {
        return {
          id: val.packId,
          positions: [{ column: val.column, line: val.line }],
        };
      }),
    ),
  );
  formData.append('type_sample', type_sample);
  formData.append('id', id);
  formData.append('volume', volume);

  if (unique) formData.append('unique', unique);

  if (copy_fields) formData.append('copy_fields', copy_fields);

  return (
    await fetch(address_server + 'sample/create-from', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function getSampleChildrenTree(id) {
  const token = authStore.token;
  const res = (
    await fetch(address_server + 'sample/source-tree?token=' + token + '&id=' + id, {
      method: 'GET',
    })
  ).json();

  if (!res.success) {
    error('getSampleChildrenTree', res);
  }
  return res;
}
