import React, { useContext, useEffect, useState } from 'react';
import classes from './workRightAddMenu.module.css';
import WidgetFileLoader from '../../../Widgets/WidgetFileLoader';
import WidgetEmail from '../../../Widgets/WidgetEmail';
import WidgetPhone from '../../../Widgets/WidgetPhone';
import WidgetUrl from '../../../Widgets/WidgetUrl';
import WidgetDateTimePicker from '../../../Widgets/WidgetDateTimePicker';
import WidgetSwitchVariants from '../../../Widgets/WidgetSwitchVariants';
import WidgetDropdownMultiple from '../../../Widgets/WidgetDropdownMultiple';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import { PeriodTimePicker } from '../../../Widgets/PeriodTimePicker';
import WidgetLocation from '../../../Widgets/WidgetLocation';
import RangeWidget from '../../../Widgets/RangeWidget';
import ProgressBarWidget from '../../../Widgets/ProgressBar';
import SelectPickerWidget from '../../../Widgets/SelectPicker';
import WidgetFinance from '../../../Widgets/WidgetFinance';
import WidgetFormula from '../../../Widgets/WidgetFormula';
import ColorPicker from '../../../Widgets/ColorPicker';
import TextareaAutosize from 'react-textarea-autosize';
import SwitchWidget from '../../../Widgets/Switch';
import IconPicker from '../../../Widgets/IconPicker';
import SimplePicker from '../../../Widgets/SimplePicker';
import {
  getListBarcodes,
  getListTypeFields,
  getListUnits,
} from '../../../../services/list.services';
import dayjs from 'dayjs';
import { getAllUsers } from '../../../../services/user.services';
import { modeContext } from '../../../Contexts/contexts';
import WidgetBarcode from '../../../Widgets/WidgetBarcode';
import { observer } from 'mobx-react-lite';
import { StructureStore } from '../../../../store/StructureStore';
import { toJS } from 'mobx';

const WorkRightAddMenuItem = observer(
  ({ item, selectItem, setWidget, setNewSelectItem, setFiles, isUserFieldsOperation = false }) => {
    const { mode } = useContext(modeContext);
    const manualStore = useContext(StructureStore);

    const [needTime, setNeedTime] = useState(false);
    const [userList, setUserList] = useState([]);
    const [units, setUnits] = useState([]);

    const getWeight = (settings) => {
      const max_size = item?.setting?.max_size;
      return max_size + 'Mb';
    };

    useEffect(() => {
      const loadUsers = async () => {
        const users = await getAllUsers();
        setUserList(users);
      };
      const loadBarcodes = async () => {
        if (!manualStore.barcodes.length) {
          await manualStore.setBarcodes();
        }
      };
      if (item.type === 'base') {
        const table = item?.setting?.find((item) => {
          return item.param === 'table';
        });
        if (table.value === 'users') {
          loadUsers();
        }
      }

      if (item.type === 'barcode') {
        loadBarcodes();
      }

      setNeedTime(item?.setting?.time ?? false);
    }, [item]);

    useEffect(() => {
      if (item.type == 'integer' || item.type == 'float') {
        const loadAllUnits = async () => {
          let res = await getListUnits();
          setUnits(res);
        };
        loadAllUnits();
      }
    }, []);

    return (
      <div className={classes.workRightAddMenu_table_row}>
        <div className={classes.workRightAddMenu_table_left}>{item.name} </div>
        <div className={classes.workRightAddMenu_table_right}>
          {item.type === 'integer' && (
            <div className={classes.numberBlock}>
              <input
                type={'number'}
                onWheel={(e) => e.target.blur()}
                className={classes.workRightAddMenuInput}
                value={selectItem?.value}
                onChange={(e) =>
                  setNewSelectItem({ value: e.target.value ?? ' ', setting: item.setting })
                }
              />
              <div>{units?.find((val) => val.id == item.setting.unit)?.name}</div>
            </div>
          )}
          {item.type === 'float' && (
            <div className={classes.numberBlock}>
              <input
                type={'number'}
                onWheel={(e) => e.target.blur()}
                step={0.5}
                className={classes.workRightAddMenuInput}
                value={selectItem?.value}
                onChange={(e) =>
                  setNewSelectItem({ value: e.target.value ?? ' ', setting: item.setting[0] ?? {} })
                }
              />
              <div>{units?.find((val) => val.id == item.setting.unit)?.name}</div>
            </div>
          )}
          {item.type === 'date' && (
            <WidgetDateTimePicker
              selected={
                selectItem.value && selectItem.value !== ' '
                  ? dayjs(selectItem.value).format(needTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')
                  : ''
              }
              onChange={(val) =>
                setNewSelectItem({
                  value: dayjs(val).format(needTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD') ?? ' ',
                  setting: {
                    date: dayjs(val).format(needTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD'),
                  },
                })
              }
              isDate={true}
              isDateTime={needTime}
            />
          )}
          {item.type === 'period' && (
            <PeriodTimePicker
              selected={selectItem}
              onChange={setNewSelectItem}
              isPeriodWithTime={needTime}
            />
          )}
          {item.type === 'time' && (
            <WidgetDateTimePicker
              selected={selectItem?.value}
              onChange={(val) =>
                setNewSelectItem({
                  value: dayjs(val).format('HH:mm') ?? ' ',
                  setting: { date: dayjs(val).format('HH:mm') },
                })
              }
              isTime={true}
            />
          )}
          {item.type === 'range' && (
            <RangeWidget
              selected={
                selectItem?.value && selectItem?.value !== ' '
                  ? [parseInt(selectItem.value)]
                  : [parseInt(item.setting?.find((ran) => ran.param === 'min')?.value)]
              }
              minVal={item.setting?.find((ran) => ran.param === 'min')?.value}
              maxVal={item.setting?.find((ran) => ran.param === 'max')?.value}
              onChange={(val) => {
                setNewSelectItem({
                  value: `${val}` ?? ' ',
                  setting: item.setting,
                });
              }}
              item={item}
            />
          )}
          {item.type === 'progress' && (
            <ProgressBarWidget selected={selectItem} onChange={setNewSelectItem} item={item} />
          )}
          {(item.type === 'string' || item.type === 'text') && (
            <TextareaAutosize
              className={classes.workRightAddMenuInput}
              value={selectItem?.value}
              onChange={({ target: { value } }) => {
                setNewSelectItem({ value: `${value}` ?? ' ', setting: {} });
              }}
            />
          )}
          {item.type === 'select' && (
            <DropdownComponent
              items={item.setting}
              selectedKey={selectItem?.setting?.value}
              onChange={(val) =>
                setNewSelectItem({
                  value: item.setting?.find((it) => it.value === val)?.param,
                  setting: {
                    value: val ?? ' ',
                    param: item.setting?.find((it) => it.value === val)?.param,
                  },
                })
              }
              keyItem={'value'}
              valueItem={'param'}
            />
          )}
          {item.type === 'select_check' && (
            <WidgetDropdownMultiple
              arrayItems={item.setting.reduce((acc, itemV) => {
                if (selectItem.value?.split(',')) {
                  selectItem.value?.split(',')?.map((si) => {
                    if (si == itemV.param) {
                      itemV['selected'] = true;
                    }
                  });
                }
                if (itemV.param !== 'multiply') {
                  acc.push(itemV);
                }
                return acc;
              }, [])}
              selected={selectItem.value}
              selectedItems={selectItem?.setting?.items?.split(',').map((val) => {
                return val.trim();
              })}
              multiply={
                item.setting ? item.setting.find((item) => item.param === 'multiply')?.value : false
              }
              itemKey={'param'}
              itemValue={'value'}
              setOutput={(val) =>
                setNewSelectItem({
                  value: val.value ?? ' ',
                  setting: {
                    items: val.items,
                  },
                })
              }
            />
          )}
          {item.type === 'email' && (
            <WidgetEmail
              value={selectItem?.value}
              onChange={(val) =>
                setNewSelectItem({
                  value: val ?? ' ',
                  setting: {},
                })
              }
            />
          )}
          {item.type === 'phone' && (
            <WidgetPhone
              value={selectItem?.value}
              onChange={(val) =>
                setNewSelectItem({
                  value: val ?? ' ',
                  setting: {},
                })
              }
            />
          )}
          {item.type === 'url' && (
            <WidgetUrl
              value={selectItem?.value}
              onChange={(val) =>
                setNewSelectItem({
                  value: val ?? ' ',
                  setting: {},
                })
              }
            />
          )}

          {item.type === 'switch_variants' && (
            <WidgetSwitchVariants
              key={item.id}
              className={classes.widgetSwitchVariants}
              item={item}
              current={selectItem?.setting}
              onChange={(val) => {
                setNewSelectItem({
                  value: val.param ?? ' ',
                  setting: {
                    name: val.param,
                    value: val.value,
                  },
                });
              }}
            />
          )}

          {item.type === 'base' && (
            <SelectPickerWidget
              selected={selectItem?.value}
              onChange={setNewSelectItem}
              item={item}
              items={userList}
            />
          )}
          {item.type === 'color' && (
            <ColorPicker
              selected={selectItem?.setting}
              onChange={(val) => {
                setNewSelectItem({
                  value: val.color ?? ' ',
                  setting: {
                    color: val.color,
                  },
                });
              }}
              item={item.value}
            />
          )}
          {item.type === 'icon' && (
            <IconPicker
              selected={
                { icon: { id: selectItem?.value, file: selectItem?.setting?.file } } ?? {
                  icon: { id: 1 },
                }
              }
              onChange={(val) => {
                setNewSelectItem({
                  value: val.icon.id,
                  setting: {
                    file: val.icon.file,
                  },
                });
              }}
              item={item}
            />
          )}
          {item.type === 'switch' && (
            <SwitchWidget
              selected={
                selectItem?.value !== '' && selectItem?.value ? selectItem?.value === 'true' : false
              }
              onChange={(val) => {
                setNewSelectItem({
                  value: `${!!val.value}`,
                });
              }}
              item={item}
            />
          )}
          {item.type === 'barcode' && (
            <WidgetBarcode
              availableBarcodes={manualStore.barcodes}
              onChange={setNewSelectItem}
              value={selectItem}
            />
          )}
          {item.type === 'yes_no' && (
            <SimplePicker
              selected={selectItem.value}
              onChange={(val) =>
                setNewSelectItem({
                  value: val ?? ' ',
                  setting: {},
                })
              }
              item={item}
            />
          )}
          {item.type === 'image' && (
            <WidgetFileLoader
              onLoadFile={(files) => {
                const setting = selectItem?.setting;
                setNewSelectItem({
                  value: '',
                  setting: toJS(setting),
                  files,
                });
                setFiles(files);
              }}
              onDeleteOldFile={(index) => {
                setWidget({
                  status: 'Sure',
                  text: 'Вы действительно хотите удалить файл?',
                  fun: async () => {
                    let settings = [...selectItem?.setting];
                    if (settings.length) settings.splice(index, 1);

                    setNewSelectItem({
                      ...selectItem,
                      value: '',
                      setting: settings,
                    });
                  },
                });
              }}
              oldFiles={isUserFieldsOperation ? selectItem?.setting : []}
              listFile={selectItem?.files || []}
              title="Перетащите файл сюда или выберите файл"
              subtitle={`Максимальный размер файла 
                        ${getWeight(item?.setting?.max_size)} 
                        (поддерживаемые форматы *.pdf, *.jpg)`}
              isImage={true}
              itemFromImage={item}
            />
          )}
          {item.type === 'file' && (
            <WidgetFileLoader
              onLoadFile={(files) => {
                const setting = toJS(selectItem?.setting);
                setNewSelectItem({
                  value: '',
                  setting,
                  files: toJS(files),
                });
                setFiles(files);
              }}
              onDeleteOldFile={(index) => {
                setWidget({
                  status: 'Sure',
                  text: 'Вы действительно хотите удалить файл?',
                  fun: async () => {
                    let settings = [...selectItem?.setting];
                    if (settings.length) settings.splice(index, 1);

                    setNewSelectItem({
                      ...selectItem,
                      value: '',
                      setting: settings,
                    });
                  },
                });
              }}
              oldFiles={isUserFieldsOperation ? selectItem?.setting : []}
              listFile={selectItem?.files || []}
              title="Перетащите файл сюда или выберите файл"
              subtitle={`Максимальный размер файла ${item?.setting?.max_size}Мб (поддерживаемые форматы ${item?.setting?.type?.join(', ')})`}
            />
          )}

          {item.type === 'calc' && (
            <WidgetFormula
              item={item}
              value={selectItem ?? {}}
              onChange={(val) =>
                setNewSelectItem({
                  value: val.result ?? ' ',
                  setting: val,
                })
              }
            />
          )}
          {item.type === 'finance' && (
            <WidgetFinance
              item={item}
              value={selectItem ?? {}}
              onChange={(val) =>
                setNewSelectItem({
                  value: val.result ?? ' ',
                  setting: val,
                })
              }
            />
          )}
          {item.type === 'location' && (
            <WidgetLocation item={item} value={selectItem} onChange={setNewSelectItem} />
          )}
        </div>
      </div>
    );
  },
);

export default WorkRightAddMenuItem;
