import React from 'react';
import ReactDOM from 'react-dom';
import classes from '../modals.module.css';
import closeSvg from '../../../../../assets/svg/close-01.svg';
import editSvg from '../../../../../assets/svg/edit-01.svg';
import Icon from '../../../../../helpers/components/Icon';
import { LicenseStore } from '../../../store/LicenseStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ModalContext } from '../../../context';
import Clipboard from 'clipboard';
import close from '../../../../../assets/svg/copy-01.svg';
import { appVersion } from '../../../../../version';

const portal = document.getElementById('skybox');

const InfoModal = observer(({ onClose }) => {
  const { selectLicenseItem } = React.useContext(LicenseStore);
  const { setShowModalAdd, setShowInfoModal } = React.useContext(ModalContext);

  const [countDays, setCountDays] = React.useState(0);
  const [supportCountDays, setSupportCountDays] = React.useState(0);
  const [sharedSupport, setSharedSupport] = React.useState(false);

  React.useEffect(() => {
    setCountDays(onCountDays(selectLicenseItem.expire_date));
  }, [selectLicenseItem.expire_date]);

  React.useEffect(() => {
    if (selectLicenseItem.support !== null) setSharedSupport(true);

    setSupportCountDays(onCountDays(selectLicenseItem.support));
  }, [selectLicenseItem.support]);

  const onCountDays = (field) => {
    if (field) {
      const dateEnd = moment(field);

      return dateEnd.diff(moment(), 'days');
    }
    return 0;
  };

  const handleCopyToClipboard = () => {
    const clipboard = new Clipboard('.copy-button');
    clipboard.on('success', (e) => {
      clipboard.destroy();
    });
  };

  const modal = (
    <div className={classes.modal}>
      <div className={classes.content}>
        <div className={classes.controls}>
          <div></div>
          {/*<Icon onClick={() => {*/}
          {/*  setShowModalAdd(true);*/}
          {/*  setShowInfoModal(false);*/}
          {/*}} src={editSvg} className={classes.closeIcon} color={"#01b454"}/>*/}
          <Icon onClick={onClose} src={closeSvg} className={classes.closeIcon} color={'#01b454'} />
        </div>
        <div className={classes.body}>
          <span className={classes.title}>Описание лицензии</span>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Наименование клиента: </p>
            <div>{selectLicenseItem?.company}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Версия ПО: </p>
            <div>{appVersion}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Код активации: </p>
            <div id={'code-license'}>{selectLicenseItem?.key}</div>

            <div
              onClick={handleCopyToClipboard}
              className="copy-button"
              style={{ width: 'auto' }}
              data-clipboard-target={`#code-license`}
            >
              <Icon color={'#1d6030'} width={'25px'} height={'25px'} src={close} />
            </div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>ID клиента: </p>
            <div>{selectLicenseItem?.id}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Версия лицензии: </p>
            <div>{!selectLicenseItem?.extension ? 'Basic' : 'Extended'}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Количество пользователей: </p>
            <div>{selectLicenseItem?.users}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Срок действия: </p>
            <div>
              {selectLicenseItem?.expire_date &&
                moment(selectLicenseItem?.expire_date).format('YYYY-MM-DD')}
            </div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Осталось дней: </p>
            <div>{countDays}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Расширенная тех. поддержка: </p>
            <div>{sharedSupport ? ' Включена' : ' Выключена'}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Срок действия: </p>
            <div>
              {selectLicenseItem?.support &&
                moment(selectLicenseItem?.support).format('YYYY-MM-DD')}
            </div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Осталось дней: </p>
            <div>{supportCountDays}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
});

export default InfoModal;
