import { authStore } from 'store/auth.store';
import { address_server } from '../config';

export async function getNotificationSettings(sample) {
  const token = authStore.token;
  return (
    await fetch(address_server + 'sample/notifications?token=' + token + '&sample=' + sample, {
      method: 'GET',
    })
  ).json();
}

export async function getNotificationMessage() {
  const token = authStore.token;
  return (
    await fetch(address_server + 'notifications?token=' + token, {
      method: 'GET',
    })
  ).json();
}

export async function upsertNotificationSettings(id, sample, count, expire, volume, defreeze) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append('sample', sample);

  if (id) formData.append('id', id);

  if (count) formData.append('count', count);

  if (expire) formData.append('expire', expire);

  if (volume) formData.append('volume', volume);

  if (defreeze) formData.append('defreeze', defreeze);

  return (
    await fetch(address_server + 'sample/notifications', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function readNotification(id) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append('id', id);

  return (
    await fetch(address_server + 'notifications/mark', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function readAllNotification() {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);

  return (
    await fetch(address_server + 'notifications/markAll', {
      method: 'POST',
      body: formData,
    })
  ).json();
}
