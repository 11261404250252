import React, { useEffect, useState } from 'react';
import classes from './workRightSelectPage.module.css';
import str from '../../../../assets/svg/arrow-01.svg';
import WorkRightSelectPageItem from './workRightSelectPageItem';

const WorkRightSelectPage = ({ allCount, selectItem, setSelectItem }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    let t = [];
    if (allCount < 7) {
      if (allCount > 1) for (let i = 1; i <= allCount; i++) t.push(i);
    } else {
      if (
        selectItem === 1 ||
        selectItem === 2 ||
        selectItem === allCount ||
        selectItem === allCount - 1
      ) {
        for (let i = 1; i <= 3; i++) t.push(i);
        t.push('...');
        for (let i = allCount - 2; i <= allCount; i++) t.push(i);
      }
      if (selectItem === 3) {
        for (let i = 1; i <= 4; i++) t.push(i);
        t.push('...');
        for (let i = allCount - 1; i <= allCount; i++) t.push(i);
      }
      if (selectItem === allCount - 2) {
        t.push(1);
        t.push(2);
        t.push('...');
        for (let i = allCount - 3; i <= allCount; i++) t.push(i);
      }
      if (selectItem > 3 && selectItem < allCount - 2) {
        t.push(1);
        t.push('...');
        t.push(selectItem - 1);
        t.push(selectItem);
        t.push(selectItem + 1);
        t.push('... ');
        t.push(allCount);
      }
    }
    setItems(t);
  }, [allCount, selectItem]);
  return (
    <div className={classes.workRightSelectPage}>
      {allCount > 1 && (
        <div
          className={classes.workRightSelectPage_str_left_block}
          onClick={() => {
            if (selectItem !== 1) setSelectItem(selectItem - 1);
          }}
        >
          <img className={classes.workRightSelectPage_str_left} src={str} alt={'str'} />
        </div>
      )}
      {items.map((item) => (
        <WorkRightSelectPageItem
          allCount={allCount}
          item={item}
          selectItem={selectItem}
          setSelectItem={setSelectItem}
          key={item}
        />
      ))}
      {allCount > 1 && (
        <div
          className={classes.workRightSelectPage_str_right_block}
          onClick={() => {
            if (selectItem !== allCount) setSelectItem(selectItem + 1);
          }}
        >
          <img className={classes.workRightSelectPage_str_right} src={str} alt={'str'} />
        </div>
      )}
    </div>
  );
};

export default WorkRightSelectPage;
