import styles from './pageHeader.module.css';
import searchSVG from '../../../../assets/svg/serch-01.svg';
import { observer } from 'mobx-react-lite';

const PageHeader = observer(({ setVisibleAdd, filter, setFilter }) => {
  return (
    <div className={styles.workRightHeader}>
      <div className={styles.workRightHeader_block}>
        <div className={styles.WorkLeftHeader_search}>
          <img className={styles.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'} />
          <input
            className={styles.WorkLeftHeader_search_input}
            placeholder={'Отобрать'}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>

        <div className={styles.workRightHeader_add_block}>
          <div className={styles.workRightHeader_add} onClick={setVisibleAdd}></div>
        </div>
      </div>
    </div>
  );
});

export default PageHeader;
