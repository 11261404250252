import React from 'react';
import classes from '../BarcodesMultiList/css/barcodesList.module.css';
import DropdownComponent from '../Dropdown/DropdownComponent';

const MultiSelectBoxItem = ({
  keyValue = 'id',
  displayValue = 'name',
  id,
  item,
  isLastItem,
  onChange,
  onAddElementClick,
  onDeleteElementClick,
  dataSource = [],
  index,
  setWidget,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      className={classes.containerParent}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={classes.container}>
        <div className={classes.barcodeContainer} style={{ minWidth: '250px' }}>
          <DropdownComponent
            key={`${id}${item?.id}`}
            items={dataSource}
            selectedKey={item?.id ?? 0}
            onChange={(val) => onChange(index, val, keyValue)}
          />
        </div>
      </div>
      <div className={`${classes.buttons} `}>
        {hovered && (
          <>
            {id > 0 && (
              <div
                className={classes.buttonClear}
                onClick={() =>
                  setWidget({
                    status: 'Sure',
                    text: 'Вы уверены что хотите удалить?',
                    fun: async () => {
                      onDeleteElementClick(index);
                    },
                  })
                }
              >
                <div className={'close_icon'}></div>
              </div>
            )}
            {isLastItem && (
              <div className={classes.buttonAdd} onClick={() => onAddElementClick()}>
                <div className={'plus_icon'}></div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MultiSelectBoxItem;
