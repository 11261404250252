import React, { useMemo, useRef } from 'react';
import close from '../../../assets/svg/close-01.svg';
import { COLORS } from '../../../config';
import Icon from '../../../helpers/components/Icon';
import styles from './modal.module.css';

const StatusSuccess = 0;
const StatusError = 1;
const StatusInfo = 2;
const StatusWarning = 3;

const statuses = {
  [StatusSuccess]: {
    color: COLORS.color_brand,
    class: styles.success,
    classIcon: 'modalOk_icon',
    buttons: [
      {
        text: 'Закрыть',
        type: 'accept',
        color: COLORS.color_brand,
        class: styles.success,
      },
    ],
  },
  [StatusError]: {
    color: '#c32b2a',
    class: styles.danger,
    classIcon: 'modalSure_icon',
    buttons: [
      {
        text: 'Отмена',
        color: 'none',
        type: 'close',
        class: styles.close,
      },
      {
        text: 'Да',
        type: 'accept',
        color: '#c32b2a',
        class: styles.danger,
      },
    ],
  },
  [StatusInfo]: {
    color: '#2ac49e',
    class: styles.info,
    classIcon: 'modalInfo_icon',
    buttons: [
      {
        text: 'Закрыть',
        type: 'accept',
        color: '#2ac49e',
        class: styles.info,
      },
    ],
  },
  [StatusWarning]: {
    color: '#fe6e02',
    class: styles.warning,
    classIcon: 'modalAttention_icon',
    buttons: [
      {
        text: 'Отмена',
        type: 'close',
        color: 'none',
        class: styles.close,
      },
      {
        text: 'Да',
        type: 'accept',
        color: '#fe6e02',
        class: styles.warning,
      },
    ],
  },
};

const ModalInfoComponent = ({
  status = StatusInfo,
  title,
  text = 'Успешно!',
  acceptButtonText = 'Да',
  openModal,
  onAccept,
  onClose,
}) => {
  const selfRef = useRef(null);
  const modalBGRef = useRef(null);

  const closeModalTappedOnBg = (ev) => {
    if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
      onClose();
    }
  };

  const statusImageSrc = useMemo(() => {
    return statuses[status].classIcon;
  }, [status]);

  const statusButtons = useMemo(() => {
    return statuses[status].buttons.map((btn) => {
      return (
        <div
          className={`${styles.button} ${btn.class}`}
          onClick={btn.type === 'accept' ? onAccept : onClose}
        >
          {btn.type === 'accept' ? (acceptButtonText ?? btn.text) : btn.text}
        </div>
      );
    });
  }, [status]);

  const modal = (
    <>
      <div ref={modalBGRef} onClick={(ev) => closeModalTappedOnBg(ev)} className={styles.modalBG}>
        <div className={styles.modal} ref={selfRef}>
          <div className={styles.header}>
            <div className={styles.closeButton} onClick={() => onClose()}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.bodyImage}>
              <div className={`${styles.bodyImage_self} ${statusImageSrc} `}></div>
            </div>
            <div className={`${styles.bodyInfo} ${!text || text === '' ? styles.bodyInfoJE : ''}`}>
              <div className={styles.bodyTitle}> {title} </div>
              {text ? (
                <>
                  <div className={styles.bodyText}>{text}</div>
                </>
              ) : (
                ''
              )}
              <div className={styles.bodyButtons}>{statusButtons}</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );

  return openModal ? modal : <></>;
};

export default ModalInfoComponent;

export { StatusSuccess };
export { StatusError };
export { StatusInfo };
export { StatusWarning };
