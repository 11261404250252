import React from 'react';
import styles from './table.module.css';
import WorkRightTableHeader from '../../../../components/WorkRight/WorkRightCommon/WorkRightTable/workRightTableHeader';
import TableRow from '../TableRow/TableRow';
import { LicenseStore } from '../../store/LicenseStore';
import { ModalContext } from '../../context';

const Table = ({ struct, rows, sortField, setSortField, onDelete }) => {
  const { setShowInfoModal, setShowModalAdd } = React.useContext(ModalContext);
  const { setSelectLicenseItem } = React.useContext(LicenseStore);

  return (
    <table width={'100%'} className={styles.workRightTable}>
      <tbody style={{ width: '100%' }}>
        <tr>
          {struct?.map((structItem) => (
            <WorkRightTableHeader
              data={structItem}
              key={structItem.name}
              sortField={sortField}
              setSortField={setSortField}
              count={3}
            />
          ))}
        </tr>
        {rows?.map((rowsItem) => (
          <TableRow
            selectRowItem={() => {
              setSelectLicenseItem(rowsItem);
              setShowInfoModal(true);
              setShowModalAdd(false);
            }}
            startEditRowItem={() => {
              setSelectLicenseItem(rowsItem);
              setShowInfoModal(false);
              setShowModalAdd(true);
            }}
            onDeleteItem={() => onDelete(rowsItem.id)}
            struct={struct}
            rowsItem={rowsItem}
            key={rowsItem.id}
          />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
