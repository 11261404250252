import React from 'react';
import classes from './leftMenu.module.css';

const LeftMenuItem2 = ({ fullMenu, menuItem, onClick = null }) => {
  const rootClasses = [classes.LeftMenu_Menu_Item2];
  if (!fullMenu) {
    rootClasses.push(classes.LeftMenu_Menu_Item2_small);
  }
  return (
    <div
      className={rootClasses.join(' ')}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div
        style={{
          WebkitMaskImage: 'url("/svg/' + menuItem.src + '")',
          maskImage: 'url("/svg/' + menuItem.src + '")',
        }}
        className={classes.LeftMenu_Menu_Item_img2}
      ></div>
      {fullMenu && <div className={classes.LeftMenu_Menu_Item_text2}>{menuItem.text}</div>}
    </div>
  );
};

export default LeftMenuItem2;
