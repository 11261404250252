import React, { useEffect, useState } from 'react';
import styles from './image-popup.component.module.css';
import { address_server_short } from '../../../config';
import close from '../../../assets/svg/close-01.svg';
import triangle from '../../../assets/svg/triangle-01.svg';
import Icon from '../../../helpers/components/Icon';
import classes from '../../Header/header.module.css';

const ImagePopupComponent = ({ images, isPopupOpen, onClosePopup }) => {
  const [count, setCount] = useState(0);
  const [imgs, setImgs] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  useEffect(() => {
    let array = Object.values(images).map((item) => item[0]);
    let validImages = [];

    array.forEach((image) => {
      if (image && image !== '') {
        validImages.push(image);
      }
    });

    setImgs(validImages);
    setCount(validImages.length);
  }, [images]);

  const setImageIndex = (newIndex) => {
    if (newIndex >= count) {
      setCurrentImage(0);
      return;
    }
    if (newIndex < 0) {
      setCurrentImage(count - 1);
      return;
    }

    setCurrentImage(newIndex);
  };

  return (
    isPopupOpen && (
      <div className={styles.modalBG}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <div className={styles.closeButton} onClick={() => onClosePopup(false)}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.img_container}>
              {
                // count > 1 && <div className={styles.side_button} >
                //               <Icon color={'#fff'}
                //                     className={styles.left}
                //                     width={'50px'}
                //                     height={'30px'}
                //                     src={triangle}/>
                //             </div>

                count > 1 && (
                  <img
                    className={styles.side_button + ' ' + styles.left}
                    alt={'alt'}
                    src={'/svg/arrow-01.svg'}
                    onClick={() => setImageIndex(currentImage - 1)}
                  />
                )
              }
              <img
                src={address_server_short + imgs[currentImage]}
                alt={'img'}
                className={styles.image}
              />
              {count > 1 && (
                <img
                  className={styles.side_button + ' ' + styles.right}
                  alt={'alt'}
                  src={'/svg/arrow-01.svg'}
                  onClick={() => setImageIndex(currentImage + 1)}
                />
              )}
            </div>
            {count > 1 && (
              <div className={styles.counter}>
                {currentImage + 1} / {count}
              </div>
            )}
            {/*<div className={styles.mini}>*/}
            {/*  {*/}
            {/*    imgs?.map(img => {*/}
            {/*      return  <img src={address_server_short + img} alt={'img'} className={styles.mini_image} key={img}/>*/}
            {/*    })*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    )
  );
};

export default ImagePopupComponent;
