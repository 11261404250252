import { useContext } from 'react';
import classes from './leftMenu.module.css';
import { modeContext } from '../Contexts/contexts';
import { getReportsGroups } from '../../services/report.services';
import { getCatalogs } from '../../services/catalog.services';
import { StructureStore } from '../../store/StructureStore';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import { useState } from 'react';
import LeftMenuItemChild from './leftMenuItemChild';
import { observer } from 'mobx-react-lite';
import { authStore } from 'store/auth.store';

const LeftMenuItem = observer(({ fullMenu, menuItem }) => {
  const { mode } = useContext(modeContext);
  const modeMain = useContext(modeContext);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [points, setPoints] = useState({ top: 0, left: 0 });

  const openTab = async (type) => {
    if (menuItem.text === 'Отчеты') {
      await new Promise(async (resolve) => {
        const response = await getReportsGroups();
        resolve(response[0]);
      }).then((responseItem) => {
        mode.setType({ mode: 'view', item: responseItem });
      });
    } else if (menuItem.text === 'Справочники') {
      structureStore.setSelectedStructure(mode.type.item.id);
      await new Promise(async (resolve) => {
        const response = await getCatalogs();
        resolve(response[0]);
      }).then((responseItem) => {
        mode.setType({ mode: 'view', item: responseItem });
      });
    } else {
      mode.setType({ mode: 'view', item: '' });
    }

    mode.setVisibleAdd(false);
    mode.setVisibleView(false);

    mode.setParentTabId(menuItem.children ? menuItem.id : 0);

    mode.setPath(menuItem.children ? menuItem.children[0].text : menuItem.text);

    const createNewTab = () => {
      const newTab = {
        name: menuItem.children ? menuItem.children[0].text : menuItem.text,
        path: menuItem.children ? menuItem.children[0].text : menuItem.text,
        parentTabId: menuItem.children ? menuItem.id : 0,
        mode: JSON.parse(JSON.stringify(modeMain)),
        id: new Date().getTime(),
      };

      localStorage.setItem('active_tabs', newTab.id);

      mode.setTabs([...mode.tabs, newTab]);
      mode.setActiveTabId(newTab.id);
      mode.setVisibleAdd(false);
    };

    if (type == 'current') {
      const tabs = [...mode.tabs];
      const tab = tabs.find((i) => i.id === mode.activeTabId);
      if (tab) {
        tab.path = menuItem.children ? menuItem.children[0].text : menuItem.text;
        tab.name = menuItem.children ? menuItem.children[0].text : menuItem.text;
        tab.parentTabId = menuItem.children ? menuItem.id : 0;
        tab.mode = JSON.parse(JSON.stringify(modeMain));
        mode.setTabs(tabs);
      } else {
        createNewTab();
      }
    } else {
      createNewTab();
    }

    setOpenContextMenu(false);
  };

  const structureStore = useContext(StructureStore);
  const rootClasses = [classes.LeftMenu_Menu_Item];
  if (!fullMenu) {
    rootClasses.push(classes.LeftMenu_Menu_Item_small);
  }
  if (mode.path === menuItem.text || mode.parentTabId == menuItem.id) {
    rootClasses.push(classes.LeftMenu_Menu_Item_Active);
  }

  return (
    <div className={classes.reletiveBlock}>
      <div
        className={rootClasses.join(' ')}
        onContextMenu={(e) => {
          e.preventDefault();
          setOpenContextMenu(true);
          setPoints({ top: e.pageY, left: e.pageX  });
        }}
        onClick={async (e) => {
          await openTab('current');
        }}
      >
        <div
          style={{
            WebkitMaskImage: 'url("/svg/' + menuItem.src + '")',
            maskImage: 'url("/svg/' + menuItem.src + '")',
          }}
          className={classes.LeftMenu_Menu_Item_img}
        ></div>
        {fullMenu && (
          <div className={classes.LeftMenu_Menu_Item_text}>
            <div className={classes.LeftMenu_Menu_Item_Relative}>{menuItem.text}</div>
          </div>
        )}
      </div>
      {menuItem?.children && (
        <div
          className={
            classes.childrenContainer +
            ' ' +
            (mode.path === menuItem.text || !!mode.parentTabId
              ? classes.childrenContainerActive
              : '')
          }
        >
          {menuItem?.children.map((val, index) => {
            return (
              <LeftMenuItemChild key={index} fullMenu={fullMenu} menuItem={val}></LeftMenuItemChild>
            );
          })}
        </div>
      )}

      {openContextMenu && (
        <ContextMenu close={() => setOpenContextMenu(false)} top={points.top} left={points.left}>
          <ContextMenuItem
            onClick={async () => {
              await openTab('newTab');
            }}
          >
            Открыть в новой вкладке
          </ContextMenuItem>
          <ContextMenuItem
            onClick={async () => {
              await openTab('current');
            }}
          >
            Открыть в текущей вкладке
          </ContextMenuItem>
        </ContextMenu>
      )}
    </div>
  );
});

export default LeftMenuItem;
