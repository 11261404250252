import classes from './WidgetUrl.module.css';

const WidgetEmail = ({ value, onChange }) => {
  return (
    <input
      type="url"
      className={classes.input}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

export default WidgetEmail;
