import { createContext, useEffect } from 'react';

function useOutside(containerRef, contextMenuRef, callback = () => {}) {
  function handleClickOutside(event) {
    if (
      containerRef?.current?.contains(event.target) &&
      !contextMenuRef?.current?.contains(event.target)
    ) {
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export default useOutside;
