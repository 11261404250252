import { useEffect } from 'react';
import cyrillicToTranslit from 'cyrillic-to-translit-js';

function useOutsideClick(ref, setFunc) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFunc(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function transliterate(word) {
  return cyrillicToTranslit().transform(word);
}

function unTransliterate(word) {
  return cyrillicToTranslit().reverse(word);
}

export default useOutsideClick;
export { useOutsideClick };
export { transliterate };
export { unTransliterate };
