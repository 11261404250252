import React from 'react';
import { COLORS } from '../../../config';
import classes from './css/range.module.css';
import './css/slider.css';
import { Slider } from 'rsuite';

const color_list = [COLORS.color_brand, COLORS.bg_inactive];

const RangeWidget = ({
  selected,
  onChange,
  item,
  minVal = false,
  maxVal = false,
  withoutInfo = false,
}) => {
  const min = minVal ?? parseInt(item?.setting?.range?.min);
  const max = maxVal ?? parseInt(item?.setting?.range?.max);

  selected = selected.length ? selected[0] : min;

  const [value, setValue] = React.useState(selected);

  React.useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className={classes.range_wrapper}>
      {value}
      <Slider min={+min} max={max} progress value={value} onChange={setValue} />
    </div>
  );
};

export default RangeWidget;
