import ReactDOM from 'react-dom';
import defaultStyle from '../ModalDefaultStyle.module.css';
import Icon from '../../../helpers/components/Icon';
import close from '../../../assets/svg/close-01.svg';
import DropdownComponent from '../../Widgets/Dropdown/DropdownComponent';
import style from './ModalCreateChildren.module.css';
import { COLORS } from '../../../config';
import confirmSVG from '../../../assets/svg/button_on-01.svg';
import TextareaAutosize from 'react-textarea-autosize';
import { createChildren } from '../../../services/sample.services';
import { useContext, useEffect, useState } from 'react';
import { getListUnits, getTypeSamples } from '../../../services/list.services';
import { Store } from '../../../store/Store';
import { modeContext } from '../../Contexts/contexts';
import classes from '../../WorkRight/WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css';
import error from '../../../assets/svg/attention-01.svg';
import { observer } from 'mobx-react-lite';

const ModalCreateChildren = observer(({ mode, setClose, sampleId, selectedTypeId }) => {
  const { widget } = useContext(modeContext);
  const { loadSampleById, updateSelectedTreeNodePacks } = useContext(Store);

  const [types, setTypes] = useState();
  const [selectedType, setSelectedType] = useState(selectedTypeId);
  const [volume, setVolume] = useState(1);
  const [volumeName, setVolumeName] = useState();
  const [unique, setUnique] = useState(false);
  const [copyFields, setCopyFields] = useState(false);
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    const loadTypes = async () => {
      let res = await getTypeSamples();
      setTypes(res);
    };
    loadTypes();
  }, []);

  useEffect(() => {
    const loadTypes = async () => {
      let res = await getListUnits();
      setVolumeName(
        res?.find((item) => item.id == types?.find((val) => val.id == selectedType)?.unit)?.name,
      );
    };
    loadTypes();
  }, [selectedType, selectedTypeId, types]);

  const clickUpsert = async () => {
    setIsClick(true);
    if (volume != '' && volume > 0) {
      const res = await createChildren(
        mode.selectedEmptyCellForCopy,
        selectedType,
        sampleId,
        volume,
        unique,
        copyFields,
      );
      if (res.success)
        widget.setWidget({
          status: 'Ok',
          text: 'Данные успешно добавлены!',
          fun: async () => {
            mode.setSelectedEmptyCellForCopy([]);
            await updateSelectedTreeNodePacks();
            setClose(false);
          },
        });
      else
        widget.setWidget({
          status: 'Error',
          text: res.error_code,
          fun: async () => {},
        });
    }
  };

  const closeModal = () => {
    setClose(false);
  };

  const modal = (
    <div className={defaultStyle.modalContainer}>
      <div className={defaultStyle.modal + ' ' + style.modal}>
        <div className={defaultStyle.header}>
          <div className={defaultStyle.closeButton} onClick={closeModal}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={defaultStyle.body}>
          <div className={defaultStyle.title}>
            Создать новые производные образцы, связанные с выбранным
          </div>
          <div className={defaultStyle.bodyRow}>
            <div className={style.lable}>Определить новый тип образца, связанный с выбранным:</div>
            <div className={style.item}>
              <DropdownComponent
                key={'line_1'}
                selectedKey={selectedType}
                items={types}
                onChange={(val) => setSelectedType(val)}
              />
            </div>
          </div>
          <div className={defaultStyle.bodyRow}>
            <div className={style.lable}></div>
            <div className={style.item}>
              <div className={style.checkboxContainer}>
                <div
                  className={style.checkbox + ' ' + (true ? style.checkboxSelected : '')}
                  onClick={() => {
                    setUnique((prev) => !prev);
                  }}
                >
                  {unique && (
                    <Icon
                      color={COLORS.color_hover2}
                      src={confirmSVG}
                      width={'17px'}
                      height={'17px'}
                    />
                  )}
                </div>
                <div className={style.checkboxText}>Создать уникальные образцы</div>
              </div>
            </div>
          </div>
          <div className={defaultStyle.bodyRow}>
            <div className={style.lable}></div>
            <div className={style.item}>
              <div className={style.checkboxContainer}>
                <div
                  className={style.checkbox + ' ' + (true ? style.checkboxSelected : '')}
                  onClick={() => {
                    setCopyFields((prev) => !prev);
                  }}
                >
                  {copyFields && (
                    <Icon
                      color={COLORS.color_hover2}
                      src={confirmSVG}
                      width={'17px'}
                      height={'17px'}
                    />
                  )}
                </div>
                <div className={style.checkboxText}>Копировать поля образца</div>
              </div>
            </div>
          </div>
          <div className={defaultStyle.bodyRow} style={{ position: 'relative' }}>
            <div className={style.lable}>Количество ({volumeName}):</div>
            <div className={style.item}>
              <input
                type={'number'}
                value={volume}
                onWheel={(e) => e.target.blur()}
                className={defaultStyle.input}
                onChange={(e) => {
                  setVolume(e.target.value);
                }}
              />
            </div>
            {(volume == '' || volume <= 0) && isClick && (
              <div className={style.dataErrorBlock}>
                <img src={error} alt={'error'} />
                {volume == '' ? (
                  <div className={style.dataError}>Поле обязательное для заполнения.</div>
                ) : (
                  <div className={style.dataError}>Количесвто должно быть больше нуля.</div>
                )}
              </div>
            )}
          </div>
          <div className={defaultStyle.bodyRow}>
            <div className={style.lable}>Комментарий:</div>
            <div className={style.item}>
              <TextareaAutosize
                maxRows={10}
                placeholder={'Комментарий...'}
                className={defaultStyle.input}
                style={{ cursor: 'default' }}
                onChange={(e) => {}}
              />
            </div>
          </div>
        </div>
        <div className={defaultStyle.footer}>
          <button
            className={`button_default save_style`}
            onClick={async () => {
              await clickUpsert();
            }}
          >
            Сохранить
          </button>
          <button className={`button_default cancel_style`} onClick={closeModal}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
});

export default ModalCreateChildren;
