import { useState } from 'react';
import classes from './authorization.module.css';
import logo from '../../assets/svg/logo-01.svg';
import logo2 from '../../assets/svg/logo_corner-01.svg';
import pass2 from '../../assets/svg/eye_open-01.svg';
import pass from '../../assets/svg/eye_closed-01.svg';

const AuthorizationAdmin = ({ onLogin }) => {
  const [passOpen, setPassOpen] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className={classes.LoginBlock}>
      <div className={classes.Login}>
        <img src={logo} alt={'Logo'} className={classes.LoginLogoTop} />
        <div className={classes.LoginForm}>
          <div className={classes.LoginInputBlock2}>
            <div className={classes.LoginInputBlock}>
              <div className={classes.LoginText}>Логин</div>
              <input
                className={classes.LoginInput}
                name="emailInput"
                id="emailInput"
                type={'text'}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onLogin(loginValue, password);
                }}
              />
            </div>
          </div>

          <div className={classes.LoginInputBlock2}>
            <div style={{ margin: '0 0 0 12px' }}>
              <div className={classes.LoginInputBlock}>
                <div className={classes.LoginText}>Пароль</div>
                <input
                  className={classes.LoginInput}
                  autoComplete={passOpen ? 'off' : 'new-password'}
                  name="passwordInput"
                  id="passwordInput"
                  type={passOpen ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') onLogin(loginValue, password);
                  }}
                />
              </div>
            </div>
            {passOpen ? (
              <img
                onClick={() => setPassOpen(false)}
                src={pass2}
                alt={'pass'}
                className={classes.LoginButtonPass}
              />
            ) : (
              <img
                onClick={() => setPassOpen(true)}
                src={pass}
                alt={'pass'}
                className={classes.LoginButtonPass}
              />
            )}
          </div>

          <button className={classes.LoginButton} onClick={() => onLogin(loginValue, password)}>
            Вход
          </button>
        </div>
        <img src={logo2} alt={'logo'} className={classes.LoginLogo} />
      </div>
    </div>
  );
};

export default AuthorizationAdmin;
