import axios from 'axios';
import { address_server } from '../config';
import { setCookie } from '../utils/fun_cookie';
import { error } from '../utils/handlerError';
import { authStore } from 'store/auth.store';

export async function getListEmail() {
  const { data } = await axios(address_server + 'user/login');

  if (data.success) {
    if (data.result) return data.result;
    return [];
  }
  error('getListEmail', data);
}

export async function login(log, pass) {
  const { data } = await axios(address_server + '/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { login: log, password: pass },
  });

  if (data?.success) {
    setCookie('token', data?.result.token);
    return data;
  }
  error('login', data);

  return false;
}

export async function getRules() {
  const token = authStore.token;
  const res = await (await fetch(address_server + '/user/profile/config?token=' + token)).json();

  if (res.success) return res.result;

  error('auth-rules', res);
  return null;
}

export async function loginAdmin(log, pass) {
  const res = await (
    await fetch(address_server + '/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ login: log, password: pass }),
    })
  ).json();

  if (res.success) {
    if (res.result.status === 999) {
      setCookie('admin-token', res.result.token);
      return res.result.token;
    }
  }
  error('admin-token', res);

  return false;
}

export async function recovery(email) {
  const { data } = await axios(address_server + '/user/recovery', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { login: email },
  });

  return data;
}

export async function verificationRecovery(recoveryToken) {
  const res = await (
    await fetch(address_server + '/user/verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recovery_token: recoveryToken }),
    })
  ).json();
  return res;
}

export async function setNewPasswordRecovery(recoveryToken, pass) {
  const res = await (
    await fetch(address_server + '/user/re-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recovery_token: recoveryToken, new_password: pass }),
    })
  ).json();
  if (res.success) {
    return res.success;
  }
  return res;
}

export async function logout() {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + '/user/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token }),
    })
  ).json();
  if (res.success) {
    setCookie('token', '');
    return true;
  }
  if (res.error_code === 1003) {
    setCookie('token', '');
    return true;
  }
  error('logout', res);
  return false;
}
