import { createContext } from 'react';
import { makeAutoObservable, observable } from 'mobx';
import { getSampleSourceById } from '../services/sampleSource.services';

class AppStore {
  sampleSourceSelected = {
    id: null,
    name: '',
    type: '',
    id_type: null,
    description: '',
    is_active: true,
    updated_at: null,
    samples_count: 0,
    user_fields: [],
  };

  tableLinkFilterFromSourceType = {
    id_type: null,
  };

  constructor() {
    makeAutoObservable(this, {
      sampleSourceSelected: observable,
      tableLinkFilterFromSourceType: observable,
    });
  }

  setSampleSourceId = async (id) => {
    const res = await getSampleSourceById(id);
    for (const key of Object.keys(res.result)) {
      this.sampleSourceSelected[key] = res.result[key];
    }
  };

  getStore = () => {
    return {
      sampleSourceSelected: this.sampleSourceSelected,
    };
  };

  setStore = (data) => {
    if (data) {
      this.sampleSourceSelected = data.sampleSourceSelected;
    }
  };

  clearSampleSourceField = () => {
    this.sampleSourceSelected = {
      id: null,
      name: '',
      type: '',
      id_type: null,
      description: '',
      is_active: true,
      updated_at: null,
      samples_count: 0,
      user_fields: [],
    };
  };

  setSampleSourceField = (key, val) => {
    const copyObj = JSON.parse(JSON.stringify(this.sampleSourceSelected));
    copyObj[key] = val;
    this.sampleSourceSelected = copyObj;
  };

  setTableLinkFilterFromSourceType = (id) => {
    this.tableLinkFilterFromSourceType.id_type = id;
  };
}

export const SampleSourceStore = createContext(new AppStore());
