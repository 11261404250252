import React, { useState, useMemo, useRef, useEffect } from 'react';

import styles from './ProgressParametersStyles.module.css';
import classes from '../../workRightEditManual.module.css';

import DropdownComponent from '../../../../../Widgets/Dropdown/DropdownComponent';
import { SketchPicker } from 'react-color';
import classes2 from '../../../../WorkRightCommon/WorkRight/workRight.module.css';
import arrowSVG from '../../../../../../assets/svg/arrow-01.svg';

const Component = ({ parameters, output, setOutput }) => {
  const types = parameters.type;
  const tables = parameters.base;

  const [visibleListIcon, setVisibleListIcon] = useState(true);
  const [visibleListColor, setVisibleListColor] = useState(false);

  const [type, setType] = useState(
    output ? output.find((item) => item.param === 'type')?.value : null,
  );
  const [color, setColor] = useState(
    output ? output.find((item) => item.param === 'color')?.value : null,
  );
  const [percentVisible, setPercentVisible] = useState(
    output ? output.find((item) => item.param === 'percentVisible')?.value : null,
  );
  const [selectedTable, setSelectedTable] = useState(
    output ? output.find((item) => item.param === 'selectedTable')?.value : null,
  );
  const [selectedField, setSelectedField] = useState(
    output ? output.find((item) => item.param === 'selectedField')?.value : null,
  );
  const [max, setMax] = useState(
    output ? output.find((item) => item.param === 'max')?.value : null,
  );
  const [min, setMin] = useState(
    output ? output.find((item) => item.param === 'min')?.value : null,
  );

  const colorPickerRef = useRef(null);

  useEffect(() => {
    const closeColorPicker = (ev) => {
      if (!colorPickerRef.current?.contains(ev.target)) {
        setVisibleListColor(false);
      }
    };

    window.addEventListener('click', closeColorPicker);
    return () => {
      window.removeEventListener('click', closeColorPicker);
    };
  }, []);

  const availableFields = useMemo(() => {
    return tables.find((table) => selectedTable === table.value)?.fields;
  }, [selectedTable]);

  const refreshOutput = (
    freshSelectedTable,
    freshSelectedField,
    freshType,
    freshColor,
    freshPercentVisible,
    freshMax,
    freshMin,
  ) => {
    setOutput([
      {
        param: 'table',
        value: freshSelectedTable,
      },
      {
        param: 'field',
        value: freshSelectedField,
      },
      {
        param: 'type',
        value: freshType,
      },
      {
        param: 'color',
        value: freshColor,
      },
      {
        param: 'percentVisible',
        value: freshPercentVisible,
      },
      {
        param: 'max',
        value: freshMax,
      },
      {
        param: 'min',
        value: freshMin,
      },
    ]);
  };

  const changeTable = (table) => {
    const tapedTable = tables.find((item) => item.value === table);

    if (selectedTable?.value !== tapedTable.value) {
      setSelectedField(null);
    }
    setSelectedTable(tapedTable.value);
    refreshOutput(tapedTable.value, selectedField, type, color, percentVisible, max, min);
  };

  const changeField = (field) => {
    const selTable = tables.find((item) => item.value === selectedTable);
    const selField = selTable.fields.find((item) => item.value === field);
    setSelectedField(selField.value);

    refreshOutput(selectedTable, selField.value, type, color, percentVisible, max, min);
  };
  const changeType = (val) => {
    refreshOutput(selectedTable, selectedField, val, color, percentVisible, max, min);
    setType(val);
  };

  const changeColor = (val) => {
    refreshOutput(selectedTable, selectedField, type, val, percentVisible, max, min);
    setColor(val);
  };

  const changePercentVisible = (val) => {
    refreshOutput(selectedTable, selectedField, type, color, val, max, min);
    setPercentVisible(val);
  };

  const changeMax = (val) => {
    refreshOutput(selectedTable, selectedField, type, color, percentVisible, val, min);
    setMax(val);
  };

  const changeMin = (val) => {
    refreshOutput(selectedTable, selectedField, type, color, percentVisible, max, val);
    setMin(val);
  };

  const fieldValue = useMemo(() => {
    return selectedField;
  }, [selectedField]);

  const tableValue = useMemo(() => {
    return selectedTable;
  }, [selectedTable]);

  const typeValue = useMemo(() => {
    return type;
  }, [type]);

  return (
    <>
      <div>
        <div className={`${styles.row}`}>
          <span>Тип </span>
          <div className={styles.typeSelect}>
            <DropdownComponent
              keyItem={'value'}
              valueItem={'name'}
              items={types}
              selectedKey={typeValue}
              onChange={(val) => changeType(val)}
            />
          </div>
        </div>
        <div className={styles.row}>
          <span>Прогресс</span>

          <div className={styles.baseSelects}>
            <DropdownComponent
              keyItem={'value'}
              valueItem={'name'}
              items={tables}
              label={'Таблица...'}
              selectedKey={tableValue}
              onChange={(val) => changeTable(val)}
            />
            <DropdownComponent
              keyItem={'value'}
              valueItem={'name'}
              items={availableFields}
              label={'Поле...'}
              disabled={!availableFields?.length}
              selectedKey={fieldValue}
              onChange={(val) => changeField(val)}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <span>Мин</span>
            <input
              className={`${classes.workRightInput} ${styles.sizeInput}`}
              type="number"
              value={min}
              onChange={(ev) => changeMin(ev.target.value)}
            />
          </div>
          <div className={styles.col}>
            <span>Макс</span>
            <input
              className={`${classes.workRightInput} ${styles.sizeInput}`}
              type="number"
              value={max}
              onChange={(ev) => changeMax(ev.target.value)}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.colorField}>
              <span>Цвет</span>
              <div
                className={styles.colorRound}
                onClick={(e) => {
                  e.stopPropagation();
                  setVisibleListIcon(false);
                  setVisibleListColor(!visibleListColor);
                }}
              >
                <div className={styles.infoItemGraphicValue2} style={{ background: color }} />
                <img className={styles.infoItemGraphicValueArrow} src={arrowSVG} />
              </div>
            </div>
            <div className={styles.colorDropdown}>
              {visibleListColor && (
                <div ref={colorPickerRef}>
                  <SketchPicker
                    disableAlpha={true}
                    color={color}
                    onChange={(e) => changeColor(e.hex)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.row} ${styles.rowPercent}`}>
          <span>Отображать процент</span>
          <div>
            <label className={classes2.workRightSwitch}>
              <input
                type={'checkbox'}
                checked={percentVisible}
                onChange={(e) => changePercentVisible(e.target.checked)}
              />
              <span className={classes2.workRightSlider}></span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
