import { action, observable, makeAutoObservable, runInAction, computed, configure } from 'mobx';
import { getCatalogs } from '../services/catalog.services';
import { createContext } from 'react';
import { getListBarcodes } from '../services/list.services';

class Store {
  constructor() {
    makeAutoObservable(this, {
      token: observable,

      searchStructureName: observable,
      setSearchStructureName: action,

      catalog: observable,
      getCatalog: computed,
      setCatalog: action,
    });

    configure({
      enforceActions: 'never',
    });
  }

  searchStructureName = '';
  token = '';
  catalog = [];
  selectedStructure = 0;
  fullCatalog = [];
  barcodes = [];

  getStore = () => {
    return {
      searchStructureName: this.searchStructureName,
      token: this.token,
      catalog: this.catalog,
      selectedStructure: this.selectedStructure,
      //fullCatalog: this.fullCatalog,
      barcodes: this.barcodes,
    };
  };

  setStore = (data) => {
    this.searchStructureName = data.searchStructureName;
    this.token = data.token;
    this.catalog = data.catalog;
    this.selectedStructure = data.selectedStructure;
    //this.fullCatalog = data.fullCatalog;
    this.barcodes = data.barcodes;
  };

  setSearchStructureName = (token, name) => {
    runInAction(() => {
      this.searchStructureName = name;
      this.setCatalog(token, name);
    });
  };

  setSelectedStructure = (id) => {
    this.selectedStructure = id;
  };

  setCatalog = (token, name) => {
    runInAction(async () => {
      this.catalog = await getCatalogs(token, name);
    });
  };
  setFullCatalog = (token) => {
    runInAction(async () => {
      this.fullCatalog = await getCatalogs(token);
    });
  };
  setBarcodes = async (token) => {
    this.barcodes = await getListBarcodes(token);
  };
}

export const StructureStore = createContext(new Store());
