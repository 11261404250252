import Float from './FloatParameters';
import Base from './BaseParameters';
import Date from './DateParameters';
import Image from './ImageParameters';
import SelectCheck from './SelectCheckParameters';
import Select from './SelectParameters';
import SwitchVariants from './SwitchVariantsParameters';
import Progress from './ProgressParameters';
import Finance from './FinanceParameters';
import Location from './LocationParameters';
import File from './FileParameters';
import Range from './RangeParameters';
import Integer from './IntegerParameters';

const Manager = ({ type, parameters, output, setOutput }) => {
  switch (type) {
    case 'integer':
      return <Integer parameters={parameters} output={output} setOutput={setOutput} />;
    case 'float':
      return <Float parameters={parameters} output={output} setOutput={setOutput} />;
    case 'base':
      return <Base parameters={parameters} output={output} setOutput={setOutput} />;
    case 'date':
      return <Date parameters={parameters} output={output} setOutput={setOutput} />;
    case 'period':
      return <Date parameters={parameters} output={output} setOutput={setOutput} />;
    case 'image':
      return <Image parameters={parameters} output={output} setOutput={setOutput} />;
    case 'select_check':
      return <SelectCheck parameters={parameters} output={output} setOutput={setOutput} />;
    case 'select':
      return <Select parameters={parameters} output={output} setOutput={setOutput} />;
    case 'switch_variants':
      return <SwitchVariants parameters={parameters} output={output} setOutput={setOutput} />;
    case 'progress':
      return <Progress parameters={parameters} output={output} setOutput={setOutput} />;
    case 'finance':
      return <Finance parameters={parameters} output={output} setOutput={setOutput} />;
    case 'location':
      return <Location parameters={parameters} output={output} setOutput={setOutput} />;
    case 'file':
      return <File parameters={parameters} output={output} setOutput={setOutput} />;
    case 'range':
      return <Range parameters={parameters} output={output} setOutput={setOutput} />;
    default:
      console.log('such type does not found for the manager:', type);
  }
};

export default Manager;
