import { address_server } from '../config';
import { error } from '../utils/handlerError';
import { editStorage, getStorage } from './storage.services';
import { getListPacks } from './list.services';
import { authStore } from 'store/auth.store';

export async function getFreePacks() {
  const token = authStore.token;
  const res = await (await fetch(address_server + 'storage/free-packs?token=' + token)).json();
  if (res.success) {
    return res.result;
  }
  error('getPucksWithOutStorage', res);
  return [];
}

export async function getPackTypes() {
  const token = authStore.token;
  const res = await (await fetch(address_server + 'pack/packs?token=' + token)).json();
  if (res.success) {
    return res.result;
  }
  error('getPucksWithOutStorage', res);
  return [];
}

export async function deletePackType(id) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);

  return await (
    await fetch(address_server + 'pack/delete', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function postSavePack(puckData) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('name', puckData.name);
  formData.append('column', Number.parseInt(puckData['column']));
  formData.append('lines', Number.parseInt(puckData['lines']));
  formData.append(
    'lines_name',
    JSON.stringify(
      puckData['lines_name']
        .replace(/ /g, '')
        .split(',')
        .filter((i) => i !== ''),
    ),
  );
  formData.append(
    'column_name',
    JSON.stringify(
      puckData['column_name']
        .replace(/ /g, '')
        .split(',')
        .filter((i) => i !== ''),
    ),
  );

  if (puckData.id) {
    formData.append('id', puckData.id);
  }

  return await (
    await fetch(address_server + 'pack', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function setPackToStorage(data) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('storage', data.storageId);
  formData.append('type', data.type);
  formData.append('name', data.name);
  formData.append('barcodes', JSON.stringify(data.barcodes));
  if (data.id) formData.append('id', data.id);

  return await (
    await fetch(address_server + 'storage/pack/pack', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function editPackToStorage(data) {
  const storage = await getStorage(data.storageId);

  const packs = [];
  for (let i = 0; i < storage.packs.length; i++) {
    if (storage.packs[i] !== null) packs.push(storage.packs[i]);
  }
  const loadedPacks = await getListPacks(data.storageId);

  storage.packs = [...loadedPacks, ...packs];

  storage.packs.forEach((i) => {
    if (i.id === data.id) {
      i.name = data.name;
    }
  });

  return await editStorage(data.storageId, storage);
}

export async function editPack(data) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('type', data.type);
  formData.append('name', data.name);
  if (data.barcodes.length !== 0) formData.append('barcodes', JSON.stringify(data.barcodes));
  formData.append('id', data.id);

  return await (
    await fetch(address_server + 'storage/pack/pack', {
      method: 'POST',
      body: formData,
    })
  ).json();
}

export async function getBarcodes(id) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'storage/pack?token=' + token + '&id=' + id)
  ).json();
  if (res.success) {
    return res.result;
  }
  error('getPucksWithOutStorage', res);
  return [];
}

export async function deletePack(id) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);

  return await (
    await fetch(address_server + 'storage/pack/delete', {
      method: 'POST',
      body: formData,
    })
  ).json();
}
