import styles from './tableRowItem.module.css';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

const TableRowItem = observer(({ item }) => {
  const type = item.type;
  const value = item.value;

  return (
    <td className={styles.workRightTableItem}>
      {(type === 'string' || type === 'text' || type === 'number') && (
        <div className={styles.workRightTableItemString}> {value?.text ?? value} </div>
      )}
      {(type === 'date' || type === 'time' || type === 'period') && (
        <div>{moment(value).format('YYYY-MM-DD')}</div>
      )}
    </td>
  );
});

export default TableRowItem;
