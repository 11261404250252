import React from 'react';
import styles from './css/SwitchWidget.module.css';

const SwitchWidget = ({ selected, onChange, item, disabled = false }) => {
  return (
    <div className={styles.container}>
      <label className={styles.switch}>
        <input
          type={'checkbox'}
          checked={selected?.value ?? selected}
          disabled={disabled}
          onChange={(e) => {
            onChange({ value: e.target.checked });
          }}
        />
        <span className={styles.slider + ' ' + (disabled ? styles.disabled : ' ')}></span>
      </label>
    </div>
  );
};

export default SwitchWidget;
