import { createBrowserRouter, RouterProvider as Routes } from 'react-router-dom';
import SuperAdminPage from 'pages/SuperAdminPage';
import Layout from 'widgets/Layout';
import PrivateRoute from 'widgets/PrivateRoute';
import LoginPage from 'pages/LoginPage';
import AdminLoginPage from 'pages/AdminLoginPage';
import ForgetPassword from 'pages/ForgetPasswordPage';
import ResetPassword from 'pages/ResetPasswordPage';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <>ERROR</>,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute pathBack={'/login'}>
            <Layout />
          </PrivateRoute>
        ),
      },
      {
        path: 'super-admin',
        element: (
          <PrivateRoute pathBack={'/admin-login'}>
            <SuperAdminPage />
          </PrivateRoute>
        ),
        errorElement: <>ERROR</>,
      },
      {
        path: 'login',
        element: <LoginPage />,
        errorElement: <>ERROR</>,
      },
      {
        path: 'recovery',
        element: <ForgetPassword />,
        errorElement: <>ERROR</>,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
        errorElement: <>ERROR</>,
      },
      {
        path: 'admin-login',
        element: <AdminLoginPage />,
        errorElement: <>ERROR</>,
      },
    ],
  },
]);

export const RouterProvider = () => {
  return <Routes router={router} />;
};

export default RouterProvider;
