import React, { useContext, useEffect, useState } from 'react';
import WorkRightHeader from '../../WorkRightCommon/WorkRightHeader/workRightHeader';
import classes from '../../WorkRightManualDir/WorkRightManual/workRightManual.module.css';
import WorkRightSelectPage from '../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage';
import WorkRightTable from '../../WorkRightCommon/WorkRightTable/workRightTable';
import { getTableSamples, moveSample } from '../../../../services/sample.services';
import WorkRightStorageViewMenu from '../../WorkRightStorageDir/WorkRightStorageViewMenu/workRightStorageViewMenu';
import { observer } from 'mobx-react-lite';
import { StorageStore } from '../../../../store/StorageStore';
import ModalMoveComponent from '../../../Widgets/Modal/ModalMoveComponent';
import { getStorages } from '../../../../services/storage.services';
import { Store } from '../../../../store/Store';
import AddSampleModal from '../../WorkRightStorageDir/WorkRightStorageAddMenu/addSampleModal';
import { SamplesStore } from 'store/SamplesStore';

const WorkRightSamples = observer(({ setWidget, sizeX, mode, type = null }) => {
  const storageStore = useContext(StorageStore);
  const { loadTree, selectedSample } = React.useContext(Store);
  const samplesStore = useContext(SamplesStore);

  const [countInPage, setCountInPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageAll, setPageAll] = useState(10);
  const [countAll, setCountAll] = useState(0);
  const [filter, setFilter] = useState('');
  const [sortField, setSortField] = useState({ type: '', field: '' });
  const [structHeader, setStructHeader] = useState({
    name: true,
    search: true,
    add: true,
    filter: true,
    print: false,
    wrap: true,
  });
  const [isSearchBarcode, setIsSearchBarcode] = useState(false);

  const [rows, setRows] = useState([]);
  const [struct, setStruct] = useState([]);
  const [selectItem, setSelectItem] = useState({ id: 0, fields: [], group: mode.type.item.id });
  const [rowsButton, setRowsButton] = useState({
    print: true,
    copy: true,
    edit: true,
    delete: true,
  });
  const [treeLocation, setTreeLocation] = useState([]);
  const [isLoadAfterError, setIsLoadAfterError] = useState(true);
  const [filterFieldsSave, setFilterFieldsSave] = useState();
  const [visiblePrintMenu, setVisiblePrintMenu] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const rules = mode.rules ?? {};

  React.useEffect(() => {
    setStructHeader({
      name: true,
      search: true,
      add: rules?.sample?.upsert,
      filter: true,
      print: true,
      wrap: true,
    });
    setRowsButton({
      print: true,
      copy: rules?.sample?.move,
      edit: rules?.sample?.upsert,
      delete: rules?.sample?.delete,
    });
  }, [mode]);

  useEffect(() => {
    const init = async () => {
      const loc = await getStorages(false, '');
      loc.push({
        id: undefined,
        type: 1,
        name: 'Глобальное хранилище',
        icon: 'icons/location-01.svg',
        color: '#ad1d1d',
        favorite: false,
        level: '0',
        parent: 0,
        nodes: [],
      });
      await loadTree(false, '');
      setTreeLocation(loc);
    };
    init();
  }, []);

  useEffect(() => {
    if (isLoadAfterError) getSamplesT();
  }, [mode, filter, page, sortField, selectedSample, filterFieldsSave]);

  const getSamplesT = async (columns = null, filterFields = null) => {
    const sampleSourceFilter = samplesStore.tableLinkFilterFromSampleSource.id_source;
    if (sampleSourceFilter && !isFirstLoad) {
      samplesStore.setTableLinkFilterFromSampleSource(null);
    }

    if (isFirstLoad) setIsFirstLoad(false);

    let searchWithoutFilter = false;
    if (!mode.sharedFilter) {
      setFilterFieldsSave(null);
      searchWithoutFilter = true;
    } else if (filterFields != null) setFilterFieldsSave(filterFields);

    const result = await getTableSamples({
      page: page,
      filterFields: sampleSourceFilter ?
        [{ field: "id_source", operator: "=", value: sampleSourceFilter, type: "integer" }] :
        (filterFields != null ? filterFields : !searchWithoutFilter ? filterFieldsSave : null),
      filter: filter,
      sortField: sortField.type != '' ? JSON.stringify(sortField) : '',
      isSearchBarcode: isSearchBarcode,
      columns: columns,
      mode: type,
    });
    const res = result.result;
    if (res) {
      const filedForSelect = {
        field: 'selectToPrint',
        name: 'selectToPrint',
        showColumn: true,
        sort: false,
        type: 'selectToPrint',
      };
      setStruct([filedForSelect, ...res.table]);
      setCountAll(res.pagination.total);

      let newArray = JSON.parse(JSON.stringify(res.samples));

      res?.samples?.map((val, index) => {
        for (let key of Object.keys(val.value)) {
          try {
            newArray[index].value[key] = JSON.parse(val.value[key]);
          } catch {
            newArray[index].value[key] = val.value[key];
          }
        }
      });
      setRows(newArray);
      setPageAll(res.pagination.pages);
    } else {
      setWidget({
        status: 'Error',
        text: res.error_code,
        fun: async () => {
          setIsLoadAfterError(false);
        },
      });
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <WorkRightHeader
        setVisibleAdd={mode.setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        setVisiblePrintMenu={setVisiblePrintMenu}
        filter={filter}
        setIsSearchBarcode={setIsSearchBarcode}
        isSearchBarcode={isSearchBarcode}
        setFilter={setFilter}
      />

      <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>

      <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
      <WorkRightTable
        mode={mode}
        struct={struct}
        rows={rows}
        visiblePrintMenu={visiblePrintMenu}
        setVisiblePrintMenu={setVisiblePrintMenu}
        setRows={setRows}
        rowsButton={rowsButton}
        setVisibleAdd={mode.setVisibleAdd}
        setSelectItem={setSelectItem}
        setWidget={setWidget}
        sortField={sortField}
        showColumnSelector={true}
        setSortField={setSortField}
        fromOther={true}
        page={page}
        loadTSamples={getSamplesT}
      />

      {mode.visibleView && rules?.sample?.get_info && (
        <WorkRightStorageViewMenu
          mode={mode}
          sizeX={sizeX}
          setWidget={setWidget}
          visibleAdd={mode.viewMode !== 0}
        />
      )}

      {mode.visibleAdd && rules?.sample?.upsert && (
        <AddSampleModal
          mode={mode}
          visibleAdd={mode.visibleAdd}
          setVisibleAdd={mode.setVisibleAdd}
          setWidget={setWidget}
        />
      )}

      {rules?.sample?.move && (
        <ModalMoveComponent
          openModal={mode.copyMode}
          mode={mode.copyType}
          onClose={() => {
            storageStore.clearSelectedSample();
            mode.setCopyMode(false);
          }}
          action={async (data) => {
            let res = await moveSample(storageStore.selectedStorageId, data);

            if (!res.success) {
              setWidget({
                status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                text:
                  parseInt(res.error_code) === 2016
                    ? 'Данная ячейка уже занята. Поместите образец в другое место хранения'
                    : res.error_code,
                fun: async () => { },
              });
            } else {
              storageStore.clearSelectedSample();
              mode.setCopyMode(false);
              await getSamplesT();
            }
          }}
        />
      )}
    </div>
  );
});

export default WorkRightSamples;
