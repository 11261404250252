import React from 'react';
import '../../app.css';
import '../../bootstrap.css';
import { MainStore } from './store/MainStore';
import LicensePage from './LicensePage/LicensePage';
import AuthorizationAdmin from '../../components/Authorization/AuthorizationAdmin';
import { observer } from 'mobx-react-lite';
import { loginAdmin } from '../../services/auth.services';

const SuperAdminPage = observer(() => {
  const { token, getToken, setToke, removeToken } = React.useContext(MainStore);

  React.useEffect(() => {
    getToken();
  }, []);

  const setLogin = async (login, password) => {
    const res = await loginAdmin(login, password);
    if (res && res !== '') {
      setToke(res);
    } else {
      removeToken();
    }
  };

  return <>{token === '' ? <AuthorizationAdmin onLogin={setLogin} /> : <LicensePage />}</>;
});

export default SuperAdminPage;
