import React from 'react';
import classes from './autoComplete.module.css';
import wrModule from '../../WorkRightCommon/WorkRight/workRight.module.css';
import { getListTypeStorages } from '../../../../services/list.services';
import { MultiItemList } from '../../../Widgets/MultiInputList';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import { getPackTypes } from '../../../../services/pucks.services';

export const AutoComplete = ({
  mode,
  setWidget,
  storageTypes,
  setStorageTypes,
  selectedPackCount,
  setSelectedPackCount,
  selectedPackType,
  setSelectedPackType,
}) => {
  const [addHierarchy, setAddHierarchy] = React.useState(false);
  const [addPacks, setAddPacks] = React.useState(false);

  const [storageTypesSource, setStorageTypesSource] = React.useState([]); //{type: 1, count: 1}

  const [packSource, setPackSource] = React.useState([]);

  React.useEffect(() => {
    const loadStorageTypes = async () => {
      let res = await getListTypeStorages();
      setStorageTypesSource(res);

      let packs = await getPackTypes();
      setPackSource(packs);
    };

    loadStorageTypes();
  }, []);

  const Item = (value, index) => {
    return (
      <div key={index} className={classes.itemRow}>
        <DropdownComponent
          items={storageTypesSource.filter((val) => val.id != 5)}
          label={'Выберите тип локации'}
          selectedKey={value.type}
          onChange={(id) => changeItemByIndex('type_storage', id, index)}
          arrowColor={'#086C22'}
        />
        <input
          className={classes.infoItemValue}
          value={value.count}
          onChange={(e) => changeItemByIndex('count', e.target.value, index)}
          type="number"
        />

        <div className={classes.buttonClear} onClick={() => deleteItem(index)}>
          <div className={'close_icon'}></div>
        </div>
      </div>
    );
  };

  const changeItemByIndex = (key, value, index) => {
    let res = JSON.parse(JSON.stringify(storageTypes));
    let item = res[index];

    if (item) {
      item[key] = value;
    }

    setStorageTypes(res);
  };

  const addItem = () => {
    let res = JSON.parse(JSON.stringify(storageTypes));
    res.push({ type_storage: 0, count: 1 });
    setStorageTypes(res);
  };

  const deleteItem = (index) => {
    let res = JSON.parse(JSON.stringify(storageTypes));
    res.splice(index, 1);
    setStorageTypes(res);
  };

  return (
    <div className={classes.autoCompleteContainer}>
      <div className={classes.row}>
        <label
          className={wrModule.workRightSwitch + ' ' + classes.workRightAddMenuUserBlock4BlockInput}
        >
          <input
            type={'checkbox'}
            checked={addHierarchy}
            onChange={(e) => setAddHierarchy((prevState) => !prevState)}
          />
          <span className={wrModule.workRightSlider}></span>
        </label>

        <span className={classes.title}> Добавить иерархию подразделений</span>
      </div>

      {addHierarchy && (
        <div className={classes.addContainer}>
          <MultiItemList items={storageTypes} onChange={addItem} Item={Item} />
        </div>
      )}

      <div className={classes.row}>
        <label
          className={wrModule.workRightSwitch + ' ' + classes.workRightAddMenuUserBlock4BlockInput}
        >
          <input
            type={'checkbox'}
            checked={addPacks}
            onChange={(e) => setAddPacks((prevState) => !prevState)}
          />
          <span className={wrModule.workRightSlider}></span>
        </label>

        <span className={classes.title}> Добавить коробки в последний уровнь подразделений</span>
      </div>

      {addPacks && (
        <div className={classes.addContainer}>
          <div className={classes.packRow}>
            <span>Тип коробки/штатива: </span>
            <DropdownComponent
              items={packSource}
              label={'Выберите тип штатива'}
              selectedKey={selectedPackType}
              onChange={(id) => setSelectedPackType(id)}
              arrowColor={'#086C22'}
            />
            <input
              className={classes.infoItemValue}
              value={selectedPackCount}
              onChange={(e) => setSelectedPackCount(e.target.value)}
              type="number"
            />
          </div>
        </div>
      )}
    </div>
  );
};
