import { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import defaultStyle from '../ModalDefaultStyle.module.css';
import style from './ModalDefreeze.module.css';
import { modeContext } from '../../Contexts/contexts';
import { getSample, intoSamples, outSamples } from '../../../services/sample.services';
import { Store } from '../../../store/Store';
import Icon from '../../../helpers/components/Icon';
import close from '../../../assets/svg/close-01.svg';
import TextareaAutosize from 'react-textarea-autosize';
import { observer } from 'mobx-react-lite';

const ModalDefreeze = observer(
  ({ sampleIds, mode, selectedSampleId = null, defreeze, setDefreeze }) => {
    const { widget } = useContext(modeContext);
    const { loadSampleById, updateSelectedTreeNodePacks } = useContext(Store);

    const [description, setDescription] = useState('');
    const [volume, setVolume] = useState();
    const [sample, setSample] = useState();

    useEffect(() => {
      if (selectedSampleId) {
        loadSample(selectedSampleId);
      } else if (sampleIds.length == 1) loadSample(sampleIds[0]);
    }, []);

    useEffect(() => {
      if (sample) setVolume(sample?.volume_all?.amount);
    }, [sample]);

    const loadSample = async (sampleId) => {
      const res = await getSample(sampleId, 'view');
      setSample(res);
    };

    const outSample = async () => {
      const res = await outSamples(sampleIds, description);
      if (selectedSampleId && res.success) {
        await loadSampleById(selectedSampleId);
        closeModal();
      }
      if (res.success) {
        await updateSelectedTreeNodePacks();
        closeModal();
      } else
        widget.setWidget({
          status: 'Error',
          text: res.error_code,
          fun: () => {},
        });
    };

    const intoSample = async () => {
      const res = await intoSamples(sampleIds, description, volume);
      if (selectedSampleId && res.success) {
        await loadSampleById(selectedSampleId);
        closeModal();
      }
      if (res.success) {
        await updateSelectedTreeNodePacks();
        closeModal();
      } else
        widget.setWidget({
          status: 'Error',
          text: res.error_code,
          fun: () => {},
        });
    };

    const hadleOutOrInto = () => {
      switch (defreeze.type) {
        case 'out':
          if (sample?.defreeze == 1 || sample?.defreeze == 0)
            widget.setWidget({
              status: 'Sure',
              text: 'Последняя разморозка для данного образца. Подтвердить?',
              fun: () => {
                outSample();
              },
            });
          else outSample();
          break;
        case 'into':
          if (sample?.defreeze == 1 || sample?.defreeze == 0) {
            widget.setWidget({
              status: 'Alarm',
              text: 'Данный образец помещен в архив!',
              fun: () => {},
            });
            intoSample();
            mode.setVisibleView(false);
          } else intoSample();
          break;
        default:
          console.error('Something went wrong!');
          break;
      }
    };

    const closeModal = () => {
      setDefreeze({ show: false, type: null });
    };

    const modal = (
      <div className={defaultStyle.modalContainer}>
        <div className={defaultStyle.modal + ' ' + style.modal}>
          <div className={defaultStyle.header}>
            <div className={defaultStyle.closeButton} onClick={closeModal}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={defaultStyle.body}>
            <div className={defaultStyle.title}>
              {defreeze.type == 'out'
                ? `Извлечь образцы в количестве: ${sampleIds.length} ?`
                : `Вернуть образцы в количестве: ${sampleIds.length} ?`}
            </div>
            {(selectedSampleId || sampleIds.length == 1) && defreeze.type == 'into' && (
              <div className={defaultStyle.bodyRow}>
                <div className={defaultStyle.lable}>
                  Количество образца ({sample?.volume_all?.unit_name}):
                </div>
                <input
                  className={defaultStyle.infoItemValue}
                  value={volume}
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    if (e.target.value >= 0) setVolume(e.target.value);
                    else setVolume(0);
                  }}
                />
                <div className={style.unitName}>{}</div>
              </div>
            )}
            <div>
              <TextareaAutosize
                maxRows={10}
                placeholder={'Комментарий...'}
                className={defaultStyle.input}
                style={{ cursor: 'default' }}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
          </div>
          <div className={defaultStyle.footer}>
            <button className={`button_default save_style`} onClick={hadleOutOrInto}>
              Да
            </button>
            <button className={`button_default cancel_style`} onClick={closeModal}>
              Отмена
            </button>
          </div>
        </div>
      </div>
    );

    const modalEmpty = (
      <div className={defaultStyle.modalContainer}>
        <div className={defaultStyle.modal + ' ' + style.modal}>
          <div className={defaultStyle.header}>
            <div className={defaultStyle.closeButton} onClick={closeModal}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={defaultStyle.body}>
            <div className={defaultStyle.title}>Образцы не выбраны</div>
          </div>
          <div className={defaultStyle.footer}>
            <button
              className={`button_default cancel_style`}
              style={{ marginLeft: '0' }}
              onClick={closeModal}
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    );

    return ReactDOM.createPortal(sampleIds.length > 0 ? modal : modalEmpty, document.body);
  },
);

export default ModalDefreeze;
