import React, { useEffect, useState } from 'react';
import classes from '../../WorkRightCommon/WorkRight/workRight.module.css';
import classes2 from '../../WorkRightManualDir/WorkRightAddMenu/workRightAddMenu.module.css';
import classes3 from '../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css';
import classes4 from '../../WorkRightUsersDir/WorkRightUsersAddGroup/workRightUsersAddGroup.module.css';
import { newGroupReports } from '../../../../services/report.services';
import error from '../../../../assets/svg/attention-01.svg';

const WorkRightReportsAddGroup = ({ mode, sizeX, setWidget }) => {
  const [name, setName] = useState(mode.type.item?.name);
  const [errorN, setErrorN] = useState(false);
  const [errorN2, setErrorN2] = useState(false);

  useEffect(() => {
    setName(mode.type.item.name);
  }, [mode.type.item]);

  async function newGroupStart() {
    if (name === '') {
      setErrorN(true);
    } else {
      if (mode.type.item.id === 0) {
        const res = await newGroupReports(mode.type.item.id, name);
        if (res.success) {
          setWidget({
            status: 'Ok',
            text: 'Группа создана успешно!',
            fun: async () => {
              mode.setType({ mode: 'view', item: '' });
            },
          });
        } else {
          if (res.error_code === 2015) {
            setErrorN2(true);
          } else {
            setWidget({ status: 'Error', text: res.error_code, fun: async () => {} });
          }
        }
      } else {
        setWidget({
          status: 'Attention',
          text: 'Наименование группы отчетов будет изменено.',
          fun: async () => {
            const res = await newGroupReports(mode.type.item.id, name);
            if (res.success) {
              mode.setType({ mode: 'view', item: { id: mode.type.item.id, name: name } });
            } else {
              if (res.error_code === 2002) {
                setErrorN2(true);
              } else {
                setWidget({ status: 'Error', text: res.error_code, fun: async () => {} });
              }
            }
          },
        });
      }
    }
  }
  return (
    <div
      className={classes2.workRightAddMenu}
      style={{ width: 'calc(100% - 3px - ' + sizeX + 'px' }}
    >
      <div className={classes2.workRightAddMenu_block}>
        <div className={classes3.workRightUsersAddMenuBlockInput}>
          <div className={classes2.workRightAddMenu_table_left}>Наименование</div>
          <input
            className={
              classes2.workRightAddMenuInput + ' ' + ((errorN || errorN2) && classes2.InputError)
            }
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrorN(false);
              setErrorN2(false);
            }}
          />
          {errorN && (
            <div className={classes.errorBlock}>
              <img src={error} alt={'error'} />
              <div className={classes.LoginErrorText2}>Название группы не может быть пустым</div>
            </div>
          )}
          {errorN2 && (
            <div className={classes.errorBlock}>
              <img src={error} alt={'error'} />
              <div className={classes.LoginErrorText2}>Название группы не может повторяться</div>
            </div>
          )}
        </div>
        <div className={classes3.workRightAddMenu_blockButton_Users}>
          <div className={classes.workRightAddMenu_save} onClick={() => newGroupStart()}>
            Сохранить
          </div>
          <div
            className={
              classes.workRightAddMenu_cancel + ' ' + classes4.workRightAddMenu_cancel_Users
            }
            onClick={() => {
              mode.setType({ mode: 'view', item: mode.type.item });
            }}
          >
            Отмена
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkRightReportsAddGroup;
