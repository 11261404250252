import React, { useRef } from 'react';
import classes from './ModalSupport.module.css';
import Icon from '../../../helpers/components/Icon';
import styles from '../../Widgets/Modal/modal.module.css';
import close from '../../../assets/svg/close-01.svg';
import DropdownComponent from '../../Widgets/Dropdown/DropdownComponent';
import WidgetFileLoader from '../../Widgets/WidgetFileLoader';
import { COLORS } from '../../../config';
import error from '../../../assets/svg/attention-01.svg';

const themes = [
  { id: 0, name: 'Ошибка' },
  { id: 1, name: 'Вопрос' },
  { id: 3, name: 'Предложение' },
];

const ModalSupport = ({ openModal, onClose, onSave }) => {
  const selfRef = useRef(null);
  const modalBGRef = useRef(null);

  const [data, setData] = React.useState({
    name: '',
    email: '',
    files: [],
    theme: 'Ошибка',
    message: '',
  });
  const [nameError, setNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [emailError2, setEmailError2] = React.useState(false);
  const [onClickSand, setOnClickSand] = React.useState(false);
  const [filesError, setFilesError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);

  const closeModalTappedOnBg = (ev) => {
    if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
      onClose();
    }
  };

  const changeField = (value, fieldKey) => {
    let obj = JSON.parse(JSON.stringify(data));
    obj.files = [];
    if (fieldKey != 'files')
      data.files.map((val, i) => {
        obj['files'].push(val);
      });
    obj[fieldKey] = value;
    setData(obj);
  };

  const onSaveClick = () => {
    setOnClickSand(true);

    if (
      data.name !== '' &&
      data.email !== '' &&
      data.message !== '' &&
      data.files.length !== 0 &&
      !emailError2
    )
      onSave(data);

    if (data.name === '') {
      setNameError(true);
    }

    if (data.email === '') {
      setEmailError(true);
    }

    if (data.message === '') {
      setMessageError(true);
    }

    if (data.files.length === 0) {
      setFilesError(true);
    }
  };

  const modal = (
    <>
      <div ref={modalBGRef} onClick={(ev) => closeModalTappedOnBg(ev)} className={styles.modalBG}>
        <div className={styles.model_copy} ref={selfRef}>
          <div className={styles.header}>
            <div className={styles.closeButton} onClick={() => onClose()}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={styles.body} style={{ margin: '10px 20px' }}>
            <div className={styles.title}>Техническая поддержка</div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Как к вам обращаться</p>
              <div className={classes.infoItemContainer}>
                <input
                  className={classes.infoItemValue}
                  type="text"
                  style={{ width: '100%' }}
                  value={data['name']}
                  onChange={(e) => {
                    changeField(e.target.value, 'name');
                    setNameError(false);
                  }}
                />

                {nameError && (
                  <div className={classes.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Ваш email</p>
              <div className={classes.infoItemContainer} style={{ paddingLeft: '3px' }}>
                <div>
                  <input
                    className={classes.infoItemValue}
                    type="email"
                    value={data['email']}
                    onChange={(e) => {
                      changeField(e.target.value, 'email');
                      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                      const isValidEmail = emailRegex.test(e.target.value);
                      setEmailError2(!isValidEmail);
                      setEmailError(false);
                    }}
                  />
                </div>
                {emailError && data['email'] == '' && (
                  <div className={classes.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
                {emailError2 && data['email'] != '' && onClickSand && (
                  <div className={classes.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.dataError}>Некорректный email</div>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Тема обращения</p>
              <div style={{ width: '74%', marginTop: '10px', marginLeft: '2px' }}>
                <DropdownComponent
                  key={'errors_1'}
                  keyItem={'name'}
                  valueItem={'name'}
                  arrowColor={COLORS.color_hover}
                  items={themes}
                  selectedKey={data['theme']}
                  onChange={(val) => {
                    changeField(val, 'theme');
                  }}
                />
              </div>
            </div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Сообщение</p>
              <div className={classes.infoItemContainer} style={{ paddingLeft: '3px' }}>
                <div>
                  <input
                    className={classes.infoItemValue}
                    type="text"
                    value={data['message']}
                    onChange={(e) => {
                      setMessageError(false);
                      changeField(e.target.value, 'message');
                    }}
                  />
                </div>
                {messageError && (
                  <div className={classes.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.infoItem_column}>
              <div className={classes.infoItemContainer}>
                <div>
                  <WidgetFileLoader
                    onLoadFile={(files) => {
                      setFilesError(false);
                      changeField(files, 'files');
                    }}
                    listFile={data['files']}
                    title="Перетащите файл отчета сюда или выберите файл"
                    isError={false}
                  />
                </div>

                {filesError && (
                  <div className={classes.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <button className={`button_default save_style`} onClick={onSaveClick}>
              Отправить
            </button>
            <button className={`button_default cancel_style`} onClick={() => onClose()}>
              Отмена
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return openModal ? modal : <></>;
};

export default ModalSupport;
