import React, { useEffect, useState } from 'react';
import styles from './workRightStorageAddGroup.module.css';
import {
  editStorage,
  getStorages,
  saveAutoCompleteByStorageId,
} from '../../../../services/storage.services';
import { getListBarcodes, getListIcon } from '../../../../services/list.services';
import classes from '../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css';
import error from '../../../../assets/svg/attention-01.svg';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import { observer } from 'mobx-react-lite';
import ColorPicker from '../../../Widgets/ColorPicker';
import { Store } from '../../../../store/Store';
import { AutoComplete } from './autoComplete';
import BarcodesMultiList from '../../../Widgets/BarcodesMultiList';

const WorkRightStorageAddGroup = observer(({ mode, sizeX, setWidget }) => {
  const { selectedStorageType, setSelectedStorageField, clearSelectedStorageType, loadTree } =
    React.useContext(Store);

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState(false);
  const [nameError3, setNameError3] = useState(false);
  const [visibleListColor, setVisibleListColor] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [visibleListIcon, setVisibleListIcon] = useState(false);
  const [visibleTreeLocation, setVisibleTreeLocation] = useState(false);
  const [listIcon, setListIcon] = useState([]);

  const [storageTypes, setStorageTypes] = React.useState([]);
  const [selectedPackType, setSelectedPackType] = React.useState(0);
  const [selectedPackCount, setSelectedPackCount] = React.useState(0);

  const [treeLocation, setTreeLocation] = React.useState([]);

  React.useEffect(() => {
    if (selectedStorageType === null) mode.setType({ mode: 'view', item: mode.type.item });
  }, [selectedStorageType]);

  useEffect(() => {
    const requests = async () => {
      const listIcons = await getListIcon();
      setListIcon(listIcons);
      const loc = await getStorages(false, '');
      loc.push({
        id: undefined,
        type: 1,
        name: 'Глобальное хранилище',
        icon: 'icons/location-01.svg',
        color: '#ad1d1d',
        favorite: false,
        level: '0',
        parent: 0,
        nodes: [],
      });
      setTreeLocation(loc);
    };
    requests();
  }, []);

  const getIconId = (url) => {
    const icon = listIcon.find((icon) => icon.file === url);
    if (icon) return icon.id;
    return 1;
  };

  const startSave = async () => {
    if (selectedStorageType?.name === '') {
      setNameError(true);
    } else {
      if (!selectedStorageType.id) {
        const res = await editStorage(0, {
          name: selectedStorageType.name,
          type: selectedStorageType.type,
          location:
            parseInt(selectedStorageType.type) === 1 ? 0 : selectedStorageType.location.parent,
          barcodes: selectedStorageType.barcodes ?? [],
          packs: selectedStorageType.packs,
          icon: getIconId(selectedStorageType.icon),
          color: selectedStorageType.color,
          copy: selectedStorageType.copy ?? 0,
          comments: selectedStorageType.comment,
        });
        if (res.success) {
          setWidget({
            status: 'Ok',
            text: 'Хранилище успешно добавлено!',
            fun: async () => {
              clearSelectedStorageType();
              await loadTree(false, '');
              mode.setType({ mode: '', item: {} });
              mode.setVisibleAdd(false);
            },
          });
          await saveAutoComplete(res.result);
        } else {
          if (res.error_code === 2014) {
            setLocationError(true);
          } else if (res.error_code === 2015) {
            setNameError2(true);
          } else if (res.error_code === 2029) {
            setNameError3(true);
          } else {
            setWidget({
              status: 'Error',
              text: res.error_code,
              fun: async () => {},
            });
          }
        }
      } else {
        setWidget({
          status: 'Attention',
          text: 'Данные хранилища будут изменены.',
          fun: async () => {
            const newBarcode = JSON.parse(JSON.stringify(selectedStorageType.barcodes));
            const res = await editStorage(selectedStorageType.id, {
              name: selectedStorageType.name,
              type: selectedStorageType.type,
              location:
                parseInt(selectedStorageType.type) === 1 ? 0 : selectedStorageType.location.parent,
              barcodes: newBarcode.filter((val) => !!val.id && !!val.value) ?? [],
              packs: selectedStorageType.packs,
              icon: getIconId(selectedStorageType.icon),
              color: selectedStorageType.color,
              copy: selectedStorageType.copy ?? 0,
              comments: selectedStorageType.comment,
            });

            if (res.success) {
              setWidget({
                status: 'Ok',
                text: 'Хранилище успешно обновлено!',
                fun: async () => {
                  clearSelectedStorageType();
                  await loadTree(false, '');
                  mode.setType({ mode: '', item: {} });
                  mode.setVisibleAdd(false);
                },
              });
              await saveAutoComplete(res.result);
            } else {
              if (res.error_code === 2014) {
                setLocationError(true);
              } else if (res.error_code === 2029) {
                setNameError3(true);
              } else {
                setWidget({
                  status: 'Error',
                  text: res.error_code,
                  fun: async () => {},
                });
              }
            }
          },
        });
      }
    }
  };

  const dataTree = {
    items: treeLocation,
    type: selectedStorageType?.parentType ?? { id: 0, name: '' },
    setLocation: (val) => {
      setSelectedStorageField('location', val);
    },
    setVisibleTreeLocation: setVisibleTreeLocation,
    initialItem: selectedStorageType?.location.path,
  };

  const getIconFile = (url) => {
    const icon = listIcon.find((icon) => icon.file === url);
    if (icon) return icon;
    return {};
  };

  const dataIcons = {
    items: listIcon,
    selectIcon: getIconFile(selectedStorageType?.icon),
    setSelectIcon: (val) => {
      setSelectedStorageField('icon', val.file);
    },
    selectIconFile: selectedStorageType?.icon,
  };

  const saveAutoComplete = async (storageId) => {
    const res = await saveAutoCompleteByStorageId(
      storageId,
      storageTypes,
      selectedPackCount,
      selectedPackType,
    );
    if (res.success) {
      await loadTree(false, '');
    } else {
      setWidget({
        status: 'Error',
        text: 'Произошла ошибка при сохранении автозаполнения хранилищя',
        fun: async () => {},
      });
    }
  };

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        setVisibleListColor(false);
        setVisibleListIcon(false);
        setVisibleTreeLocation(false);
      }}
    >
      <div className={styles.info}>
        <label className={styles.infoItem}>
          <p className={styles.infoItemName}>Тип</p>
          <input
            className={styles.infoItemValue}
            type="text"
            placeholder=""
            readOnly={true}
            value={selectedStorageType?.parentType.name ?? ''}
          />
        </label>
        <div className={styles.storageAddress}>
          <p className={styles.infoItemName}>Расположение</p>
          <DropdownComponent
            items={dataTree?.items}
            dataCustom={dataTree}
            selectedKey={
              selectedStorageType?.location?.parent === 0
                ? '/'
                : selectedStorageType?.location?.parent
            }
            keyItem={'parent'}
            keyValue={'path'}
            label={
              selectedStorageType?.location?.parent === 0 ? '/' : selectedStorageType?.location.path
            }
            disabled={false}
            arrowColor={'#086C22'}
            customComponent={'tree'}
          />
        </div>

        <label className={styles.infoItem}>
          <p className={styles.infoItemName}>Наименование</p>
          <input
            className={styles.infoItemValue + ' ' + (nameError && styles.errorInput)}
            type="text"
            value={selectedStorageType?.name ?? ''}
            onChange={(e) => {
              setSelectedStorageField('name', e.target.value);
              setNameError(false);
              setNameError2(false);
            }}
          />
          {nameError && (
            <div className={styles.dataErrorBlock}>
              <img src={error} alt={'error'} />
              <div className={classes.dataError}>Поле обязательно для заполнения</div>
            </div>
          )}
          {nameError2 && (
            <div className={styles.dataErrorBlock}>
              <img src={error} alt={'error'} />
              <div className={classes.dataError}>Поле должно быть уникальным</div>
            </div>
          )}
        </label>

        <div className={styles.barcode}>
          <span className={styles.infoItemName}>Штрихкод</span>
          <BarcodesMultiList
            list={
              selectedStorageType?.barcodes.filter((val) => !!val.type).length
                ? selectedStorageType?.barcodes?.map((val) => {
                    return { id: val.type, type: val.type, value: val.value };
                  })
                : (selectedStorageType?.barcodes ?? [''])
            }
            getItemsList={getListBarcodes}
            setWidget={setWidget}
            setList={(val) => setSelectedStorageField('barcodes', val)}
          />
          {nameError3 && (
            <div className={styles.dataErrorBlock}>
              <img src={error} alt={'error'} />
              <div className={classes.dataError}>Штрихкод должен быть уникальным</div>
            </div>
          )}
        </div>

        <label className={styles.infoItemIcon} style={{ marginTop: '10px' }}>
          <span className={styles.infoItemName}>Иконка</span>
          <DropdownComponent
            items={dataIcons?.items}
            disabled={true}
            dataCustom={dataIcons}
            label={''}
            arrowColor={'#086C22'}
            customComponent={'icons'}
          />
        </label>
        <div className={styles.infoItem}>
          <div className={styles.infoItemName}>Цвет</div>
          <div style={{ marginLeft: '13px' }}>
            <ColorPicker
              selected={{
                color: selectedStorageType?.color ? selectedStorageType.color : '#000000',
              }}
              onChange={({ color }) => {
                setSelectedStorageField('color', color.slice(0, -2));
              }}
              item={selectedStorageType?.color ?? '#000000'}
            />
          </div>
        </div>
      </div>
      <div className={styles.description}>
        <p className={styles.infoItemNameAbout}>Описание</p>
        <textarea
          value={selectedStorageType?.comment ?? ''}
          onChange={(e) => setSelectedStorageField('comment', e.target.value)}
          placeholder={'Описание хранилища'}
        ></textarea>
      </div>

      {selectedStorageType?.parentType.id === 2 && (
        <AutoComplete
          mode={mode}
          setWidget={setWidget}
          storageTypes={storageTypes}
          setStorageTypes={setStorageTypes}
          selectedPackCount={selectedPackCount}
          setSelectedPackCount={setSelectedPackCount}
          selectedPackType={selectedPackType}
          setSelectedPackType={setSelectedPackType}
        />
      )}

      <div className={styles.functionalButtons}>
        <div className={styles.save} onClick={startSave}>
          Сохранить
        </div>

        <div
          className={styles.cancel}
          onClick={() => {
            clearSelectedStorageType();
            mode.setType({ mode: 'view', item: mode.type.item });
          }}
        >
          Отмена
        </div>
      </div>
    </div>
  );
});

export default WorkRightStorageAddGroup;
