export function sortStruct(struct) {
  const structSort = [];
  for (let i = 1; i <= struct.length; i++) {
    for (let j = 0; j < struct.length; j++) {
      if (struct[j].pos === i) {
        structSort.push(struct[j]);
        break;
      }
    }
  }
  return structSort;
}

export function sortRowByStruct(struct, row) {
  const rowsItemSort = [];
  for (let i = 1; i <= struct.length; i++) {
    for (let j = 0; j < struct.length; j++) {
      if (struct[j].pos === i) {
        for (let k = 0; k < row.length; k++) {
          if (row[k].id === struct[j].id) {
            rowsItemSort.push(row[k]);
            break;
          }
        }
        break;
      }
    }
  }
  return rowsItemSort;
}

export function getMasItem(struct, item) {
  const res = [];
  for (let i = 0; i < struct?.length; i++) {
    if (item) {
      if (typeof struct[i].field == 'string') {
        res.push({
          name: struct[i].field,
          value: item[struct[i].field],
          setting: '',
          type: struct[i].type,
          id: i,
        });
      } else {
        res.push({
          name: struct[i].field,
          value:
            item[`user_field[${struct[i].field}]`]?.value ??
            item[`user_field[${struct[i].field}]`] ??
            '',
          setting: item[`user_field[${struct[i].field}]`]?.setting,
          type: struct[i].type,
          id: i,
        });
      }
    }
  }
  return res;
}

export function getBoolFilter(item, filter, struct) {
  let res = false;
  struct.map((i) => {
    if (i.type === 'string' || i.type === 'text') {
      if (item[i.field]) {
        res ||= item[i.field].toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      }
    }
  });
  return res;
}

String.prototype.replaceAt = function (index, character) {
  return this.substr(0, index) + character + this.substr(index + character.length);
};
