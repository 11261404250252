import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FieldsStore } from '../../../../store/FieldsStore';
import { observer } from 'mobx-react-lite';

import classes2 from '../../WorkRightCommon/WorkRight/workRight.module.css';
import classes from './workRightEditManual.module.css';
import close from '../../../../assets/svg/close-01.svg';

import Icon from '../../../../helpers/components/Icon';
import ParametersComponent from './components/ParametersComponent';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';

import { AttentionIcon, TriangleDownIcon } from '../../../../helpers/icons';
import { configure } from 'mobx';
import TextareaAutosize from 'react-textarea-autosize';

const MIN_TEXTAREA_HEIGHT = 40;

const WorkRightEditManualItem = observer(({ item, modalOpen, snapshot, provided, innerRef }) => {
  const store = useContext(FieldsStore);

  const availableTypes = store.fieldTypes;

  const name = item.name ?? '';
  const type = availableTypes ? availableTypes.find((avType) => avType.id === item.type) : null;
  const setting = item.setting ?? [];
  const description = item.description ?? '';
  const search = item.search ?? false;
  const sorting = item.sorting ?? false;
  const required = item.required ?? false;
  const unique = item.unique ?? false;

  const [hovered, setHovered] = useState(false);
  const [textareaClass, setTextareaClass] = useState(item.description?.split('\n').length >= 5);
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${Math.max(
        textareaRef?.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT,
      )}px`;
    }
  }, [description]);

  const openStructureTab = useRef(null);
  const deleteStructureRef = useRef(null);

  const activateCurrentStructure = (ev, firstOpen = false) => {
    if (firstOpen) {
      if (
        !openStructureTab.current?.contains(ev.target) &&
        !deleteStructureRef.current?.contains(ev.target) &&
        openStructureTab.current !== ev.target &&
        deleteStructureRef.current !== ev.target
      ) {
        store.toggleOpenField(item.order_field);
        store.toggleFieldActive(item.order_field);
        return true;
      }
      return false;
    } else if (
      !openStructureTab.current?.contains(ev.target) &&
      !deleteStructureRef.current?.contains(ev.target) &&
      openStructureTab.current !== ev.target &&
      deleteStructureRef.current !== ev.target
    ) {
      store.toggleFieldActive(item.order_field);
      return true;
    }
    return false;
  };

  const deleteButton = useMemo(() => {
    return hovered ? (
      <>
        <div
          ref={deleteStructureRef}
          className={`${classes.workRightCloseButton}`}
          onClick={() => modalOpen(item.order_field, name)}
        >
          <img src={close} alt={'close'} />
        </div>
      </>
    ) : (
      ''
    );
  }, [hovered]);

  const changeName = (name) => {
    new Promise((resolve) => {
      store.setFieldVal(name, 'name', item.order_field);
      resolve();
    }).then(() => {
      store.checkEqualNames();
      store.checkNotFilledFieldName();
    });
  };

  const changeType = (typeId) => {
    store.setFieldVal(
      availableTypes.find((avItem) => avItem.id === typeId)?.id,
      'type',
      item.order_field,
    );
    store.clearTypeFieldError(item.order_field);
  };

  const changeSetting = (data) => {
    store.setFieldVal(data, 'setting', item.order_field);
  };

  const textareaChanged = (block) => {
    const row = block.value.split('\n').length;
    if (row > 5) {
      setTextareaClass(true);
    } else {
      setTextareaClass(false);
    }
  };

  const selectedTypeHaveSetting = (type) => {
    if (type) {
      if (
        type.type_name === 'select' ||
        type.type_name === 'range' ||
        type.type_name === 'location' ||
        type.type_name === 'switch_variants'
      ) {
        return true;
      }
      return Object.keys(type.parameters).length;
    } else {
      return false;
    }
  };

  const FullBlock = (
    <>
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ ...provided.draggableProps.style }}
        ref={innerRef}
        className={`${item.active ? classes.workRightEditItemActive : classes.workRightEditItem} ${snapshot.isDragging && classes.workRightEditItemDraggin}`}
        onClick={!item.active ? (e) => activateCurrentStructure(e) : () => {}}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <div className={classes.workRightEditInputs}>
          <div
            className={`${classes.manualRow}  ${classes.manualSmallRow}  ${classes.manualClosedTab}`}
            style={{
              display: item.active ? 'none' : 'flex',
            }}
          >
            <div className={classes.workRightEditItemShortButtons}>
              <div
                ref={openStructureTab}
                className={classes.toggleStructure}
                onClick={() => store.toggleOpenField(item.order_field, false)}
              >
                <Icon
                  className={classes.manualArrow}
                  src={TriangleDownIcon}
                  width={12}
                  height={12}
                  color={'#086c22'}
                />
              </div>
              {deleteButton}
            </div>
          </div>
          <div className={classes.manualRow}>
            <span className={classes.manualLabel}>Наименование</span>
            <div className={classes.workRightInputBlock}>
              <input
                className={`${classes.workRightInput} 
                                ${
                                  item.errors && (item.errors.name || item.errors.required)
                                    ? classes.workRightInputError
                                    : ''
                                }`}
                type={'text'}
                value={name}
                disabled={!item.active}
                placeholder={'Новое поле'}
                onChange={(e) => changeName(e.target.value)}
              />
              {item.errors && (item.errors.name || item.errors.required) ? (
                <>
                  <div className={classes.workRightInputErrorMessage}>
                    <Icon color={'#c12d2d'} src={AttentionIcon} width={'10px'} height={'10px'} />
                    <span>
                      {item.errors.required
                        ? 'Поле обязательно для заполнения'
                        : item.errors.name
                          ? 'Поле должно быть уникальным'
                          : 'Ошибка'}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className={classes.deleteButton}>
              {item.active ? (
                <>
                  <div
                    className={classes2.workRightCloseButton}
                    onClick={() => modalOpen(item.order_field, name)}
                  >
                    <img src={close} alt={'close'} />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={item.id ? classes.stopEdit : ''}>
            <div className={classes.manualRow}>
              <span className={classes.manualLabel}>Тип поля</span>
              <div>
                <DropdownComponent
                  key={`dd_${item.id}`}
                  cols={2}
                  error={!!(item.errors && item.errors.type)}
                  disabled={!item.active}
                  items={availableTypes}
                  hasIcons={true}
                  selectedKey={type ? type.id : null}
                  label={'Выберите тип...'}
                  onChange={(val) => changeType(val)}
                />
              </div>
            </div>

            {selectedTypeHaveSetting(type) ? (
              <div className={classes.manualRow}>
                <div></div>
                <ParametersComponent
                  type={type.type_name}
                  parameters={type.parameters}
                  output={setting}
                  setOutput={(data) => changeSetting(data)}
                />
              </div>
            ) : null}
          </div>

          <div className={classes.manualRow}>
            <span className={classes.manualLabel}>Описание</span>
          </div>

          <div className={`${classes.manualRow} ${classes.manualRowMono}`}>
            <TextareaAutosize
              className={
                textareaClass
                  ? classes.workRightEditTextArea
                  : classes.workRightEditTextAreaWitoutScrollbar
              }
              value={description}
              maxRows={5}
              placeholder={'Текст описания объекта'}
              onChange={(e) => {
                textareaChanged(e.target);
                store.setFieldVal(e.target.value, 'description', item.order_field);
              }}
              disabled={!item.active}
            />
          </div>
        </div>

        <div className={classes.workRightEditSwitchers}>
          <div
            className={
              `${classes.manualRow} ${classes.manualRowAlignCenter} ` +
              ' ' +
              (!!item.id && !item.required ? classes.stopEdit : '')
            }
          >
            <span className={classes.manualLabel}>Обязательно для заполнения</span>
            <div>
              <label className={classes2.workRightSwitch}>
                <input
                  type={'checkbox'}
                  checked={required}
                  onChange={(e) =>
                    store.setFieldVal(e.target.checked, 'required', item.order_field)
                  }
                />
                <span className={classes2.workRightSlider}></span>
              </label>
            </div>
          </div>
          <div className={`${classes.manualRow} ${classes.manualRowAlignCenter} `}>
            <span className={classes.manualLabel}>Уникальность</span>
            <div>
              <label className={classes2.workRightSwitch}>
                <input
                  type={'checkbox'}
                  checked={unique}
                  onChange={(e) => store.setFieldVal(e.target.checked, 'unique', item.order_field)}
                />
                <span className={classes2.workRightSlider}></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const ShortBlock = (
    <>
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ ...provided.draggableProps.style }}
        ref={innerRef}
        className={`${classes.workRightEditItemShort} ${snapshot.isDragging && classes.workRightEditItemDraggin}`}
        onClick={(e) => activateCurrentStructure(e, true)}
        onDrag={(e) => {}}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <div className={classes.workRightEditItemShortTitle}>
          {(item.errors || name === '') && (
            <Icon color={'#c12d2d'} src={AttentionIcon} width={'20px'} height={'20px'} />
          )}

          <span
            style={{
              color: name === '' ? '#c12d2d' : '',
              fontWeight: name === '' ? '400' : '',
            }}
          >
            {name !== '' ? name : 'Название не указано'}
          </span>
        </div>
        <div className={classes.workRightEditItemShortButtons}>
          <div
            ref={openStructureTab}
            className={classes.toggleStructure}
            onClick={() => store.toggleOpenField(item.order_field)}
          >
            <Icon
              className={`${classes.manualArrow} ${classes.manualArrowLeft}`}
              src={TriangleDownIcon}
              color={'#086c22'}
              width={12}
              height={12}
            />
          </div>
          {deleteButton}
        </div>
      </div>
    </>
  );

  return <>{item.opened ? FullBlock : ShortBlock}</>;
});

export default WorkRightEditManualItem;

configure({ enforceActions: 'observed' });
