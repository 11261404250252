import React from 'react';
import classes from './workRightUserAddMenu.module.css';
import WorkRightUserAddMenuItemItem from './workRightUserAddMenuItemItem';
import WorkRightUserAddMenuReportsItem from './workRightUserAddMenuReportsItem';
import GroupCheckBox from '../../../Widgets/GroupCheckBox/GroupCheckBox';

const WorkRightUserAddMenuItem = ({ item, setSettings, settings, reports, setReports }) => {
  const changeValue = (title, state) => {
    let s = JSON.parse(JSON.stringify(settings));
    if (!s) return;

    let storage = s.find((item) => item.name === title);

    if (!storage) return;

    if (state === 'UncheckedAll') {
      storage.fields.forEach((item) => {
        item.value = false;
      });
    }

    if (state === 'CheckedAll') {
      storage.fields.forEach((item) => {
        item.value = true;
      });
    }
    setSettings(s);
  };

  return (
    <div>
      <GroupCheckBox
        title={item.name}
        values={item.fields.map((i) => i.value)}
        onChange={changeValue}
      />
      {item.fields.map((itemS) => (
        <WorkRightUserAddMenuItemItem
          settings={settings}
          setSettings={setSettings}
          item={JSON.parse(JSON.stringify(itemS))}
          key={itemS.id}
        />
      ))}
      {item.name === 'Отчеты' &&
        reports.map((itemS) => (
          <WorkRightUserAddMenuReportsItem
            key={itemS.id}
            item={JSON.parse(JSON.stringify(itemS))}
            setReports={setReports}
            reports={reports}
          />
        ))}
    </div>
  );
};

export default WorkRightUserAddMenuItem;
