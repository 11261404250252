import React, { useContext } from 'react';
import WorkRightHeader from '../../WorkRightCommon/WorkRightHeader/workRightHeader';
import WorkRightStorageTable from '../WorkRightStorageTable/workRightStorageTable';
import styles from './workRightStorage.module.css';
import WorkRightStorageViewMenu from '../WorkRightStorageViewMenu/workRightStorageViewMenu';
import { observer } from 'mobx-react-lite';
import { getTypeSamples } from '../../../../services/list.services';
import { STORAGE_TYPE, Store } from '../../../../store/Store';
import AddSampleModal from '../WorkRightStorageAddMenu/addSampleModal';
import ModalPackAdd from '../../../Packs/ModalPackAdd/ModalPackAdd';
import ModalPackSelect from '../../../Packs/ModalPackSelect/ModalPackSelect';
import { setPackToStorage } from '../../../../services/pucks.services';
import { toJS } from 'mobx';
import { SampleTypeModal } from '../../SampleType/sampleTypeModal';

const structHeader = {
  name: false,
  search: true,
  add: false,
  filter: false,
  print: false,
  wrap: false,
};

export const ExtendedSampleContext = React.createContext('extended-sample-context');

const WorkRightStorage = observer(({ setWidget, sizeX, mode }) => {
  const [types, setTypes] = React.useState([]);
  const rules = mode.rules ?? {};

  const { selectedTreeNodePacks, selectedSample, setSampleField, loadTree, selectedTreeNode } =
    React.useContext(Store);

  React.useEffect(() => {
    const loadTypes = async () => {
      let res = await getTypeSamples();
      setTypes(res);
    };
    loadTypes();
  }, []);

  const savePackToStorage = async (data) => {
    let res = await setPackToStorage(data);

    if (res.success) {
      mode.setPuckSelectModal(false);
      await loadTree(false, '');
    } else {
      setWidget({
        status: 'Error',
        text: res.error_code,
        fun: async () => {},
      });
      mode.setPuckSelectModal(false);
    }
  };

  React.useEffect(() => {
    mode.updateMode();
  }, [selectedTreeNodePacks]);

  return (
    <div style={{ overflow: 'hidden', height: '100%' }}>
      <WorkRightHeader
        setVisibleAdd={mode.setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        filter={''}
        setFilter={() => {}}
      />
      <div className={styles.rowContainer}>
        <div className={styles.containerWorkSpace}>
          <div
            className={styles.workSpace}
            style={{ gridTemplateColumns: 'auto', height: '100vh' }}
          >
            <div className={styles.samples}>
              {rules?.storage?.get_info &&
                selectedTreeNodePacks.map((pack, index) => (
                  <WorkRightStorageTable
                    mode={mode}
                    key={pack.id + '-' + index}
                    path={pack.path}
                    storageId={pack.storageId}
                    pack={pack}
                    samples={pack.samples ?? []}
                    setViewMode={mode.setViewMode}
                  />
                ))}
            </div>
          </div>

          {mode.showEmptyDetail && selectedSample && !mode.visibleView && (
            <SampleTypeModal
              mode={mode}
              styles={styles}
              selectedSample={selectedSample}
              types={types}
              setSampleField={setSampleField}
              setShowEmptyDetail={mode.setShowEmptyDetail}
            />
          )}
        </div>

        {mode.visibleAdd && rules?.sample?.upsert && (
          <AddSampleModal
            mode={mode}
            visibleAdd={mode.visibleAdd}
            setVisibleAdd={mode.setVisibleAdd}
            setWidget={setWidget}
          />
        )}
      </div>

      {mode.visibleView && rules?.sample?.get_info && (
        <WorkRightStorageViewMenu
          mode={mode}
          sizeX={sizeX}
          setWidget={setWidget}
          visibleAdd={mode.viewMode !== 0}
        />
      )}
      {mode.puckAddModal && rules?.storage?.upsert && (
        <ModalPackAdd
          setWidget={setWidget}
          onClose={() => mode.setPuckAddModal(false)}
          openModal={mode.puckAddModal}
          packData={{ id: 0, name: '', column: 0, lines: 0, column_name: '', lines_name: '' }}
        />
      )}
      {mode.puckSelectModal && rules?.storage?.upsert && (
        <ModalPackSelect
          mode={mode}
          setWidget={setWidget}
          action={savePackToStorage}
          onClose={() => mode.setPuckSelectModal(false)}
          openModal={mode.puckSelectModal}
          storageId={selectedTreeNode?.type === STORAGE_TYPE ? selectedTreeNode.id : 0}
        />
      )}
    </div>
  );
});

export default WorkRightStorage;
