import { createContext, useContext } from 'react';
import { makeAutoObservable, observable, runInAction, toJS } from 'mobx';
import { getSample, getTypeSampleById } from '../services/sample.services';

class AppStore {
  typeSampleSelected = {
    description: '',
    editable: null,
    icon: null,
    id: null,
    name: '',
    unit: null,
    user_fields: [],
    activeFieldTabId: 1,
    folder: null,
    folders: [],
    id_icon: null,
    currentPage: 1,
    limit: 10,
    sortField: { name: 'asc' },
    searchValue: '',
    pagination: {},
    selectedArray: [],
    globalFields: [],
    deleted_fields: [],
    defreeze: null,
  };

  constructor() {
    makeAutoObservable(this, {
      typeSampleSelected: observable,
    });
  }

  setTypeSampleId = async (id) => {
    const res = await getTypeSampleById({ id: id });
    for (const key of Object.keys(res.result)) {
      this.typeSampleSelected[key] = res.result[key];
    }
  };

  getStore = () => {
    return {
      typeSampleSelected: this.typeSampleSelected,
    };
  };

  setStore = (data) => {
    if (data) {
      this.typeSampleSelected = data.typeSampleSelected;
    }
  };

  clearTypeSampleField = () => {
    this.typeSampleSelected = {
      description: null,
      editable: null,
      icon: null,
      id: null,
      name: null,
      unit: null,
      user_fields: [],
      activeFieldTabId: 1,
      folder: null,
      folders: [],
      id_icon: null,
      currentPage: 1,
      limit: 10,
      sortField: { name: 'asc' },
      searchValue: '',
      pagination: {},
      selectedArray: [],
      globalFields: [],
      deleted_fields: [],
    };
  };

  setTypeSampleField = (key, val) => {
    const copyObj = JSON.parse(JSON.stringify(this.typeSampleSelected));
    copyObj[key] = val;
    this.typeSampleSelected = copyObj;
  };
}

export const TypeSampleStore = createContext(new AppStore());
