import React, { useEffect, useState } from 'react';
import WorkLeftReportsItem from './WorkLeftReportsItem';
import classes2 from './workLeftReports.module.css';

const WorkLeftReports = ({ setWidget, reports, mode }) => {
  const rules = mode.rules;

  const [rowsButtons, setRowsButtons] = useState({
    print: false,
    copy: false,
    edit: true,
    delete: true,
  });

  useEffect(() => {
    setRowsButtons({
      print: false,
      copy: false,
      edit: rules?.report_group?.upsert,
      delete: rules?.report_group?.delete,
    });
  }, [mode]);

  return (
    <div className={classes2.WorkLeftTableBlock}>
      <table className={classes2.WorkLeftTable}>
        <tbody>
          {reports.map((rowsItem) => (
            <WorkLeftReportsItem
              item={rowsItem}
              key={rowsItem.id}
              rowsButton={rowsButtons}
              setWidget={setWidget}
              mode={mode}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorkLeftReports;
