import './preloader.widget.css';

const Preloader = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 100,
        top: 0,
        left: 0,
        background: 'rgba(255,255,255,0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="loader" />
    </div>
  );
};

export default Preloader;
