import styles from './ChangePassword.module.css';
import React, { useState, useEffect } from 'react';
import visiblePasswordSvg from '../../assets/svg/eye_open-01.svg';
import unvisiblePasswordSvg from '../../assets/svg/eye_closed-01.svg';
import { address_server_short } from '../../config';
import { editProfileShort, getProfile, rePassword } from '../../services/user.services';
import userLogo from '../../assets/png/user_logo.png';
import classes from '../WorkRight/WorkRightReportsDir/WorkRightReportsAddMenu/workRightReportsAddMenu.module.css';
import error from '../../assets/svg/attention-01.svg';
import { observer } from 'mobx-react-lite';
import { authStore } from 'store/auth.store';

const ChangePassword = observer(({ mode, setWidget }) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleOldPassword, setIsVisibleOldPassword] = useState(false);
  const [name, setName] = useState('');
  const [post, setPost] = useState('');
  const [birth, setBirth] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [avatar, setAvatar] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [tempAvatar, setTempAvatar] = useState();

  useEffect(() => {
    const getProfile_t = async () => {
      const res = await getProfile(0);
      setName(res.fio);
      setPost(res.position);
      if (res.birth)
        setBirth(
          res.birth.split('.')[2] + '-' + res.birth.split('.')[1] + '-' + res.birth.split('.')[0],
        ); //res.birth);
      setEmail(res.email);
      if (res.avatar) setAvatar(res.avatar);
      else setAvatar('');
    };
    getProfile_t();
  }, []);

  async function startRePassword() {
    if (newPassword.length < 4) {
      setErrorNewPassword(true);
    } else {
      const res = await rePassword(password, newPassword);
      if (res) {
        setWidget({ status: 'Ok', text: 'Пароль успешно изменен!', fun: async () => {} });
        setPassword('');
        setNewPassword('');
        setErrorPassword(false);
      } else setErrorPassword(true);
      setErrorNewPassword(false);
    }
  }

  async function startEditProfile() {
    if (name === '') {
      setErrorName(true);
    } else {
      const res = await editProfileShort({
        fio: name,
        birth: birth,
        position: post,
        avatar: avatar,
        email: email,
      });
      if (res.success) {
        setWidget({ status: 'Ok', text: 'Данные профиля успешно изменены!', fun: async () => {} });
      } else {
        setWidget({ status: 'Error', text: res.error_code, fun: async () => {} });
      }
    }
  }

  return (
    <div className={styles.User}>
      <div className={styles.UserInfo}>
        <div className={styles.Block1Img}>
          <div className={styles.Block1ImgBlock}>
            {avatar || tempAvatar ? (
              <img
                src={tempAvatar ? tempAvatar : address_server_short + avatar}
                alt={'userLogo'}
                className={styles.Avatar}
              />
            ) : (
              <img src={userLogo} alt={'userLogo'} className={styles.Avatar} />
            )}
            {avatar && (
              <div className={styles.DeleteAvatarBlock} onClick={() => setAvatar('')}>
                <div className={styles.DeleteAvatar}></div>
              </div>
            )}
            <label
              htmlFor="inputAvatar"
              className={styles.EditAvatarBlock}
              data-tooltip="Максимальный размер: 5мб"
            >
              <div className={styles.EditAvatar}></div>
            </label>
            <input
              hidden={true}
              id={'inputAvatar'}
              type="file"
              name="picture"
              accept="image/*"
              onChange={(e) => setTempAvatar(window.URL.createObjectURL(e.target.files[0]))}
            />
          </div>
        </div>
        <div className={styles.UserData}>
          <div className={styles.UserInfoItem}>
            <p className={styles.UserInfoItemName}>ФИО</p>
            <input
              className={styles.UserInfoItemValue + ' ' + (errorName && styles.InputError)}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrorName(false);
              }}
            />
            {errorName && (
              <div className={styles.errorBlock}>
                <img src={error} alt={'error'} />
                <div className={classes.LoginErrorText2}>Поле обязательно для заполнения</div>
              </div>
            )}
          </div>
          <div className={styles.UserInfoItem}>
            <p className={styles.UserInfoItemName}>Должность</p>
            <input
              readOnly={true}
              className={styles.UserInfoItemValue}
              value={post}
              onChange={(e) => setPost(e.target.value)}
            />
          </div>
          <div className={styles.UserInfoItem}>
            <p className={styles.UserInfoItemName}>Дата рождения</p>
            <input
              style={{ width: 'calc(100% - 157px)' }}
              className={styles.UserInfoItemValue}
              value={birth}
              onChange={(e) => setBirth(e.target.value)}
              type={'date'}
            />
          </div>
          <div className={styles.UserInfoItem}>
            <p className={styles.UserInfoItemName}>Email</p>
            <input
              readOnly={true}
              className={styles.UserInfoItemValue}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type={'email'}
            />
          </div>
        </div>
      </div>
      <div className={styles.functionalButtons}>
        <button className={styles.button_edit} onClick={() => startEditProfile()}>
          Сохранить
        </button>
        <button className={styles.button_cancel} onClick={() => mode.setPath('Хранилище')}>
          Отмена
        </button>
      </div>
      <div className={styles._}>
        <h3>Сменить пароль</h3>
        <div className={styles.wrapper}>
          <label className={styles.passwordItem}>
            <span>Старый пароль</span>
            <input
              className={styles.input + ' ' + (errorPassword && styles.InputError)}
              type={isVisibleOldPassword ? 'text' : 'password'}
              style={{ width: 'calc(100% - 168px)' }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorPassword(false);
              }}
            />
            <button
              style={{ margin: '3px 0 0 0' }}
              onClick={() => setIsVisibleOldPassword(!isVisibleOldPassword)}
            >
              {isVisibleOldPassword ? (
                <img src={visiblePasswordSvg} />
              ) : (
                <img src={unvisiblePasswordSvg} />
              )}
            </button>
            {errorPassword && (
              <div className={styles.errorBlock}>
                <img src={error} alt={'error'} />
                <div className={classes.LoginErrorText2}>Старый пароль неверный</div>
              </div>
            )}
          </label>
          <label className={styles.passwordItem}>
            <span>Новый пароль</span>
            <input
              className={styles.input + ' ' + (errorNewPassword && styles.InputError)}
              type={isVisiblePassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setErrorNewPassword(false);
              }}
            />
            <button
              style={{ margin: '3px 0 0 0' }}
              onClick={() => setIsVisiblePassword(!isVisiblePassword)}
            >
              {isVisiblePassword ? (
                <img src={visiblePasswordSvg} />
              ) : (
                <img src={unvisiblePasswordSvg} />
              )}
            </button>
            {errorNewPassword && (
              <div className={styles.errorBlock}>
                <img src={error} alt={'error'} />
                <div className={classes.LoginErrorText2}>Пароль должен быть больше 3 символов</div>
              </div>
            )}
          </label>
          <button onClick={() => startRePassword()} className={styles.button}>
            Сменить пароль
          </button>
        </div>
      </div>
    </div>
  );
});
export default ChangePassword;
