import classes from './WidgetFileLoader.module.css';
import React, { useState, useRef, useCallback } from 'react';
import skrepka_img from '../../assets/svg/screpka.svg';
import error_img from '../../assets/svg/attention-01.svg';
import magic_stick_img from '../../assets/png/magic_stick.png';
import close_img from '../../assets/svg/close-01.svg';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import ModalInfoComponent, { StatusError } from './ModalInfo/ModalInfoComponent';
import { v4 as uuid } from 'uuid';
import { ImageIcon } from '../../helpers/icons';
import { address_server, address_server_short } from '../../config';

/*
Example DragAndDrop code: https://react-dnd.github.io/react-dnd/examples/other/native-files
*/

/**
 * Создает Widget загрузки файлов и возможностями DragAndDrop файлов.
 * @param {Function} onLoadFile Функция которая вызывается когда загружается файл и возвращает все файлы.
 * @param {Array} listFile Массив с файлами или строками.
 * @param {String} title Заголовок.
 * @param {String} subtitle Подзаголовок.
 * @param {Boolean} isError Переменаня которая обозначает стоит показывать ошибку или нет.
 */
const WidgetFileLoader = ({
  onLoadFile,
  listFile,
  title,
  subtitle,
  isError,
  onDeleteOldFile,
  oldFiles,
  isImage = false,
  itemFromImage,
}) => {
  const inputElement = useRef(null);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [modalOpened, setModalOpened] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [itemsReady, setItemsReady] = useState(false);

  const getSizes = (settings) => {
    return [settings.width, settings.height];
  };

  const getWeight = (settings) => {
    if (settings) {
      const weight = settings.max_size;
      return weight?.value;
    }
  };

  useEffect(() => {
    setDroppedFiles(listFile?.filter((item) => !!item));
  }, [listFile]);

  const is_view_file_list = droppedFiles.length > 0;

  const addItems = (item) => {
    const new_files = Array.from(item.files);
    const all_files = droppedFiles.concat(new_files);
    setDroppedFiles(all_files);
    onLoadFile(all_files);
  };
  const handleLoadFile = (item) => {
    if (item) {
      if (isImage) {
        let img = new Image();
        img.src = URL.createObjectURL(item?.files[0]);
        let weight = itemFromImage?.setting?.max_size;
        if (Number(weight) > 0) {
          if (item?.files[0]?.size < weight * 1000000) {
            addItems(item);
          }
        } else if (Number(weight) <= 0) {
          addItems(item);
        }

        img.onload = () => {
          let sizes = getSizes(itemFromImage?.setting),
            maxWidth = Number(sizes[0].value),
            maxHeight = Number(sizes[1].value),
            weight = itemFromImage?.setting?.max_size,
            checkArr = [];

          if (maxWidth > 0) checkArr.push({ maxWidth: maxWidth });
          if (maxHeight > 0) checkArr.push({ maxHeight: maxHeight });
          if (Number(weight) > 0) checkArr.push({ weight: Number(weight) });

          const checkFunc = (el) => {
            if (checkArr?.length) {
              if (Object.keys(el)[0] === 'maxWidth') {
                if (img.width < maxWidth) {
                  checkArr.shift();
                  checkFunc(checkArr[0]);
                }
              }
              if (Object.keys(el)[0] === 'maxHeight') {
                if (img.height < maxHeight) {
                  checkArr.shift();
                  checkFunc(checkArr[0]);
                }
              }
              if (Object.keys(el)[0] === 'weight') {
                if (item?.files[0].size < weight * 1000000) {
                  checkArr.shift();
                  checkFunc(checkArr[0]);
                }
              }
            }

            if (checkArr?.length === 0) addItems(item);
          };

          if (checkArr?.length) checkFunc(checkArr[0]);
        };
      }

      if (!isImage) {
        addItems(item);
      }
    }

    inputElement.current.value = null;
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (handleLoadFile) {
          handleLoadFile(item);
        }
      },
      canDrop(item) {
        return true;
      },
      hover(item) {},
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [handleLoadFile],
  );
  const isActive = canDrop && isOver;

  const handlerDeleteFile = () => {
    const copyDroppedFiles = [...droppedFiles];
    copyDroppedFiles.splice(deletedItem, 1);
    setDroppedFiles(copyDroppedFiles);
    onLoadFile(copyDroppedFiles);
    setDeletedItem(null);
    setModalOpened(false);
  };

  const handlerButton = (e) => {
    e.preventDefault();
    inputElement.current.click();
  };

  return (
    <>
      {/* Target Box */}
      <div
        className={clsx([
          {
            [classes.container_hover]: isActive,
          },
          classes.container_file_loader,
        ])}
        ref={drop}
      >
        <div className={classes.title}>
          <img src={skrepka_img} alt="" />
          <span>{title}</span>
        </div>
        <input
          hidden={true}
          ref={inputElement}
          onChange={() => handleLoadFile(inputElement.current)}
          type="file"
          multiple
          name="file"
          accept={isImage ? 'image/jpeg, image/jpg, application/pdf' : 'file/*'}
        />
        <button className={classes.button} onClick={handlerButton}>
          Выбрать файл
        </button>
        <p className={classes.subinfo}>{subtitle}</p>
        {isError && (
          <div className={classes.error_block}>
            <img src={error_img} alt="error" />
            <div className={classes.error_text}>Загрузите файл</div>
          </div>
        )}
      </div>
      {/* File List */}
      {isImage && (
        <div className={`${classes.contain_list_file} + ${classes.image}`}>
          {oldFiles?.length
            ? oldFiles.map((file, index_file) => (
                <>
                  <div key={index_file}>
                    <div className={classes.list_file_block}>
                      <img
                        className={classes.imagePreview}
                        src={address_server_short + file}
                        alt={`${file}`}
                        title={`${file}`}
                      />
                      <img
                        className={classes.delete_file}
                        src={close_img}
                        alt="Удалить"
                        title={'Удалить'}
                        onClick={() => {
                          onDeleteOldFile(index_file);
                        }}
                      />
                    </div>
                  </div>
                </>
              ))
            : ''}
          {droppedFiles.map((file, index_file) => {
            if (!file) return null;

            return (
              <div key={index_file}>
                <div className={classes.list_file_block + classes.imgContainer}>
                  <img
                    className={
                      classes.imagePreview +
                      ` ${file?.type === 'application/pdf' ? classes.pdfFile : ''}`
                    }
                    src={
                      typeof file.name == 'string'
                        ? URL.createObjectURL(file)
                        : `${address_server_short + file}`
                    }
                    alt={`${file?.name}`}
                    title={`${file?.name}`}
                  />
                  <div>
                    {typeof file.name == 'string' ? file.name : file.split('/').reverse()[0]}
                  </div>
                  <img
                    className={classes.delete_file}
                    src={close_img}
                    alt="Удалить"
                    title={'Удалить'}
                    onClick={() => {
                      setDeletedItem(index_file);
                      setModalOpened(true);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!isImage && (
        <div className={classes.contain_list_file}>
          {oldFiles?.length
            ? oldFiles.map((file, index_file) => (
                <>
                  <div key={index_file}>
                    <div className={classes.list_file_block}>
                      <div>
                        {file.name
                          ? file.name.split('/').reverse()[0]
                          : file.split('/').reverse()[0]}
                      </div>
                      <img
                        className={classes.delete_file}
                        src={close_img}
                        alt="Удалить"
                        title={'Удалить'}
                        onClick={() => {
                          onDeleteOldFile(index_file);
                        }}
                      />
                    </div>
                  </div>
                </>
              ))
            : ''}
          {droppedFiles.map((file, index_file) => {
            if (!file) return null;
            return (
              <div key={index_file}>
                <div className={classes.list_file_block}>
                  <div>
                    {file.name ? file.name.split('/').reverse()[0] : file.split('/').reverse()[0]}
                  </div>
                  <img
                    className={classes.delete_file}
                    src={close_img}
                    alt=""
                    onClick={() => {
                      setDeletedItem(index_file);
                      setModalOpened(true);
                    }}
                  />
                </div>
              </div>
            );
          })}

          {/* <div className={classes.list_file_button}>
                        <img src={magic_stick_img} alt="" />
                        <span>Открыть в редакторе</span>
                    </div> */}
        </div>
      )}

      <ModalInfoComponent
        key={uuid()}
        openModal={modalOpened}
        onClose={() => setModalOpened(false)}
        onAccept={() => handlerDeleteFile()}
        title={'Вы действительно хотите удалить файл?'}
        text={''}
        acceptButtonText={'Да'}
        status={StatusError}
      />
    </>
  );
};

export default WidgetFileLoader;
