import { useEffect, useState } from 'react';
import styles from './forgetPassword.module.css';
import logo from '../../assets/svg/logo-01.svg';
import logo2 from '../../assets/svg/logo_corner-01.svg';
import close from '../../assets/svg/close-01.svg';
import error from '../../assets/svg/attention-01.svg';
import { authStore } from 'store/auth.store';
import { observer } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FetchState } from 'consts/axios.consts';
import classNames from 'classnames';

//TODO: убрать в модуле ненужные стили
const ForgetPassword = observer(() => {
  const { clear, sendMail, fetchSendMail, msgSendMailError } = authStore;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState('');
  const [isBadToken, setIsBadToken] = useState(false);

  useEffect(() => {
    clear();
  }, []);

  useEffect(() => {
    if (searchParams.get('badToken')) {
      setIsBadToken(true);
      searchParams.delete('badToken');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <div className={styles.LoginBlock}>
      <div className={styles.ForgetBlock}>
        <img
          className={styles.close}
          src={close}
          alt={'close'}
          onClick={() => {
            navigate('/login');
          }}
        />
        <img src={logo} alt={'Logo'} className={styles.LoginLogoTop} />
        <div className={styles.LoginForm}>
          <div className={styles.ForgetInputBlock}>
            <div className={styles.ForgetText}>
              Для восстановления пароля Вам необходимо указать e-mail вашей учетной записи. На него
              будет отправлена ссылка для смены пароля. Или обратитесь к Администратору.
            </div>
            <div className={classNames(styles.LoginInputBlock, styles.LoginInputBlockForget)}>
              <div className={styles.LoginText}>Email</div>
              <input
                className={classNames(styles.LoginInput, {
                  [styles.LoginInputError]: fetchSendMail === FetchState.Rejected,
                })}
                type={'email'}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') sendMail(email);
                }}
              />
              {fetchSendMail === FetchState.Rejected && (
                <div className={styles.errorBlock}>
                  <img src={error} alt={'error'} />
                  <div className={styles.LoginErrorText2}>{msgSendMailError}</div>
                </div>
              )}
            </div>
          </div>
          {isBadToken && (
            <div className={styles.errorTokenBlock}>
              <img src={error} alt={'error'} />
              <div className={styles.errorTokenText}>
                Данная ссылка не действительна. Для смены пароля отправьте новую.
              </div>
            </div>
          )}
          <div className={styles.LoginButton} onClick={() => sendMail(email)}>
            Отправить
          </div>
        </div>

        <img src={logo2} alt={'logo'} className={styles.LoginLogo} />
      </div>
    </div>
  );
});

export default ForgetPassword;
