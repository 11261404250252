import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import classes from './WidgetDateTimePicker.module.css';
import { useEffect, useRef, useState } from 'react';
import icons, { ArrowDownIcon } from '../../helpers/icons';
import Icon from '../../helpers/components/Icon';
import moment from 'moment';
import useOutsideClick from '../../helpers/utils';
import dayjs from 'dayjs';

const WidgetDateTimePicker = ({ selected, onChange, isDateTime, isTime = false }) => {
  const [rerender, setRerender] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  const dateTimeRef = useRef(null);

  useOutsideClick(wrapperRef, setIsOpen);

  let dateFormat = 'YYYY-MM-DD';

  if (isTime) {
    dateFormat = 'HH:mm';
  }

  useEffect(() => {
    if (!isTime) {
      const dateTimeChanger = window.document.querySelector('.rdtPicker .rdtTimeToggle');
      const dateTimeSwitch = window.document.querySelector('.rdtPicker td.rdtSwitch');

      if (dateTimeChanger) {
        changeTextToIcon(dateTimeChanger, icons?.IconTime);
        dateTimeChanger.scrollIntoView();
      }
      if (dateTimeSwitch) {
        changeTextToIcon(dateTimeSwitch, icons?.IconCalendar);
      }

      setRerender(false);
    }
  }, [rerender]);

  const changeTextToIcon = (el, iconSrc) => {
    if (el && !el.classList.contains('switcher-with-icon')) {
      el.innerHTML = '';
      el.classList.add('switcher-with-icon');

      let iconTime = document.createElement('img');
      iconTime.src = iconSrc;

      el.appendChild(iconTime);
    }
  };

  let inputProps = {
    onClick: () => setIsOpen(!isOpen),
  };

  return (
    <div className={classes.containerDatePicker} ref={wrapperRef}>
      <Datetime
        ref={dateTimeRef}
        locale="ru"
        className={classes.input_datetime}
        value={selected ?? ''}
        onChange={onChange}
        timeFormat={isTime ? dateFormat : !!isDateTime}
        dateFormat={isTime ? false : dateFormat}
        open={isOpen}
        inputProps={inputProps}
      />
      <Icon
        src={ArrowDownIcon}
        color={'#009044'}
        width={12}
        height={7}
        className={classes.arrowIcon}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </div>
  );
};

export default WidgetDateTimePicker;
