import styles from './css/IconPicker.module.css';
import DropdownComponent from '../Dropdown/DropdownComponent';
import React, { useContext, useEffect, useState } from 'react';
import { getListBarcodes, getListIcon, getListPacks } from '../../../services/list.services';
import { modeContext } from '../../Contexts/contexts';
import { StorageStore } from '../../../store/StorageStore';
import { observer } from 'mobx-react-lite';

const IconPicker = observer(({ selected, onChange, item, disabled = false }) => {
  const { mode } = useContext(modeContext);
  const storageStore = useContext(StorageStore);

  const [listIcon, setListIcon] = useState([]);

  useEffect(() => {
    const requests = async () => {
      const listIcons = await getListIcon();
      setListIcon(listIcons);
    };
    if (!listIcon?.length) {
      requests();
    }
  }, [storageStore]);

  const dataIcons = {
    items: listIcon,
    selectIcon: selected.icon,
    setSelectIcon: (val) => onChange({ icon: val }),
    selectIconFile: selected.icon,
  };

  return (
    <div className={styles.container}>
      <DropdownComponent
        items={dataIcons?.items}
        dataCustom={dataIcons}
        label={''}
        disabled={disabled}
        arrowColor={'#086C22'}
        customComponent={'icons'}
      />
    </div>
  );
});

export default IconPicker;
