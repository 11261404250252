import { createRoot } from 'react-dom/client';
import App from './App';

String.prototype.toUnicode = function () {
  var result = '';
  for (var i = 0; i < this.length; i++) {
    // Assumption: all characters are < 0xffff
    result += '\\u' + ('000' + this[i].charCodeAt(0).toString(16)).substr(-4);
  }
  return result;
};

const root = document.getElementById('root');

if (root == null) {
  throw new Error('root not found');
}

const container = createRoot(root);

container.render(<App />);
