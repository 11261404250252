import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import { authStore } from 'store/auth.store';

const PrivateRoute = observer(({ children, pathBack }) => {
  const { token } = authStore;

  return token ? <>{children}</> : <Navigate to={pathBack} />;
});

export default PrivateRoute;
