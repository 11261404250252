import { address_server } from '../config';
import { getListTypeFields } from './list.services';
import { error } from '../utils/handlerError';
import { transliterate } from '../helpers/utils';
import { authStore } from 'store/auth.store';

export async function getCatalogs(search = '') {
  const token = authStore.token;
  const searchField = search !== '' ? `&search=${search}` : '';
  const url = `${address_server}catalog/catalogs?token=${token}${searchField}`;
  const res = await (await fetch(url)).json();
  if (res.success) {
    return res.result;
  }

  error('getCatalogs', res);
  return [];
}

export async function getCatalog(id) {
  const token = authStore.token;
  const url = `${address_server}catalog?token=${token}&id=${id}`;
  const res = await (await fetch(url)).json();
  if (res.success) {
    return res.result;
  }
  error('getCatalog', res);
  return {};
}

export async function getSample(id, mode) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'sample?token=' + token + '&id=' + id + '&mode=' + mode)
  ).json();
  if (res.success) {
    return res.result;
  }
  error('getSample', res);
  return [];
}

export async function create(data) {
  const token = authStore.token;
  const url = `${address_server}catalog`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('name', data.name);
  formData.append('fields', JSON.stringify(data.fields));

  if (data.id) {
    formData.append('id', data.id);
  }
  const res = await (
    await fetch(url, {
      method: 'POST',
      body: formData,
    })
  ).json();
  return res;
}

export async function deleteCatalog(id) {
  const token = authStore.token;
  const url = `${address_server}catalog/delete`;
  let data;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      id,
    }),
  }).then((res) => (data = res.json()));
  return data;
}

export async function createData(fields, files = false) {
  const token = authStore.token;
  const url = `${address_server}catalog/data`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('fields', JSON.stringify(fields));
  if (files) {
    files?.map((file) => {
      file?.files?.map((item) => {
        formData.append(file.key, item, transliterate(item.name));
      });
    });
  }
  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return res.json();
}

export async function updateData(id, fields, files = false, fileKey) {
  const url = `${address_server}catalog/data`;
  const token = authStore.token;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);
  formData.append('fields', JSON.stringify(fields));

  if (files) {
    files?.map((file) => {
      file?.files?.map((item) => {
        formData.append(file.key, item, transliterate(item.name));
      });
    });
  }

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return res.json();
}

export async function getData(catalog, limit = 200, search = '') {
  const token = authStore.token;
  const url = `${address_server}catalog/data?token=${token}&catalog=${catalog}&limit=${limit}&search=${search}`;

  const res = await fetch(url);

  return res.json();
}

export async function deleteDataCatalog(id) {
  const token = authStore.token;
  const url = `${address_server}catalog/data/delete`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return res.json();
}

export async function getMappingCatalog(id_catalog) {
  const token = authStore.token;
  const res_typesField = await getListTypeFields(token);
  const res = await getCatalog(token, id_catalog);
  let catalogFields = res?.fields || [];
  catalogFields = catalogFields.map((item, index) => {
    const type_name = res_typesField[item.type - 1].type_name;
    return {
      ...item,
      type: type_name,
      field: item.id,
    };
  });
  catalogFields = catalogFields.sort(function (a, b) {
    return a.order_field - b.order_field;
  });
  return {
    fields: catalogFields,
    catalog: id_catalog,
  };
}

export async function getTableCatalogs(idGroup, page, sorting, filter) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  if (page != (null && undefined && '')) formData.append('page', page);
  if (filter != null && filter != '') formData.append('search', filter);
  formData.append('catalog', idGroup);
  if (sorting != '') formData.append('sorting', sorting);

  const res = await (
    await fetch(address_server + 'catalog/data', {
      method: 'POST',
      body: formData,
    })
  ).json();

  return res;
}

export async function getMappingData(id_catalog, limit = 20, search = '') {
  const token = authStore.token;
  const resData = await getData(token, id_catalog, 200, search);

  const arrData = resData?.result?.data || [];
  const raws = [];
  for (const col of arrData) {
    const rawValue = {};
    const rowSetting = {};
    for (const field of col?.fields) {
      rawValue[field.id] = field.value;
      rowSetting[field.id] = field.setting;
    }

    raws.push({
      id: col.id,
      value: rawValue,
      setting: rowSetting,
    });
  }

  const itemsByPages = raws.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / limit);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(item);
    return acc;
  }, []);

  return {
    itemsByPages,
    catalog: id_catalog,
    pageAll: resData?.result?.pagination?.pages ?? 1,
    total: resData?.result?.pagination?.total ?? 0,
  };
}
