import ReactDOM from 'react-dom';
import defaultStyle from '../ModalDefaultStyle.module.css';
import Icon from '../../../helpers/components/Icon';
import close from '../../../assets/svg/close-01.svg';
import style from './ModalSetNotification.module.css';
import { COLORS } from '../../../config';
import confirmSVG from '../../../assets/svg/button_on-01.svg';
import { useContext, useEffect, useState } from 'react';
import WidgetDateTimePicker from '../../Widgets/WidgetDateTimePicker';
import {
  getNotificationSettings,
  upsertNotificationSettings,
} from '../../../services/notification.services';
import { modeContext } from '../../Contexts/contexts';
import { observer } from 'mobx-react-lite';

const ModalSetNotification = observer(({ mode, setClose, sampleId }) => {
  const { widget } = useContext(modeContext);
  const [notificId, setNotificId] = useState(null);
  const [countSample, setCountSample] = useState({ show: false, value: 0 });
  const [dateExpireShow, setDateExpireShow] = useState(false);
  const [dateExpire, setDateExpire] = useState();
  const [volume, setVolume] = useState({ show: false, value: 0 });
  const [countDefreeze, setCountDefreeze] = useState({ show: false, value: 0 });
  const [loadValue, setLoadValue] = useState(false);

  useEffect(() => {
    const loadNotific = async () => {
      const res = await getNotificationSettings(sampleId);
      const result = res.result;

      if (result.count != null) setCountSample({ show: true, value: result.count });

      if (result.id != null) setNotificId(result.id);

      if (result.expire != null) {
        setDateExpireShow(true);
        setDateExpire(result.expire);
      }

      if (result.volume != null) setVolume({ show: true, value: result.volume });

      if (result.defreeze != null) setCountDefreeze({ show: true, value: result.defreeze });

      setLoadValue(true);
    };
    loadNotific();
  }, []);

  useEffect(() => {}, [dateExpire]);

  const closeModal = () => {
    setClose(false);
  };

  const saveSatting = async () => {
    const res = await upsertNotificationSettings(
      notificId,
      sampleId,
      countSample.show ? countSample.value : null,
      dateExpireShow ? dateExpire : null,
      volume.show ? volume.value : null,
      countDefreeze.show ? countDefreeze.value : null,
    );
    if (res.success)
      widget.setWidget({
        status: 'Ok',
        text: 'Настройки установлены',
        fun: async () => {
          setClose(false);
        },
      });
  };

  const modal = loadValue && (
    <div className={defaultStyle.modalContainer}>
      <div className={defaultStyle.modal + ' ' + style.modal}>
        <div className={defaultStyle.header}>
          <div className={defaultStyle.closeButton} onClick={closeModal}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={defaultStyle.body}>
          <div className={defaultStyle.title}>Уведомления</div>
          <div className={defaultStyle.bodyRow}>
            <div className={style.item}>
              <div className={style.checkboxContainer}>
                <div
                  className={style.checkbox + ' ' + (true ? style.checkboxSelected : '')}
                  onClick={() => {
                    setCountSample((prevValue) => {
                      return {
                        show: !prevValue.show,
                        value: prevValue.value,
                      };
                    });
                  }}
                >
                  {countSample.show && (
                    <Icon
                      color={COLORS.color_hover2}
                      src={confirmSVG}
                      width={'17px'}
                      height={'17px'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={style.lable}>Общее количество пробирок</div>
          </div>

          <div className={defaultStyle.bodyRow}>
            <div className={style.lableLow}>Оповестить, когда число станет меньше, чем:</div>
            <div className={style.item}>
              <input
                className={style.infoItemValue}
                value={countSample.value}
                type={'number'}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  if (e.target.value >= 0)
                    setCountSample((prevValue) => {
                      return {
                        show: prevValue.show,
                        value: e.target.value,
                      };
                    });
                }}
              />
            </div>
          </div>

          <div className={defaultStyle.bodyRow}>
            <div className={style.item}>
              <div className={style.checkboxContainer}>
                <div
                  className={style.checkbox + ' ' + (true ? style.checkboxSelected : '')}
                  onClick={() => {
                    setDateExpireShow((prevValue) => !prevValue);
                  }}
                >
                  {dateExpireShow && (
                    <Icon
                      color={COLORS.color_hover2}
                      src={confirmSVG}
                      width={'17px'}
                      height={'17px'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={style.lable}>Срок годности</div>
          </div>
          {
            <div className={defaultStyle.bodyRow}>
              <div className={style.lableLow}>Оповестить об истечении срока в указанный день:</div>
              <div className={style.item}>
                <WidgetDateTimePicker
                  selected={dateExpire}
                  onChange={(val) => {
                    setDateExpire(val);
                  }}
                  isDateTime={true}
                />
              </div>
            </div>
          }
          <div className={defaultStyle.bodyRow}>
            <div className={style.item}>
              <div className={style.checkboxContainer}>
                <div
                  className={style.checkbox + ' ' + (true ? style.checkboxSelected : '')}
                  onClick={() => {
                    setVolume((prevValue) => {
                      return {
                        show: !prevValue.show,
                        value: prevValue.value,
                      };
                    });
                  }}
                >
                  {volume.show && (
                    <Icon
                      color={COLORS.color_hover2}
                      src={confirmSVG}
                      width={'17px'}
                      height={'17px'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={style.lable}>Общее количество образца</div>
          </div>
          {
            <div className={defaultStyle.bodyRow}>
              <div className={style.lableLow}>
                Оповестить, когда количество образца в пробирках станет меньше, чем:
              </div>
              <div className={style.item}>
                <input
                  className={style.infoItemValue}
                  value={volume.value}
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    if (e.target.value >= 0)
                      setVolume((prevValue) => {
                        return { show: prevValue.show, value: e.target.value };
                      });
                  }}
                />
              </div>
            </div>
          }
          <div className={defaultStyle.bodyRow}>
            <div className={style.item}>
              <div className={style.checkboxContainer}>
                <div
                  className={style.checkbox + ' ' + (true ? style.checkboxSelected : '')}
                  onClick={() => {
                    setCountDefreeze((prevValue) => {
                      return {
                        show: !prevValue.show,
                        value: prevValue.value,
                      };
                    });
                  }}
                >
                  {countDefreeze.show && (
                    <Icon
                      color={COLORS.color_hover2}
                      src={confirmSVG}
                      width={'17px'}
                      height={'17px'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={style.lable}>Количество циклов разморозки-заморозки</div>
          </div>
          {
            <div className={defaultStyle.bodyRow}>
              <div className={style.lableLow}>
                Оповестить, когда число разморозок образца станет меньше, чем:
              </div>
              <div className={style.item}>
                <input
                  className={style.infoItemValue}
                  value={countDefreeze.value}
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    if (e.target.value >= 0)
                      setCountDefreeze((prevValue) => {
                        return { show: prevValue.show, value: e.target.value };
                      });
                  }}
                />
              </div>
            </div>
          }
        </div>
        <div className={defaultStyle.footer}>
          <button
            className={`button_default save_style`}
            onClick={async () => {
              await saveSatting();
            }}
          >
            Сохранить
          </button>
          <button className={`button_default cancel_style`} onClick={closeModal}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
});

export default ModalSetNotification;
