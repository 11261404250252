import React from 'react';
import { observer } from 'mobx-react-lite';
import TableRowItem from '../TableRowItem/TableRowItem';
import { getMasItem } from '../../../../utils/struct';
import styles from './tableRow.module.css';

const TableRow = observer(({ struct, rowsItem, selectRowItem, startEditRowItem, onDeleteItem }) => {
  const [massItem, setMassItem] = React.useState([]);

  React.useEffect(() => {
    setMassItem(getMasItem(struct, rowsItem));
  }, [struct, rowsItem]);

  const Table = () => {
    return massItem.map((item) => {
      let it = item;
      return it ? <TableRowItem item={it} key={it.id} /> : it.type;
    });
  };

  return (
    <tr className={styles.workRightTableRow} style={{ cursor: 'pointer' }}>
      <Table />
      <td className={styles.workRightTableItem + ' ' + styles.workRightTableItem_last}>
        <div style={{ display: 'flex' }}>
          <div
            className={styles.workRightTableItem_Button + ' ' + styles.workRightTableItem_show}
            onClick={selectRowItem ? selectRowItem : () => {}}
          ></div>
          {/*<div className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_edit} onClick={startEditRowItem ? startEditRowItem : () => {}}></div>*/}
          <div
            className={
              styles.workRightTableItem_Button + ' ' + styles.workRightTableItem_button_delete
            }
            onClick={onDeleteItem ? onDeleteItem : () => {}}
          ></div>
        </div>
      </td>
    </tr>
  );
});

export default TableRow;
