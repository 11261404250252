import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { modeContext } from '../Contexts/contexts';
import { StorageStore } from '../../store/StorageStore';
import { address_server_short, COLORS } from '../../config';
import classes from './tree.module.css';
import classes2 from '../WorkRight/WorkRightCommon/WorkRight/workRight.module.css';
import { setFavoriteStorage } from '../../services/storage.services';
import { deletePack, editPack, getBarcodes } from '../../services/pucks.services';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './tree';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import { PACK_TYPE, Store } from '../../store/Store';
const styleName = [{ padding: '0 0 0 0' }, {}];
const styleVisibleSVG = [{ height: '16px' }, { height: '0' }];
const styleBlock = [{}, { height: '0', width: '0', opacity: '0' }];

const TreeItemPack = observer(({ path, mode, value, visibleTop, setWidget }) => {
  const store = useContext(Store);
  const [rowsButton, setRowsButton] = React.useState({
    print: false,
    copy: false,
    edit: true,
    delete: true,
    favorite: true,
  });

  React.useEffect(() => {
    const rules = mode.rules ?? {};
    setRowsButton({
      print: false,
      copy: false,
      edit: rules?.storage?.upsert,
      delete: rules?.storage?.delete,
      favorite: rules?.storage?.upsert_favorites,
    });
  }, [mode]);

  const { widget } = useContext(modeContext);
  const modeMain = useContext(modeContext);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(value.name);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [points, setPoints] = useState({ top: 0, left: 0 });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.PACK_TYPE,
    item: { id: value.id_storage_pack ?? value.id, name: value.name, type: ItemTypes.PACK_TYPE },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
  }));

  useEffect(() => {
    mode.setIsDragging(isDragging);
  }, [isDragging]);

  const [isHover, setIsHover] = useState(false);
  const styleHoverBlock = {
    color: isHover ? COLORS.color_brand : COLORS.text_primary,
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      editOpen();
      setEditMode(false);
    }
  };

  const editOpen = async () => {
    let oldPack = await getBarcodes(value.id_storage_pack ?? value.id);

    let res = await editPack({
      name: name,
      id: value.id_storage_pack ?? value.id,
      type: oldPack.structura.id,
      barcodes: oldPack.barcodes ?? [],
    });
    if (res.success) {
      mode.setType({ mode: 'view', item: '' });
    } else {
      setWidget({
        status: 'Alarm',
        text:
          parseInt(res.error_code) === 2009
            ? 'Нельзя изменить имя не пустого штатива'
            : res.error_code,
        fun: async () => {},
      });
      setName(value.name);
    }
  };

  const [favoriteTemp, setFavoriteTemp] = useState(value.favorite ?? false);

  useEffect(() => {
    const handleClick = (e) => {
      setOpenContextMenu(false);
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const selectTreeNode = async () => {
    mode.setType({
      mode: 'view',
      item: { id: value.id_storage_pack ?? value.id, name: (path + ' / ' + value.name).slice(3) },
    });
    await store.setSelectedTreeNode({
      id: value.id_storage_pack ?? value.id,
      type: PACK_TYPE,
      storageId: value.id_storage ?? 0,
    });
  };

  const openInNewTab = async () => {
    const newTab = {
      name: mode.path + ' / ' + value.name,
      path: mode.path + ' / ' + value.name,
      mode: JSON.parse(JSON.stringify(modeMain)),
      id: new Date().getTime(),
    };

    mode.setTabs([...mode.tabs, newTab]);
    mode.setActiveTabId(newTab.id);
    mode.setVisibleAdd(false);
    await selectTreeNode();
  };

  const active =
    store.selectedTreeNode &&
    store.selectedTreeNode.id === (value.id_storage_pack ?? value.id) &&
    store.selectedTreeNode.type == 'pack';

  return (
    <>
      <div
        ref={!editMode ? drag : undefined}
        onContextMenu={(e) => {
          e.preventDefault();
          setOpenContextMenu(true);
          setPoints({ top: 30, left: 40 });
        }}
        className={classes.Item}
      >
        {openContextMenu && (
          <ContextMenu close={() => setOpenContextMenu(false)} top={points.top} left={points.left}>
            <ContextMenuItem onClick={openInNewTab}>Открыть в новой вкладке</ContextMenuItem>
            <ContextMenuItem
              onClick={async () => {
                widget.setWidget({
                  status: 'Sure',
                  text: 'Вы действительно хотите удалить штатив?',
                  fun: async () => {
                    const res = await deletePack(value.id_storage_pack ?? value.id);
                    if (res.success) {
                      mode.setType({ mode: 'view', item: '' });
                    } else {
                      if (res.error_code === 2008) {
                        widget.setWidget({
                          status: 'Info',
                          text: 'Штатив привязан, либо имеет образцы',
                          fun: async () => {},
                        });
                      }
                    }
                  },
                });
              }}
            >
              Удалить
            </ContextMenuItem>
          </ContextMenu>
        )}
        <div className={classes.ItemName} onClick={selectTreeNode}>
          <img
            className={classes.tripod + ` ${!value.is_empty ? classes.tripodOrange : ''}`}
            style={styleBlock[visibleTop ? 0 : 1]}
            src={address_server_short + 'icons/pack.svg'}
            alt={''}
          />

          {!editMode && (
            <div
              className={classes.ItemNameBlock + ' ' + (active && classes.ItemNameSelect)}
              style={styleName[visibleTop ? 1 : 0]}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              {value.icon && (
                <img
                  alt={''}
                  className={classes.SVG}
                  src={address_server_short + value.icon}
                  style={styleVisibleSVG[visibleTop ? 0 : 1]}
                />
              )}
              <div
                style={styleHoverBlock}
                onClick={() => {
                  setVisible(!visible);
                }}
                className={classes.ItemNameText}
              >
                {name}
              </div>
              {visibleTop && <div className={classes.InvBlock}></div>}
              {visibleTop && (
                <div
                  className={classes.Block}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {rowsButton.favorite && !favoriteTemp && (
                    <div
                      className={
                        classes.Item_Button +
                        ' ' +
                        classes2.workRightTableItem_button_favorite +
                        ' ' +
                        (active && classes.ButtonActive)
                      }
                      onClick={() => {
                        setFavoriteStorage(value.id_storage_pack ?? value.id, 'true');
                        setFavoriteTemp(true);
                      }}
                    ></div>
                  )}
                  {rowsButton.favorite && favoriteTemp && (
                    <div
                      className={
                        classes.Item_Button +
                        ' ' +
                        classes2.workRightTableItem_button_favorite_active +
                        ' ' +
                        (active && classes.ButtonActive)
                      }
                      onClick={() => {
                        setFavoriteStorage(value.id_storage_pack ?? value.id, false);
                        setFavoriteTemp(false);
                      }}
                    ></div>
                  )}
                  {rowsButton.print && (
                    <div
                      className={
                        classes.Item_Button +
                        ' ' +
                        classes2.workRightTableItem_button_print +
                        ' ' +
                        (active && classes.ButtonActive)
                      }
                    ></div>
                  )}
                  {rowsButton.copy && (
                    <div
                      className={
                        classes.Item_Button +
                        ' ' +
                        classes2.workRightTableItem_button_copy +
                        ' ' +
                        (active && classes.ButtonActive)
                      }
                    ></div>
                  )}
                  {rowsButton.edit && (
                    <div
                      className={
                        classes.Item_Button +
                        ' ' +
                        classes2.workRightTableItem_button_edit +
                        ' ' +
                        (active && classes.ButtonActive)
                      }
                      onClick={() => setEditMode(true)}
                    ></div>
                  )}
                  {rowsButton.delete && (
                    <div
                      className={
                        classes.Item_Button +
                        ' ' +
                        classes2.workRightTableItem_button_delete +
                        ' ' +
                        (active && classes.ButtonActive)
                      }
                      onClick={() => {
                        widget.setWidget({
                          status: 'Sure',
                          text: 'Вы действительно хотите удалить штатив?',
                          fun: async () => {
                            const res = await deletePack(value.id_storage_pack ?? value.id);
                            if (res.success) {
                              mode.setType({ mode: 'view', item: '' });
                            } else {
                              if (res.error_code === 2008) {
                                widget.setWidget({
                                  status: 'Info',
                                  text: 'Штатив привязан, либо имеет образцы',
                                  fun: async () => {},
                                });
                              }
                            }
                          },
                        });
                      }}
                    ></div>
                  )}
                </div>
              )}
            </div>
          )}
          {editMode && (
            <div className={classes.ItemNameBlock}>
              <input
                className={classes.infoItemValue}
                type="text"
                value={name}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '50px',
                  alignItems: 'center',
                }}
              >
                <div
                  className={classes.Item_Btn + ' ' + classes2.workRightTableItem_save_delete}
                  onClick={() => {
                    editOpen();
                    setEditMode(false);
                  }}
                ></div>
                <div
                  className={classes.Item_Btn + ' ' + classes2.workRightTableItem_cancel_delete}
                  onClick={() => {
                    setName(value.name);
                    setEditMode(false);
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default TreeItemPack;
