import React, { useRef, useState, useEffect } from 'react';
import close from '../../../assets/svg/close-01.svg';

import Icon from '../../../helpers/components/Icon';
import styles from './modal.module.css';
import dropStyles from '../../WorkRight/WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css';
import arrowSVG from '../../../assets/svg/arrow-01.svg';
import Tree from '../../WorkRight/WorkRightStorageDir/WorkRightStorageAddMenu/Tree/tree';
import { getStorages } from '../../../services/storage.services';
import DropdownComponent from '../Dropdown/DropdownComponent';
import { getListPacks, getListUnits } from '../../../services/list.services';
import { getFreePacks } from '../../../services/pucks.services';
import { Store } from '../../../store/Store';
import { observer } from 'mobx-react-lite';

const ModeMove = 0;
const ModeCopy = 1;

const ModalMoveComponent = observer(({ openModal, mode, action, onClose }) => {
  const { selectedSample, getPath } = React.useContext(Store);
  const [sample, setSample] = React.useState(null);
  const selfRef = React.useRef(null);
  const modalBGRef = React.useRef(null);

  const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = React.useState(false);

  const [maxValue, setMaxValue] = React.useState(selectedSample?.volume);
  const [path, setPath] = React.useState('');

  const [treeLocation, setTreeLocation] = React.useState([]);
  const [packs, setPacks] = React.useState([]);
  const [selectedPack, setSelectedPack] = React.useState();
  const [unitsList, setUnitsList] = React.useState([]);
  const [selectedUnit, setSelectedUnit] = React.useState();

  React.useEffect(() => {
    setSample(JSON.parse(JSON.stringify(selectedSample)));
  }, [selectedSample]);

  const closeModalTappedOnBg = (ev) => {
    if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
      onClose();
    }
  };

  React.useEffect(() => {
    const loadPacksByStorageId = async () => {
      if (sample?.storage === undefined || sample?.storage === 0) {
        let freePacks = await getFreePacks();
        setPacks(freePacks);
      } else {
        let res = await getListPacks(sample?.storage);
        setPacks(res);
      }
    };
    loadPacksByStorageId();
  }, [sample?.storage]);

  React.useEffect(() => {
    const findedUnit = unitsList.find((i) => i.id === sample?.unit);
    if (findedUnit) {
      setSelectedUnit(findedUnit);
    }
  }, [sample, unitsList]);

  React.useEffect(() => {
    setPath(getPath(sample?.storage));
  }, [sample, treeLocation]);

  React.useEffect(() => {
    let findedPack = packs.find((i) => i.id === sample?.pack);

    if (findedPack) {
      setSelectedPack(findedPack);
    }
  }, [sample, packs]);

  React.useEffect(() => {
    const getSamplesT = async () => {
      setUnitsList(await getListUnits());
      const loc = await getStorages(false, '');
      loc.push({
        id: undefined,
        type: 1,
        name: 'Глобальное хранилище',
        icon: 'icons/location-01.svg',
        color: '#ad1d1d',
        favorite: false,
        level: '0',
        parent: 0,
        nodes: [],
      });
      setTreeLocation(loc);
      setMaxValue(selectedSample?.volume);
    };
    getSamplesT();
  }, []);

  const onCancel = () => {
    onClose();
  };

  const changeField = (key, val) => {
    const data = JSON.parse(JSON.stringify(sample));
    data[key] = val;

    setSample(data);
  };

  const modal = (
    <>
      <div ref={modalBGRef} onClick={(ev) => closeModalTappedOnBg(ev)} className={styles.modalBG}>
        <div className={styles.model_copy} ref={selfRef}>
          <div className={styles.header}>
            <div className={styles.closeButton} onClick={() => onClose()}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.title}>
              {mode === ModeCopy ? 'Разделение образца' : 'Перемещение образца'}
            </div>
            <div className={dropStyles.infoItem} style={{ marginBottom: '0px' }}>
              <p className={dropStyles.infoItemName} style={{ width: '160px' }}>
                Расположение
              </p>
              <div className={dropStyles.infoItemInput}>
                <textarea
                  className={dropStyles.infoItemValue}
                  value={path}
                  readOnly={true}
                  style={{ resize: 'none' }}
                  onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}
                />
                <button
                  className={dropStyles.infoItemArrow}
                  onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}
                >
                  <img src={arrowSVG} alt="" />
                </button>
                {isVisiblePositionDropdown && (
                  <>
                    <div
                      className={dropStyles.DropdownBlockAll}
                      onClick={() => setIsVisiblePositionDropdown(false)}
                    ></div>
                    <div className={dropStyles.TreeBlock}>
                      <Tree
                        storages={treeLocation}
                        setVisibleTreeLocation={setIsVisiblePositionDropdown}
                        setLocation={(val) => changeField('storage', val.id)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={dropStyles.infoItem} style={{ marginBottom: '0px' }}>
              <p className={dropStyles.infoItemName} style={{ width: '150px' }}>
                Штатив
              </p>
              <div style={{ width: '68%' }}>
                <DropdownComponent
                  key={'packs_1'}
                  items={packs}
                  selectedKey={sample?.pack}
                  onChange={(val) => {
                    changeField('pack', val);
                  }}
                />
              </div>
            </div>

            <div className={dropStyles.infoItem} style={{ marginBottom: '0px' }}>
              <p className={dropStyles.infoItemName} style={{ width: '150px' }}>
                Место хранения
              </p>
              <div style={{ width: '200px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <DropdownComponent
                  key={'line_1'}
                  items={
                    selectedPack?.structura?.lines &&
                    selectedPack?.structura?.lines.map((i) => ({ id: i, name: i }))
                  }
                  selectedKey={sample?.line}
                  disabled={!sample?.pack}
                  onChange={(val) => changeField('line', val)}
                />
                <DropdownComponent
                  key={'column_1'}
                  items={
                    selectedPack?.structura?.columns &&
                    selectedPack?.structura?.columns.map((i) => ({ id: i, name: i }))
                  }
                  selectedKey={sample?.column}
                  disabled={!sample?.pack}
                  onChange={(val) => changeField('column', val)}
                />
              </div>
            </div>

            {mode === ModeCopy && (
              <div className={dropStyles.infoItem} style={{ marginBottom: '0px' }}>
                <p className={dropStyles.infoItemName} style={{ width: '150px' }}>
                  Количество
                </p>
                <div>
                  <input
                    className={dropStyles.infoItemValue}
                    value={sample?.volume}
                    type={'number'}
                    style={{ width: '100px' }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (Number(newValue) <= Number(maxValue)) {
                        changeField('volume', newValue);
                      }
                    }}
                  />
                  {selectedUnit && selectedUnit.code}
                </div>
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <button className={`button_default save_style`} onClick={() => action(sample)}>
              Сохранить
            </button>
            <button className={`button_default cancel_style`} onClick={onCancel}>
              Отмена
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return openModal ? modal : <></>;
});

export default ModalMoveComponent;

export { ModeCopy };
export { ModeMove };
