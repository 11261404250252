import React, { useEffect, useState } from 'react';

import styles from './SelectCheckParametersStyles.module.css';

import DropdownComponent from '../../../../../Widgets/Dropdown/DropdownComponent';
import TextareaComponent from '../../../../../Widgets/Textarea/TextareaComponent';
import classes from '../../workRightEditManual.module.css';

const mimes = (output) => {
  const list = output.type ?? false;
  if (list) {
    return list.join('\n');
  }
  return list;
};

const Component = ({ parameters, output, setOutput }) => {
  const [selectItems, setSelectItems] = useState(
    mimes(output) !== false ? mimes(output) : '*.pdf\n*.dxf\n*.jpg',
  );

  const [maxSize, setMaxSize] = useState(output ? (output.max_size ?? null) : null);

  useEffect(() => {
    const fillmimes = selectItems.split('\n');

    setOutput({
      type: fillmimes,
      max_size: maxSize,
    });
  }, [selectItems, maxSize]);

  return (
    <>
      <div>
        <div className={`${styles.row} ${styles.rowWithTextarea} `}>
          <TextareaComponent onChange={setSelectItems} defaultValue={selectItems} />
        </div>
        <div className={styles.row}>
          <span>Максимальный размер Мб.</span>
          <input
            className={`${classes.workRightInput} ${styles.sizeInput}`}
            type="number"
            value={maxSize}
            onChange={(ev) => setMaxSize(ev.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default Component;
