import React, { useState } from 'react';
import classes2 from '../../WorkRightCommon/WorkRight/workRight.module.css';
import classes from './workRightUserAddMenu.module.css';

const WorkRightUserAddMenuItemItem = ({ item, setSettings, settings }) => {
  const [value, setValue] = useState(item.value);

  React.useEffect(() => {
    setValue(item.value);
  }, [item]);

  function setSettingValue(v) {
    let s = JSON.parse(JSON.stringify(settings));
    for (let j = 0; j < s.length; j++) {
      for (let i = 0; i < s[j].fields.length; i++) {
        if (s[j].fields[i].id === item.id) {
          s[j].fields[i].value = v;
          break;
        }
      }
    }
    setSettings(s);
  }
  return (
    <div className={classes.workRightAddMenuUserBlock4Block2}>
      <div className={classes.workRightAddMenuUserBlock4Block2Text}>{item.name}</div>
      <label
        className={classes2.workRightSwitch + ' ' + classes.workRightAddMenuUserBlock4BlockInput}
      >
        <input
          type={'checkbox'}
          checked={value}
          onChange={(e) => {
            setValue(e.target.checked);
            setSettingValue(e.target.checked);
          }}
        />
        <span className={classes2.workRightSlider}></span>
      </label>
    </div>
  );
};

export default WorkRightUserAddMenuItemItem;
