import React, { useContext, useState } from 'react';
import WorkLeftManualItem from './workLeftManualItem';
import ModalInfoComponent, { StatusError } from '../../Widgets/ModalInfo/ModalInfoComponent';
import { observer } from 'mobx-react-lite';
import { deleteCatalog } from '../../../services/catalog.services';
import { FieldsStore } from '../../../store/FieldsStore';
import { StructureStore } from '../../../store/StructureStore';
import classes from './workLeftManual.module.css';
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';

const WorkLeftManual = observer(({ setWidget, mode }) => {
  const store = useContext(FieldsStore);
  const structureStore = useContext(StructureStore);
  const rules = mode.rules;

  const [modalOpened, setModalOpened] = useState(false);
  const [rowsButtons, setRowsButtons] = useState({
    print: false,
    copy: true,
    edit: true,
    delete: true,
  });

  useEffect(() => {
    setRowsButtons({
      print: false,
      copy: rules?.catalog?.upsert,
      edit: rules?.catalog?.upsert,
      delete: rules?.catalog?.delete,
    });
  }, [mode]);

  const modalActionOnAccept = () => {
    deleteCatalog(store.deleteStructureId);
    if (store.deleteStructureId === store.activeStructureId) {
      store.clearStructure();
    }
    setModalOpened(false);
    mode.setType({ mode: 'view', item: '' });
  };
  const renderCatalogList = structureStore.catalog.map((rowsItem) => (
    <>
      <WorkLeftManualItem
        key={uuid(rowsItem.id)}
        item={rowsItem}
        rowsButton={rowsButtons}
        setWidget={setWidget}
        mode={mode}
        modalOpen={() => setModalOpened(true)}
      />
    </>
  ));

  return (
    <>
      <div className={classes.WorkLeftTableBlock}>
        <table className={classes.WorkLeftTable}>
          <tbody key={uuid()}>{renderCatalogList}</tbody>
        </table>
        <ModalInfoComponent
          key={uuid()}
          openModal={modalOpened}
          onClose={() => setModalOpened(false)}
          onAccept={modalActionOnAccept}
          title={'Вы уверены?'}
          text={'После удаления структуры её нельзя будет восстановить!'}
          acceptButtonText={'Да'}
          status={StatusError}
        />
      </div>
    </>
  );
});

export default WorkLeftManual;
