import React, { useState } from 'react';
import styles from './DateParametersStyles.module.css';
import classes2 from '../../../../WorkRightCommon/WorkRight/workRight.module.css';

const Component = ({ parameters, output, setOutput }) => {
  const [isTime, setIsTime] = useState(output ? output.time : null);

  const changeSwiper = (time) => {
    setIsTime(time);
    setOutput({
      time,
    });
  };

  return (
    <>
      <div className={styles.row}>
        <span>Время</span>
        <div>
          <label className={classes2.workRightSwitch}>
            <input
              type={'checkbox'}
              checked={isTime}
              onChange={(e) => changeSwiper(e.target.checked)}
            />
            <span className={classes2.workRightSlider}></span>
          </label>
        </div>
      </div>
    </>
  );
};

export default Component;
