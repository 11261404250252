import React from 'react';
import ReactDOM from 'react-dom';
import classes from '../../SampleType/SampleTypeFields/fieldModalStyle.module.css';
import styles from '../../../Widgets/ModalInfo/modal.module.css';
import Icon from '../../../../helpers/components/Icon';
import close from '../../../../assets/svg/close-01.svg';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import modalClasses from './exportModalStyle.module.css';
import WidgetDropdownMultiple from '../../../Widgets/WidgetDropdownMultiple';
import { getSampleTypes } from '../../../../services/sample.services';
import {
  exportTemplate,
  getReportGroups,
  getTemplates,
  upsertTemplates,
} from '../../../../services/report.services';
import { modeContext } from '../../../Contexts/contexts';
import error from '../../../../assets/svg/attention-01.svg';
import FileSaver from 'file-saver';

const ExportModal = ({ setModalOpened, mode, defaultFields, filterSettings }) => {
  const modeMain = React.useContext(modeContext);
  const { setWidget } = modeMain.widget;

  const [isAddNewTemplate, setIsAddNewTemplate] = React.useState(false);
  const [arrayItems, setArrayItems] = React.useState();
  const [arrayItemsAddV, setArrayItemsAddV] = React.useState([...defaultFields]);
  const [nameTemplate, setNameTemplate] = React.useState('');
  const [groups, setGroups] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState();
  const [templates, setTemplates] = React.useState(null);
  const [isClickSave, setIsClickSave] = React.useState(false);
  const [selectedFields, setSelectedFields] = React.useState();
  const [selectedTemplate, setSelectedTemplate] = React.useState();
  const [fullInfoTemplates, setFullInfoTemplates] = React.useState(null);
  const [selectedFieldsByTemplate, setSelectedFieldsByTemplate] = React.useState();
  const [errorSelectedFields, setErrorSelectedFields] = React.useState(null);
  const [accessUpsert, setAccessUpsert] = React.useState(mode?.rules?.report?.add);

  const loadItemsSample = async () => {
    const res = await getSampleTypes();
    setArrayItems([
      ...defaultFields,
      ...res.map((val) => {
        return { id: val.id, name: val.name };
      }),
    ]);
    setArrayItemsAddV([
      ...defaultFields,
      ...res.map((val) => {
        return { id: val.id, name: val.name };
      }),
    ]);
  };

  React.useEffect(() => {
    const loadItems = async () => {
      const res = await getReportGroups();
      setGroups(res.result);
    };
    loadItems();
  }, []);

  React.useEffect(() => {
    loadItemsSample();
    if (selectedTemplate && fullInfoTemplates != undefined) {
      try {
        setSelectedFieldsByTemplate(
          JSON.parse(
            fullInfoTemplates?.find((val) => val.id_report_template == selectedTemplate)?.settings,
          ),
        );
      } catch {
        setSelectedFieldsByTemplate([]);
      }
    } else {
      setArrayItems(null);
    }
  }, [selectedTemplate, fullInfoTemplates]);

  const swichTabs = (groupId = null, templeteId = null) => {
    if (accessUpsert) {
      setIsAddNewTemplate((prevVal) => !prevVal);
      setIsClickSave(false);
      setNameTemplate('');
      if (groupId == null && templeteId == null) {
        setSelectedGroups(null);
        setSelectedFields();
        setSelectedFieldsByTemplate([]);
        setSelectedTemplate(null);
        setTemplates(null);
      } else {
        selectGroup(groupId, templeteId);
      }
    }
  };

  const selectGroup = (idGroup, idTemplate = null) => {
    setSelectedGroups(idGroup);
    if (!isAddNewTemplate || idTemplate != null) {
      const loadItems = async () => {
        const res = await getTemplates(idGroup);
        if (!res.result.templates || res.result.templates.length == 0) {
          setWidget({
            status: 'Alarm',
            text: `Выбранная группа не содержит шаблонов. Создайте новый шаблон или выберите дргую группу.`,
            fun: async () => {},
          });
          setTemplates(null);
        } else {
          setFullInfoTemplates(res.result.templates);
          setTemplates(
            res.result.templates.map((item) => {
              return { id: item.id_report_template, name: item.name };
            }),
          );
          if (idTemplate) setSelectedTemplate(idTemplate);
          else setSelectedTemplate(null);
        }
      };
      loadItems();
    }
  };

  const saveTemplate = () => {
    setIsClickSave(true);
    if (nameTemplate && selectedFields && selectedGroups) {
      const valueArray = selectedFields.items.split(',');
      const idArray = selectedFields.value.split(',');
      const settings = idArray.map((item, index) => {
        return { id: item, value: valueArray[index] };
      });

      if (settings.length == 1 && settings[0].id == '') {
        setErrorSelectedFields(true);
        return;
      }

      const setItems = async () => {
        const res = await upsertTemplates(selectedGroups, nameTemplate, settings);
        if (res.success) {
          setWidget({
            status: 'Ok',
            text: 'Шаблон успешно добавлен!',
            fun: async () => {
              swichTabs(res.result.id_report_group, res.result.id_report_template);
            },
          });
        } else {
          setWidget({
            status: 'Error',
            text: res.error_code,
            fun: async () => {},
          });
        }
      };
      setItems();
    }
  };

  const isClickOnExportTemplate = () => {
    const loadExport = async () => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const res = await exportTemplate(selectedTemplate, filterSettings);
      const data = new Blob([res], { type: fileType });
      FileSaver.saveAs(
        data,
        `${templates?.find((val) => val.id == selectedTemplate).name}` + fileExtension,
      );
    };
    loadExport();
  };

  const modal = (
    <div className={classes.modalContainer}>
      <div className={modalClasses.modal}>
        <div className={styles.header}>
          <div className={styles.closeButton} onClick={() => setModalOpened(false)}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={modalClasses.titleModal}>Экспорт данных</div>
        <div className={modalClasses.selectTemplate + ' ' + modalClasses.swichTabs}>
          <div
            className={
              modalClasses.addNewTemplateBtn +
              ' ' +
              (!isAddNewTemplate ? modalClasses.selectedTab : '')
            }
            onClick={() => swichTabs()}
          >
            Выбрать шаблон
          </div>
          <div
            className={
              modalClasses.addNewTemplateBtn +
              ' ' +
              (isAddNewTemplate ? modalClasses.selectedTab : '') +
              ' ' +
              (!accessUpsert ? modalClasses.disable : '')
            }
            onClick={() => swichTabs()}
          >
            Добавить новый шаблон
          </div>
        </div>

        <div className={modalClasses.selectTemplate}>
          {!isAddNewTemplate ? (
            <>
              <div className={modalClasses.itemBlock}>
                <DropdownComponent
                  keyItem={'id'}
                  label={'Выберите группу...'}
                  valueItem={'name'}
                  selectedKey={selectedGroups}
                  onChange={(e) => {
                    selectGroup(e);
                  }}
                  items={groups}
                />
              </div>
              <div className={modalClasses.itemBlock}>
                <DropdownComponent
                  keyItem={'id'}
                  disabled={!templates ?? true}
                  selectedKey={selectedTemplate}
                  label={'Выберите шаблон для экспорта...'}
                  valueItem={'name'}
                  onChange={(e) => {
                    setSelectedTemplate(e);
                  }}
                  items={templates}
                />
              </div>
              <div className={modalClasses.itemBlock}>
                {arrayItems && (
                  <WidgetDropdownMultiple
                    arrayItems={arrayItems}
                    disabled={true}
                    multiply={true}
                    selectedItems={selectedFieldsByTemplate}
                    itemKey={'id'}
                    itemValue={'name'}
                  />
                )}
              </div>
            </>
          ) : (
            <div className={modalClasses.bodyContainer}>
              <div className={modalClasses.addTemplateContainer + ' ' + modalClasses.itemBlock}>
                <div>Название</div>
                <input
                  type={'text'}
                  className={modalClasses.addTemplateInput}
                  value={nameTemplate}
                  onChange={(e) => {
                    setNameTemplate(e.target.value);
                  }}
                />
                {!nameTemplate && isClickSave && (
                  <div className={modalClasses.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={modalClasses.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
              </div>
              <div className={modalClasses.itemBlock}>
                <DropdownComponent
                  keyItem={'id'}
                  label={'Выберите группу...'}
                  valueItem={'name'}
                  selectedKey={selectedGroups}
                  onChange={(e) => {
                    selectGroup(e);
                  }}
                  items={groups}
                />
                {!selectedGroups && isClickSave && (
                  <div className={modalClasses.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={modalClasses.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
              </div>
              <div className={modalClasses.selectTemplateContainer}>
                <WidgetDropdownMultiple
                  arrayItems={arrayItemsAddV}
                  selectedItems={[]}
                  multiply={true}
                  itemKey={'id'}
                  itemValue={'name'}
                  setOutput={(val) => {
                    setSelectedFields(val);
                  }}
                />
                {(!selectedFields || errorSelectedFields) && isClickSave && (
                  <div className={modalClasses.dataErrorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={modalClasses.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={classes.footer}>
          {isAddNewTemplate && (
            <button
              className={`button_default save_style`}
              onClick={() => {
                saveTemplate();
              }}
            >
              Сохранить
            </button>
          )}
          {!isAddNewTemplate && (
            <button
              className={`button_default save_style`}
              disabled={selectedTemplate ? false : true}
              style={{ marginLeft: '20px' }}
              onClick={() => {
                isClickOnExportTemplate();
              }}
            >
              Экспорт
            </button>
          )}
          <button className={`button_default cancel_style`} onClick={() => setModalOpened(false)}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default ExportModal;
