import React, { createRef, useEffect, useState } from 'react';
import classes from '../../WorkRightManualDir/WorkRightManual/workRightManual.module.css';
import WorkRightHeader from '../../WorkRightCommon/WorkRightHeader/workRightHeader';
import WorkRightSelectPage from '../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage';
import WorkRightTable from '../../WorkRightCommon/WorkRightTable/workRightTable';
import WorkRightUserAddMenu from '../WorkRightUserAddMenu/workRightUserAddMenu';
import { getUsers } from '../../../../services/user.services';

const WorkRightUsers = ({ setWidget, sizeX, mode }) => {
  const tableRef = createRef();
  const [countInPage, setCountInPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pageAll, setPageAll] = useState(0);
  const [countAll, setCountAll] = useState(0);
  const [filter, setFilter] = useState('');
  const [sortField, setSortField] = useState({ type: '', field: '' });
  const [structHeader, setStructHeader] = useState({
    name: true,
    search: true,
    add: true,
    filter: false,
    print: false,
    wrap: true,
  });
  const [rows, setRows] = useState([]);
  const rules = mode?.rules ?? {};
  const [struct, setStruct] = useState([]);

  const [selectItem, setSelectItem] = useState({ id: 0, fields: [], group: mode.type.item.id });
  const [rowsButton, setRowsButton] = useState({
    print: false,
    copy: true,
    edit: true,
    delete: true,
  });
  React.useEffect(() => {
    setStructHeader({
      name: true,
      search: true,
      add: rules?.user?.add,
      filter: false,
      print: false,
      wrap: true,
    });
    setRowsButton({
      print: false,
      copy: false,
      edit: rules?.user?.update,
      delete: rules?.user?.delete,
    });
  }, [mode]);

  useEffect(() => {
    const getListUsers = async () => {
      const res = await getUsers(
        mode.type.item.id,
        page,
        sortField.type != '' ? JSON.stringify(sortField) : '',
        filter,
      );
      setStruct(res.result.table);
      setPageAll(res?.result.pagination?.pages);
      setCountAll(res?.result.pagination?.total);

      const items = res.result;

      let newArray = JSON.parse(JSON.stringify(items.users));

      items?.users?.map((val, index) => {
        for (let key of Object.keys(val)) {
          try {
            const newValue = JSON.parse(val[key]);
            if (newValue == null) newArray[index][key] = '';
            else newArray[index][key] = newValue;
          } catch {
            const newValue = val[key];
            if (newValue == null) newArray[index][key] = '';
            else newArray[index][key] = newValue;
          }
        }
      });

      newArray = newArray?.map((val) => {
        return { value: val, id: val.id_user };
      });

      setRows(newArray);
    };
    getListUsers();
  }, [mode, filter, sortField, page]);

  useEffect(() => {
    setSortField({ type: '', field: '' });
  }, [mode]);

  return (
    <div className={classes.workRightManual}>
      <WorkRightHeader
        setVisibleAdd={mode.setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        filter={filter}
        setFilter={setFilter}
        setSelectItem={setSelectItem}
      />
      <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>
      <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
      <WorkRightTable
        tableRef={tableRef}
        mode={mode}
        struct={struct}
        rows={rows}
        rowsButton={rowsButton}
        setVisibleAdd={mode.setVisibleAdd}
        setSelectItem={setSelectItem}
        setWidget={setWidget}
        sortField={sortField}
        setSortField={setSortField}
      />
      {sizeX === 0 && (
        <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
      )}
      {mode.visibleAdd && (rules?.user?.update || rules?.user?.add) && (
        <WorkRightUserAddMenu
          mode={mode}
          selectItem={selectItem}
          sizeX={sizeX}
          setVisibleAdd={mode.setVisibleAdd}
          setSelectItem={setSelectItem}
          setWidget={setWidget}
        />
      )}
    </div>
  );
};

export default WorkRightUsers;
