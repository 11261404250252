import React from 'react';
import styles from './contextMenu.module.css';

const ContextMenuItem = ({ children, onClick = () => {} }) => {
  return (
    <div className={styles.contextMenuItem} onClick={onClick}>
      {children}
    </div>
  );
};

export default ContextMenuItem;
