const Icon = ({
  src,
  className,
  color,
  alt = 'icon',
  width = '',
  height = '',
  onClick = undefined,
  disable = false,
}) => {
  return (
    <>
      <i
        onClick={onClick}
        className={className}
        disabled={disable}
        style={{
          display: 'block',
          WebkitMaskSize: 'contain',
          maskSize: 'contain',
          WebkitMaskImage: `url(${src})`,
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskPosition: 'center',
          maskImage: `url(${src}) center no-repeat`,
          backgroundSize: 'contain',
          backgroundColor: color ?? 'black',
          width: width,
          height: height,
        }}
      />
    </>
  );
};

export default Icon;
