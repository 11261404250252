import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import defaultStyle from '../ModalDefaultStyle.module.css';
import style from './ModalHistory.module.css';
import Icon from '../../../helpers/components/Icon';
import close from '../../../assets/svg/close-01.svg';
import { getSampleHistory } from '../../../services/sample.services';
import { observer } from 'mobx-react-lite';

const ModalHistory = observer(({ mode, setClose, sample }) => {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    const loadHistory = async () => {
      const res = await getSampleHistory(sample?.id);
      if (res.result.length > 0) setHistory(res.result);
    };
    loadHistory();
  }, []);

  const closeModal = () => {
    setClose(false);
  };

  const modal = (
    <div className={defaultStyle.modalContainer}>
      <div className={defaultStyle.modal + ' ' + style.modal}>
        <div className={defaultStyle.header}>
          <div className={defaultStyle.closeButton} onClick={closeModal}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={defaultStyle.body}>
          <div className={defaultStyle.title}>История изменений</div>
          <div className={defaultStyle.bodyRow}>
            {history != null ? (
              <div className={style.table}>
                <div className={style.header}>
                  <div className={style.itemDate}>Дата</div>
                  <div className={style.itemUser}>Пользователь</div>
                  <div className={style.itemAction}>Действие</div>
                  <div className={style.itemDescription}>Комментарий</div>
                </div>
                <div className={style.body}>
                  {history &&
                    history.map((val, index) => {
                      return (
                        <div className={style.tableRow} key={index}>
                          <div className={style.itemDate}>{val.created_at}</div>
                          <div className={style.itemUser}>{val.user}</div>
                          <div className={style.itemAction}>{val.description}</div>
                          <div className={style.itemDescription}>{val.comment}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>История не найдена</div>
            )}
          </div>
          <div className={defaultStyle.bodyRow}></div>
          <div className={defaultStyle.bodyRow}></div>
        </div>
        <div className={defaultStyle.footer}>
          <button
            className={`button_default cancel_style`}
            style={{ marginLeft: '0' }}
            onClick={closeModal}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
});

export default ModalHistory;
