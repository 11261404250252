import React from 'react';
import classes from './workRightSelectPage.module.css';

const WorkRightSelectPageItem = ({ allCount, item, selectItem, setSelectItem }) => {
  let st = classes.workRightSelectPage_item;
  if (item === selectItem) st = classes.workRightSelectPage_item_select;
  return (
    <div
      className={st}
      onClick={() => {
        if (item !== '...' && item !== '... ') setSelectItem(item);
      }}
    >
      {item}
    </div>
  );
};

export default WorkRightSelectPageItem;
