import style from './TreeSampleChildrenItem.module.css';
import Icon from '../../../../helpers/components/Icon';
import { ArrowDownIcon } from '../../../../helpers/icons';
import { COLORS } from '../../../../config';
import { useState, useContext } from 'react';
import classes from '../../tree.module.css';
import { ChildrenSampleStore } from '../../../../store/ChildrenSampleStore';
import { observer } from 'mobx-react-lite';

const TreeSampleChildrenItem = observer(({ child, width, first, last }) => {
  const sample = useContext(ChildrenSampleStore);

  const [isOpenChildren, setIsOpenChildren] = useState(true);

  const styleBlock = [{}, { height: '0', width: '0', opacity: '0' }];

  return (
    <div className={style.modalContainer} style={{ marginLeft: first ? '0px' : '30px' }}>
      {!first && <div className={classes.Line} style={styleBlock[true ? 0 : 1]}></div>}
      {!last && <div className={classes.Line2} style={styleBlock[true ? 0 : 1]}></div>}
      <div
        className={
          style.container + ' ' + (sample.selectedTreeSample == child.id ? style.activeSample : '')
        }
      >
        <div className={style.nameContainer}>
          {child?.childs?.length > 0 && (
            <div className={style.arrow}>
              <Icon
                src={ArrowDownIcon}
                color={COLORS.color_hover}
                width={12}
                height={7}
                className={style.arrowRight + ' ' + (isOpenChildren ? style.arrowDown : '')}
                onClick={() => setIsOpenChildren((prevValue) => !prevValue)}
              />
            </div>
          )}
          <div
            className={style.name}
            onClick={async () => await sample.setSelectedTreeSample(child.id)}
          >
            {`ID образца(${child.type}): ` + child.name}
          </div>
        </div>
      </div>
      {isOpenChildren && (
        <div>
          {child?.childs?.map((val, index, arr) => {
            return (
              <div key={index}>
                <TreeSampleChildrenItem
                  first={false}
                  last={index === arr.length - 1}
                  child={val}
                  width={width + 30}
                ></TreeSampleChildrenItem>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default TreeSampleChildrenItem;
