import { createContext } from 'react';
import { makeAutoObservable, observable } from 'mobx';
import { getCookie, setCookie } from '../../../utils/fun_cookie';

class AppStore {
  token = '';

  constructor() {
    makeAutoObservable(this, {
      token: observable,
    });
  }

  setToke = (token) => {
    this.token = token;
    setCookie('admin-token', this.token);
  };

  removeToken = () => {
    this.token = '';
    setCookie('admin-token', '');
  };

  getToken = () => {
    const token = getCookie('admin-token');
    if (token) {
      this.token = token;
    }
  };
}

export const MainStore = createContext(new AppStore());
