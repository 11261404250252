import { address_server } from '../config';
import { error } from '../utils/handlerError';
import { authStore } from 'store/auth.store';

export async function getStorages(favorite, search) {
  const token = authStore.token;
  let url = address_server + 'storage/storages?token=' + token + '&favorites=' + favorite;
  if (search !== '') url += '&search=' + search;
  const res = await (await fetch(url)).json();
  if (res.success) {
    return res.result;
  }
  error('getStorages', res);
  return [];
}

export async function getStorage(id) {
  const token = authStore.token;
  const res = await (await fetch(address_server + 'storage?token=' + token + '&id=' + id)).json();
  if (res.success) {
    return res.result;
  }
  error('getStorage', res);
  return [];
}

export async function getStoragePacks(id) {
  const token = authStore.token;
  if (!id) return [];
  const res = await (
    await fetch(address_server + 'storage/packs?token=' + token + '&storage=' + id)
  ).json();
  if (res.success) {
    return res.result;
  }
  error('getStorage', res);
  return [];
}

export async function deleteStorage(id) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'storage/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, id: id }),
    })
  ).json();

  return res;
}

export async function setFavoriteStorage(id, favorite) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'storage/favorite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, id: id, favorite: favorite }),
    })
  ).json();
  if (res.success) {
    return res;
  }
  error('setFavoriteStorage', res);
  return [];
}

export async function editStorage(id, params) {
  const token = authStore.token;
  const formData = new FormData();

  formData.append('token', token);
  formData.append('name', params.name);
  formData.append('type', params.type);

  if (params.location.parent !== undefined)
    formData.append('location', JSON.stringify(params.location.parent));
  else formData.append('location', JSON.stringify(params.location));

  if (params.barcodes.length > 0) formData.append('barcodes', JSON.stringify(params.barcodes));

  formData.append('packs', JSON.stringify(params.packs));

  if (params.icon !== null && params.icon.id !== undefined) formData.append('icon', params.icon.id);
  else formData.append('icon', params.icon ? params.icon : '');

  if (params.color?.length > 0) formData.append('color', params.color);

  if (params.copy) {
    formData.append('copy', params.copy);
  }

  if (params?.comments?.length > 0)
    formData.append('description', params.comments ? params.comments : '');

  let res;
  if (id !== 0) {
    formData.append('id', id);
    res = await (
      await fetch(address_server + 'storage', {
        method: 'POST',
        body: formData,
      })
    ).json();
  } else {
    res = await (
      await fetch(address_server + 'storage', {
        method: 'POST',
        body: formData,
      })
    ).json();
  }
  return res;
}

export async function storageMove(id, parent) {
  const token = authStore.token;
  const url = `${address_server}storage/move`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);
  formData.append('parent', parent);

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return res.json();
}

export async function packMove(id, storage) {
  const token = authStore.token;
  const url = `${address_server}storage/pack/move`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);
  formData.append('storage', storage);

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return res.json();
}

export async function saveAutoCompleteByStorageId(storageId, structure, packCount, packId) {
  const token = authStore.token;
  const url = `${address_server}storage/auto-create`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('id_parent_storage', storageId);
  formData.append('structure', JSON.stringify(structure));

  if (packCount && packCount > 0) {
    formData.append('packs_count', packCount);
    formData.append('pack_type', packId);
  }

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return res.json();
}
