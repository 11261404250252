import React, { useContext, useEffect, useState } from 'react';
import classes2 from '../../WorkRightCommon/WorkRight/workRight.module.css';
import classes from './workRightAddMenu.module.css';
import WorkRightAddMenuItem from './workRightAddMenuItem';
import { wrapContext } from '../../../Contexts/contexts';
import { createData, updateData } from '../../../../services/catalog.services';

const WorkRightAddMenu = ({
  struct,
  selectItem,
  sizeX,
  setVisibleAdd,
  setSelectItem,
  setWidget,
  mode,
}) => {
  const [height, setHeight] = useState(95);
  const { wrap } = useContext(wrapContext);
  if (struct.length === 0) setVisibleAdd(false);
  //if (selectItem.id === 0 && Object.keys(selectItem.fields).length === 0) setVisibleAdd(false);

  const handlerInsertData = async () => {
    const regexp = /files\[(.*)\]/;
    const fileKeys = Object.keys(selectItem).filter((item) => !!item.match(regexp));

    const files =
      fileKeys?.reduce((acc, item) => {
        acc.push({
          key: item,
          files: selectItem[item],
        });
        return acc;
      }, []) ?? false;

    return await createData(createFields(), files);
  };
  function createFields() {
    const fields = [];
    for (const index in struct) {
      const item = struct[index];
      const value = selectItem.fields[item.id]?.value;
      const setting = selectItem.fields[item.id]?.setting;
      const field = {
        field: item.field,
        value,
        setting,
      };
      fields.push(field);
    }
    return fields;
  }

  const handlerUpdateData = async () => {
    const id = selectItem.id;
    const regexp = /files\[(.*)\]/;
    const fileKeys = Object.keys(selectItem).filter((item) => !!item.match(regexp));

    const files =
      fileKeys?.reduce((acc, item) => {
        acc.push({
          key: item,
          files: selectItem[item],
        });
        return acc;
      }, []) ?? false;

    return await updateData(id, createFields(), files);
  };

  useEffect(() => {
    if (wrap) {
      setHeight(0);
    }
  }, [wrap]);

  const resetSelectItem = () => {
    setVisibleAdd(false);
    setSelectItem({ id: 0, fields: {} });
  };

  return (
    <div
      className={classes.workRightAddMenu}
      style={{
        width: 'calc(100% - 3px - ' + (sizeX !== 0 ? sizeX : -3) + 'px',
        top: height + 100 + 'px',
        position: 'fixed',
        height: 'calc(92% - ' + height + 'px)',
      }}
    >
      <form className={classes.workRightAddMenu_block}>
        <input hidden={true} value={selectItem.id} readOnly={true} />
        <div className={classes.workRightAddMenu_table}>
          {struct.map((item, index) => {
            const handlerInputData = (data) => {
              const newSelectItem = { ...selectItem };
              newSelectItem.fields[item.id] = data;
              setSelectItem(newSelectItem);
            };
            const handlerInputFile = (files) => {
              const newSelectItem = { ...selectItem };
              newSelectItem[`files[${item.id}]`] = files;
              setSelectItem(newSelectItem);
            };
            const values = selectItem.fields?.[item.id] || '';
            return (
              <WorkRightAddMenuItem
                item={item}
                selectItem={values}
                resetSelectItem={resetSelectItem}
                setWidget={setWidget}
                setNewSelectItem={handlerInputData}
                setFiles={handlerInputFile}
                key={index}
              />
            );
          })}
        </div>
        <div className={classes.workRightAddMenu_blockButton}>
          <div
            className={classes2.workRightAddMenu_save}
            onClick={async () => {
              if (selectItem.id === 0) {
                const res = await handlerInsertData();
                if (res?.success) {
                  setWidget({
                    status: 'Ok',
                    text: 'Данные успешно добавлены!',
                    fun: async () => {
                      mode.setVisibleAdd(false);
                      resetSelectItem();
                      mode.setType({ ...mode.type });
                    },
                  });
                } else {
                  setWidget({
                    status: 'Error',
                    text: `${res.error_code}`,
                    fun: async () => {},
                  });
                }
              } else {
                setWidget({
                  status: 'Attention',
                  text: 'Вы действительно хотите изменить данные?',
                  fun: async () => {
                    const res = await handlerUpdateData();
                    if (!res?.success) {
                      setWidget({
                        status: 'Error',
                        text: 'Ошибка добавления!',
                        fun: async () => {},
                      });
                    } else {
                      setWidget({
                        status: 'Ok',
                        text: 'Данные успешно добавлены!',
                        fun: async () => {},
                      });
                      mode.setVisibleAdd(false);
                      resetSelectItem();
                      mode.setType({ ...mode.type });
                    }
                  },
                });
              }
            }}
          >
            Сохранить
          </div>
          <div className={classes2.workRightAddMenu_cancel} onClick={() => resetSelectItem()}>
            Отмена
          </div>
        </div>
      </form>
    </div>
  );
};

export default WorkRightAddMenu;
