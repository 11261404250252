import React from 'react';
import classes from './group-check-box.module.css';

const GroupCheckBox = ({ title, values, onChange, type = '' }) => {
  const [stage, setStage] = React.useState(''); // 'UncheckedAll', 'CheckedAll', 'CheckedPart'

  React.useEffect(() => {
    setStage('CheckedPart');

    if (values.filter((item) => item === true).length === values.length) {
      setStage('CheckedAll');
    }

    if (values.filter((item) => item === false).length === values.length) {
      setStage('UncheckedAll');
    }
  }, [values]);

  React.useEffect(() => {
    onChange(title, stage);
  }, [stage]);

  const onChangeValue = () => {
    if (stage === 'UncheckedAll' || stage === 'CheckedPart') {
      setStage('CheckedAll');
    }
    if (stage === 'CheckedAll') {
      setStage('UncheckedAll');
    }
  };

  return (
    <div className={classes.checkbox_container}>
      <div className={classes.square} onClick={onChangeValue}>
        {stage === 'UncheckedAll' && <div className={classes.default}></div>}
        {stage === 'CheckedAll' && <div className={classes.full}></div>}
        {stage === 'CheckedPart' && <div className={classes.part}></div>}
      </div>

      {type === '' && <div className={classes.title}>{title}</div>}
    </div>
  );
};

export default GroupCheckBox;
