import React, { useState, useContext, useEffect } from 'react';
import classes from './workRightTable.module.css';
import user_logo from '../../../../assets/png/user_logo.png';
import { address_server_short, COLORS } from '../../../../config';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { SamplesStore } from '../../../../store/SamplesStore';
import { StorageStore } from '../../../../store/StorageStore';
import Progress from 'rsuite/Progress';
import { getListBarcodes } from '../../../../services/list.services';
import SwitchWidget from '../../../Widgets/Switch';
import styles from '../../../Widgets/Switch/css/SwitchWidget.module.css';
import { StructureStore } from '../../../../store/StructureStore';
import ImagePopupComponent from '../../../Widgets/ImagePopup/ImagePopupComponent';
import { getSample } from '../../../../services/sample.services';
import { Store } from '../../../../store/Store';
import Icon from '../../../../helpers/components/Icon';
import confirmSVG from '../../../../assets/svg/button_on-01.svg';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';

const settingFilter = (setting) => {
  return setting?.length
    ? setting.reduce((acc, item) => {
      acc[item.param] = item.value;
      return acc;
    }, {})
    : setting;
};

const WorkRightTableRowItem = observer(
  ({
    item,
    params,
    rowsItem,
    selectItem,
    selectedItemsToPrint,
    selectedItemsToImport,
    mode,
    fromOther = false,
    imagePopup,
    typePackItems,
    changeTypePack,
    index,
    selectClick,
  }) => {
    const manualStore = useContext(StructureStore);
    const { loadSampleById } = useContext(Store);

    const type = item.type;
    const value = item.value;
    const [barcode, setBarcode] = useState('');
    const newSetting = settingFilter(item.setting);
    const settings = item.settings;

    const newDate = () => new Date(value);

    const onlyDate = () => {
      const { getDay, getMonth, getFullYear } = newDate();
      return `${getDay()} ${getMonth()} ${getFullYear()}`;
    };

    const dateTime = () => {
      const { getHours, getMinutes } = newDate();
      return `${onlyDate()} ${getHours()}:${getMinutes()}`;
    };

    useEffect(() => {
      if (type === 'barcode') {
        const loadBarcodes = async () => {
          if (!manualStore.barcodes.length) {
            await manualStore.setBarcodes();
          }
          setBarcode(manualStore.barcodes?.find((i) => i.id === parseInt(value)));
        };
        loadBarcodes();
      }
    }, []);

    return (
      <td className={classes.workRightTableItem} onClick={imagePopup}>
        {type === 'type_pack' && item.value != undefined && (
          <div className={classes.selectType}>
            <DropdownComponent
              keyItem={'id'}
              valueItem={'name'}
              items={typePackItems}
              selectedKey={value}
              label={'Тип штатива...'}
              onChange={(val) => {
                changeTypePack(val, index);
              }}
            />
          </div>
        )}
        {type === 'selectToPrint' && (
          <div
            className={classes.deviceTrue + ' ' + (true ? classes.deviceTrueSelected : '')}
            onClick={() => {
              selectItem({ id: rowsItem.id, barcode: rowsItem.value.barcode });
            }}
          >
            {selectedItemsToPrint?.find((val) => val.id == rowsItem.id) && (
              <Icon color={COLORS.color_hover2} src={confirmSVG} width={'17px'} height={'17px'} />
            )}
          </div>
        )}
        {type === 'selectToImport' && (
          <div
            className={classes.deviceTrue + ' ' + (true ? classes.deviceTrueSelected : '')}
            onClick={() => {
              selectClick({ id: rowsItem.id });
            }}
          >
            {selectedItemsToImport?.find((val) => val.id == rowsItem.id) && (
              <Icon color={COLORS.color_hover2} src={confirmSVG} width={'17px'} height={'17px'} />
            )}
          </div>
        )}
        {type === 'switch_variants' && (
          <div className={classes.workRightTableItemString}>{value}</div>
        )}
        {type === 'progress' && value !== '' ? (
          <div className={classes.workRightTableItemString}>
            <Progress
              percent={value != undefined ? parseFloat(value) : 0}
              strokeColor={newSetting?.color ?? COLORS.color_brand}
              strokeWidth={22}
              showInfo={newSetting?.percentVisible || false}
            />
          </div>
        ) : (
          ''
        )}
        {type === 'location' && <div className={classes.workRightTableItemString}> {value} </div>}
        {type === 'calc' && <div className={classes.workRightTableItemCount}>{value}</div>}
        {type === 'select_check' && (
          <div className={classes.workRightTableItemCount}>{params?.items?.replace(',', ', ')}</div>
        )}
        {type === 'color' && value !== '' ? (
          <div className={classes.workRightTableItemColor} style={{ backgroundColor: value }}></div>
        ) : (
          ''
        )}
        {type === 'finance' && value !== '' ? (
          <div
            className={classes.workRightTableItemCount}
          >{`${value ? value + ' ' + item?.setting?.postfix : ''}`}</div>
        ) : (
          ''
        )}
        {(type === 'integer' || type === 'numeric' || type === 'range') && (
          <div className={classes.workRightTableItemCount}>{value}</div>
        )}
        {type === 'yes_no' &&
          (value !== '' ? (
            value === 'yes' ? (
              <div style={{ color: 'green' }}>Да </div>
            ) : (
              <div>Нет</div>
            )
          ) : (
            ''
          ))}
        {(type === 'string' ||
          type === 'text' ||
          type === 'email' ||
          type === 'phone' ||
          type === 'base' ||
          type === 'select') &&
          (fromOther ? (
            <div>{value}</div>
          ) : (
            <div className={classes.workRightTableItemString}> {value?.text ?? value} </div>
          ))}
        {type === 'url' &&
          (fromOther ? (
            <div>{value}</div>
          ) : (
            <div className={classes.workRightTableItemString}>
              <a href={value?.text ?? value} target={'_blank'} rel="noreferrer">
                {' '}
                {value?.text ?? value}{' '}
              </a>
            </div>
          ))}
        {type === 'file' &&
          (Array.isArray(params) ? (
            params?.map((file) => {
              return (
                <div className={classes.workRightTableItemString}>
                  <a href={`${address_server_short}${file}`} target={'_blank'} rel="noreferrer">
                    {' '}
                    {file.split('/').pop()}{' '}
                  </a>
                </div>
              );
            })
          ) : !!value && value != '' && typeof value == 'string' ? (
            <div className={classes.workRightTableItemString}>
              <a href={`${address_server_short}${value}`} target={'_blank'} rel="noreferrer">
                {' '}
                {value.split('/').pop()}{' '}
              </a>
            </div>
          ) : (
            ''
          ))}
        {type === 'float' && <div>{value && value.value ? value.value : ''}</div>}
        {type === 'status' && (
          <div>
            {item.value == 'archived'
              ? 'Утилизирован'
              : item.value == 'packed'
                ? 'В хранилище'
                : 'Извлечен'}
          </div>
        )}
        {type === 'Дата' && (settings.time ? <div>{dateTime()}</div> : <div>{onlyDate()}</div>)}
        {(type === 'date' || type === 'time' || type === 'period') && <div>{value}</div>}
        {type === 'barcode' && params?.value ? (
          <div>
            ({barcode?.name}) {params?.value}
          </div>
        ) : (
          ''
        )}
        {type === 'switch' && value !== '' ? (
          <div>
            <label className={styles.switch}>
              <input
                type={'checkbox'}
                checked={value === 'true' || value === true}
                disabled={true}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        ) : (
          ''
        )}
        {type === 'image' &&
          (Array.isArray(params) ? (
            params?.map((imagePath) => {
              return (
                <img
                  src={address_server_short + imagePath}
                  alt={'img'}
                  className={classes.workRightTable_Image}
                />
              );
            })
          ) : value ? (
            <img
              src={address_server_short + value}
              alt={'img'}
              className={classes.workRightTable_Image}
            />
          ) : (
            ''
          ))}
        {type === 'icon' &&
          (rowsItem?.setting && rowsItem?.setting[item.name]?.file ? (
            <img
              src={address_server_short + rowsItem?.setting[item.name]?.file}
              alt={`${rowsItem?.setting[item.name]?.file}`}
              className={classes.workRightTable_icon}
            />
          ) : (
            ''
          ))}
        {type === 'icon' &&
          (rowsItem?.value[`user_field[${item.name}]`]?.setting?.file ? (
            <img
              src={
                address_server_short + rowsItem?.value[`user_field[${item.name}]`]?.setting?.file
              }
              alt={`${rowsItem?.setting[item.name]?.file}`}
              className={classes.workRightTable_icon}
            />
          ) : (
            ''
          ))}
        {type === 'array' && (
          <div className={classes.workRightTableItemArray}>
            {value?.map((i) => (
              <div key={i.name}>{i.name}</div>
            ))}
          </div>
        )}
        {type === 'sample' && (
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            onClick={async (e) => {
              e.stopPropagation();
              await loadSampleById(rowsItem.id);
              mode.setVisibleView(true);
              mode.setVisibleAdd(false);
            }}
          >
            <div className={classes.workRightTableItemSampleImg}>
              <img
                className={classes.containerImg}
                src={address_server_short + '' + rowsItem?.setting?.sample?.icon}
                alt={'icon'}
              ></img>
            </div>
          </div>
        )}
        {type === 'is_active' && item.name == 'name' && (
          <div style={{ textDecoration: !rowsItem.value.is_active ? 'line-through' : 'none' }}>
            {' '}
            {rowsItem.value.name}{' '}
          </div>
        )}
        {type === 'link' && (item.name == 'sources_count' || item.name == 'samples_count') && (
          <div style={rowsItem.value[item.name] ? {
            display: 'inline-block',
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'blue',
          } : undefined}>
            {' '}
            {rowsItem.value[item.name]}{' '}
          </div>
        )}
      </td>
    );
  },
);

export default WorkRightTableRowItem;
