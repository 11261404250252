import React, { useContext, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import classes from './AddSampleSourceModal.module.css';
import styles from '../../../../Widgets/ModalInfo/modal.module.css';
import baseClasses from '../../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css';
import dropStyles from '../../../WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css';

import Icon from '../../../../../helpers/components/Icon';
import error from '../../../../../assets/svg/attention-01.svg';
import close from '../../../../../assets/svg/close-01.svg';

import DropdownComponent from '../../../../Widgets/Dropdown/DropdownComponent';
import WorkRightAddMenuItem from '../../../WorkRightManualDir/WorkRightAddMenu/workRightAddMenuItem';

import { modeContext } from '../../../../Contexts/contexts';

import { SampleSourceStore } from '../../../../../store/SampleSourceStore';
import {
  createSampleSource,
  updateSampleSource,
} from '../../../../../services/sampleSource.services';

const AddSampleSourceModal = ({
  setModalOpened,
  mode,
  typesSampleSource,
  setIsAddSuccess,
  isAddSuccess,
}) => {
  const mainMode = React.useContext(modeContext);
  const { sampleSourceSelected, setSampleSourceField, clearSampleSourceField } =
    React.useContext(SampleSourceStore);

  const { setWidget } = mainMode.widget;

  const [field, setField] = React.useState({
    name: sampleSourceSelected.name ?? '',
    description: sampleSourceSelected.description ?? '',
    user_fields: [],
  });
  const [typesSources, setTypesSources] = React.useState(typesSampleSource);
  const [selectedTypeId, setSelectedTypeId] = React.useState(sampleSourceSelected.id_type);
  const [selectedSourceType, setSelectedSourceType] = React.useState(
    typesSources.find((item) => item.id == sampleSourceSelected.id_type),
  );
  const [newUserFields, setNewUserFields] = useState({ id: 0, fields: {} });
  const [isClickAdd, setIsClickAdd] = React.useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  React.useEffect(() => {
    if (Array.isArray(sampleSourceSelected?.user_fields)) {
      const newSelectItem = { ...newUserFields };

      sampleSourceSelected.user_fields.map((val) => {
        if (val.type.type_name == 'file')
          newSelectItem.fields[val.id] = {
            value: [],
            setting: JSON.parse(val.setting),
            files: val.value,
          };
        else if (val.type.type_name == 'image')
          newSelectItem.fields[val.id] = {
            value: [],
            setting: JSON.parse(val.setting),
            files: val.value,
          };
        else {
          try {
            newSelectItem.fields[val.id] = JSON.parse(val.value);
          } catch {
            newSelectItem.fields[val.id] = val.value;
          }
        }
      });
      setNewUserFields(newSelectItem);
    }
  }, []);

  React.useEffect(() => {
    if (selectedTypeId && !sampleSourceSelected.id) {
      setField({
        name: '',
        description: '',
        user_fields: [],
      });
      setNewUserFields({ id: 0, fields: {} });
      clearSampleSourceField();
    }
  }, [selectedTypeId]);

  const setNewField = (key, value) => {
    const obj = JSON.parse(JSON.stringify(field));
    obj[key] = value;
    setField(obj);
    setSampleSourceField(key, value);
  };

  const addClick = () => {
    setIsClickAdd(true);

    if (!sampleSourceSelected.name) return;
    if (sampleSourceSelected.id != null)
      return setWidget({
        status: 'Attention',
        text: `Данные типа источника будут изменены`,
        fun: async () => {
          save();
        },
      });

    save();
  };

  const save = () => {
    const sampleSourceUpsertAsync = async () => {
      let res;
      const user_fields = configureUserFields();
      if (sampleSourceSelected.id != null) {
        res = await updateSampleSource(sampleSourceSelected.id, {
          ...sampleSourceSelected,
          user_fields: user_fields,
          type: selectedSourceType.id,
        });
      } else {
        res = await createSampleSource({
          ...sampleSourceSelected,
          user_fields: user_fields,
          type: selectedSourceType.id,
        });
      }

      if (!res.success) {
        errorHandler(res.error_code);
      } else {
        setWidget({
          status: 'Ok',
          text: sampleSourceSelected.id
            ? `Источник образца отредактирован успешно!`
            : `Источник образца создан успешно!`,
          fun: async () => {
            setIsAddSuccess(!isAddSuccess);
          },
        });
        setModalOpened(false);
      }
    };
    sampleSourceUpsertAsync();
  };

  const cancelClick = () => {
    setModalOpened(false);
    clearSampleSourceField();
  };

  const errorHandler = (code) => {
    setWidget({
      status: 'Error',
      text: ` Код № ${code}.`,
      fun: async () => {},
    });
  };

  const resetSelectItem = () => {
    setModalOpened(false);
    setNewUserFields({ id: 0, fields: {} });
  };

  const configureUserFields = () => {
    if (newUserFields.fields) {
      const user_fields = [];
      selectedSourceType.user_fields.forEach((item) => {
        user_fields.push({ id_field: item.id_field, value: '' });
      });

      for (let key in newUserFields.fields) {
        user_fields.map((item) => {
          if (item.id_field == key) {
            if (newUserFields[key]?.files) {
              return (item.value = newUserFields.fields[key].files.filter(
                (val) => typeof val === 'string',
              ));
            } else {
              return (item.value = JSON.stringify(newUserFields.fields[key]));
            }
          }
          return item;
        });
      }
      return user_fields;
    }
    return [];
  };

  const modal = (
    <div className={classes.modalContainer}>
      <div className={classes.modal}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            {sampleSourceSelected.id == null ? 'Добавить' : 'Редактировать'} источник образца
          </div>
          <div className={styles.closeButton} onClick={() => cancelClick()}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={classes.modalBody}>
          {
            <div className={classes.infoItem}>
              <p className={dropStyles.infoItemName}>Тип</p>
              <DropdownComponent
                className={classes.typeDropdown}
                keyItem={'id'}
                valueItem={'name'}
                selectedKey={selectedTypeId}
                onChange={(e) => {
                  setSelectedSourceType(typesSources.find((item) => item.id == e));
                  setSelectedTypeId(e);
                }}
                items={typesSources}
                disabled={sampleSourceSelected.id}
              />
            </div>
          }
          {!selectedSourceType && isClickAdd && (
            <div className={baseClasses.dataErrorBlock}>
              <img src={error} alt={'error'} />
              <div className={baseClasses.dataError}>Поле обязательно для заполнения</div>
            </div>
          )}
          {selectedSourceType && (
            <>
              <p className={classes.categoryTitle}>Стандартные поля</p>
              <div className={dropStyles.infoItem + ' ' + classes.standartFieldContainerFirst}>
                <p className={dropStyles.infoItemName}>Название</p>
                <div className={dropStyles.infoItemInput}>
                  <input
                    className={dropStyles.infoItemValue}
                    value={field?.name ?? ''}
                    onChange={(e) => setNewField('name', e.target.value)}
                  />
                </div>
              </div>
              {!sampleSourceSelected?.name?.trim() && isClickAdd && (
                <div className={baseClasses.dataErrorBlock}>
                  <img src={error} alt={'error'} />
                  <div className={baseClasses.dataError}>Поле обязательно для заполнения</div>
                </div>
              )}
              <div className={dropStyles.infoItem + ' ' + classes.standartFieldContainer}>
                <p className={dropStyles.infoItemName}>Описание</p>
                <div className={dropStyles.infoItemInput}>
                  <input
                    className={dropStyles.infoItemValue}
                    value={field?.description ?? ''}
                    onChange={(e) => setNewField('description', e.target.value)}
                  />
                </div>
              </div>
              <p className={classes.categoryTitle}>Пользовательские поля</p>
              {selectedSourceType?.user_fields.map((val, index) => {
                const handlerInputData = (data) => {
                  const newSelectItem = { ...newUserFields };
                  newSelectItem.fields[val.id_field] = data;
                  setNewUserFields(newSelectItem);
                };
                const handlerInputFile = (files) => {
                  const newSelectItem = { ...newUserFields };
                  newSelectItem[`files[${val.id_field}]`] = files;
                  setNewUserFields(newSelectItem);
                };

                let value = newUserFields.fields[val.id_field] || '';

                return (
                  <div key={index} className={classes.userFieldContainer}>
                    <WorkRightAddMenuItem
                      item={{
                        type: val.type.type_name,
                        name: val.name,
                        setting: JSON.parse(val.setting),
                        id: val.id_field,
                      }}
                      selectItem={value}
                      resetSelectItem={resetSelectItem}
                      setWidget={setWidget}
                      setNewSelectItem={handlerInputData}
                      setFiles={handlerInputFile}
                      isUserFieldsOperation={true}
                    />
                    {isClickAdd ? (
                      val.required ? (
                        newUserFields.fields[val.id_field] ? (
                          newUserFields.fields[val.id_field].value == '' &&
                          newUserFields.fields[val.id_field].files?.length == 0 &&
                          isClickAdd && (
                            <div
                              className={
                                classes.dataErrorBlock + ' ' + classes.dataErrorBlockDinamyc
                              }
                            >
                              <img src={error} alt={'error'} />
                              <div className={classes.dataError}>
                                Поле обязательно для заполнения
                              </div>
                            </div>
                          )
                        ) : (
                          <div
                            className={classes.dataErrorBlock + ' ' + classes.dataErrorBlockDinamyc}
                          >
                            <img src={error} alt={'error'} />
                            <div className={classes.dataError}>Поле обязательно для заполнения</div>
                          </div>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className={classes.footer}>
          <button className={`button_default save_style`} onClick={() => addClick()}>
            {sampleSourceSelected.id == null ? 'Добавить' : 'Редактировать'}
          </button>
          <button className={`button_default cancel_style`} onClick={() => cancelClick()}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default AddSampleSourceModal;
