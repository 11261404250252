import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { modeContext } from '../../Contexts/contexts';
import closeSVG from '../../../assets/svg/close-01.svg';
import plusSVG from '../../../assets/svg/add-01.svg';
import { address_server_short, COLORS } from '../../../config';
import classes from './sampleType.module.css';
import classesForAction from '../WorkRightStorageDir/WorkRightStorageViewMenu/workRightStorageViewMenu.module.css';
import Icon from '../../../helpers/components/Icon';
import { TypeSampleStore } from '../../../store/TypeSampleStore';
import edit from 'assets/svg/edit-01.svg';
import add from 'assets/svg/add-01.svg';

export const SampleTypeModal = observer(
  ({ mode, setShowEmptyDetail, types, setSampleField, selectedSample, styles }) => {
    const typeSampleStore = React.useContext(TypeSampleStore);

    const modeMain = React.useContext(modeContext);
    const { setWidget } = modeMain.widget;

    const [selectedTypeId, setSelectedTypeId] = React.useState(null);

    const navToSampleType = (modeOpen) => {
      if (modeOpen == 'new') {
        typeSampleStore.clearTypeSampleField();
      }

      let newContext = JSON.parse(JSON.stringify(modeMain));
      const newTab = {
        name: 'Карточка образца',
        path: 'Карточка образца',
        mode: newContext,
        id: new Date().getTime(),
      };

      localStorage.setItem('active_tabs', newTab.id);
      mode.setTabs([...mode.tabs, newTab]);
      mode.setActiveTabId(newTab.id);
      mode.setVisibleAdd(false);
      mode.setPath('Карточка образца');
    };

    const addClick = () => {
      if (selectedTypeId != null) {
        setShowEmptyDetail(false);
        setSampleField('type', selectedTypeId);
        mode.setVisibleAdd(true);
      }
    };

    const clickOnItem = (id) => {
      if (id == selectedTypeId) {
        addClick();
      } else {
        setSelectedTypeId(id);
      }
    };

    const editTypeSample = async () => {
      await typeSampleStore.setTypeSampleId(selectedTypeId);
      navToSampleType('edit');
    };

    return (
      <>
        <div className={styles.sampleDeatilBox}>
          <button className={styles.closeButton} onClick={() => setShowEmptyDetail(false)}>
            <img src={closeSVG} alt="" />
          </button>
          <div className={classes.titlePlace}>
            Позиция: {selectedSample.line} / {selectedSample.column}
          </div>
          <div className={classes.actionSample}>
            <div className={classes.actionSampleAdd}>
              <div className={'hover_hint_parent'}>
                <div onClick={addClick}>
                  <Icon
                    disable={selectedTypeId != null ? false : true}
                    color={selectedTypeId != null ? COLORS.color_brand : COLORS.bg_inactive}
                    src={add}
                    width={'20px'}
                    height={'20px'}
                  />
                </div>
                <div className={'hover_hint'}>Добавить новый образец</div>
              </div>
            </div>
            <div>
              <div className={'hover_hint_parent'}>
                <div onClick={editTypeSample}>
                  <Icon
                    disable={selectedTypeId != null ? false : true}
                    color={selectedTypeId != null ? COLORS.color_brand : COLORS.bg_inactive}
                    src={edit}
                    width={'20px'}
                    height={'20px'}
                  />
                </div>
                <div className={'hover_hint'}>Редактировать выбранный тип</div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <span>Выберите тип образца: </span>
            <div className={styles.sampleTypescontainer}>
              {types.map((i, index) => (
                <div
                  className={
                    classes.containerMiniImg +
                    ' ' +
                    classes.hoverHintParent +
                    ' ' +
                    (i.id == selectedTypeId ? classes.selectType : '')
                  }
                  key={i.id}
                  onClick={() => {
                    clickOnItem(i.id);
                  }}
                >
                  <img className={classes.itemImg} src={address_server_short + i.icon}></img>
                  <div className={classes.hoverHint}>{i.name}</div>
                </div>
              ))}
            </div>
            <div className={classes.rowAddTypeSample}>
              <div className={classes.rowAddTypeSamplebtn} onClick={() => navToSampleType('new')}>
                Определить новый тип
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
