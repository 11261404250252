import { useContext, useEffect, useRef, useState } from 'react';
import Barcode from 'react-barcode';
import { observer } from 'mobx-react-lite';
import { getStorages } from '../../../../services/storage.services';
import { getFileSample, getSample, getTypeSampleById } from '../../../../services/sample.services';
import { getListPacks, getListUnits, getTypeSamples } from '../../../../services/list.services';
import { getMappingCatalog } from '../../../../services/catalog.services';
import moment from 'moment';
import { getFreePacks } from '../../../../services/pucks.services';
import { address_server_short, COLORS } from '../../../../config';
import Progress from 'rsuite/Progress';
import SwitchWidget from '../../../Widgets/Switch';
import FileSaver from 'file-saver';
import { ChildrenSampleStore } from '../../../../store/ChildrenSampleStore';
import style from './SampleInfo.module.css';

const SampleInfo = observer(({ sizeX, mode, setWidget }) => {
  const { selectedTreeSample } = useContext(ChildrenSampleStore);

  const blockRef = useRef(null);

  const [selectedSample, setSelectedSample] = useState();
  const [treeLocation, setTreeLocation] = useState([]);
  const [types, setTypes] = useState([]);
  const [packs, setPacks] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  const [patientDescriptions, setPatientDescriptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [path, setPath] = useState('');
  const [selectedPack, setSelectedPack] = useState();

  useEffect(() => {
    const loadSample = async () => {
      const res = await getSample(selectedTreeSample, 'view');
      setSelectedSample(res);
    };
    loadSample();
  }, [selectedTreeSample]);

  useEffect(() => {
    const loadPacksByStorageId = async () => {
      if (selectedSample?.storage === undefined || selectedSample?.storage === 0) {
        let freePacks = await getFreePacks();
        setPacks(freePacks);
      } else {
        let res = await getListPacks(selectedSample?.storage);
        setPacks(res);
      }
    };
    loadPacksByStorageId();
  }, [selectedSample]);

  useEffect(() => {
    let findedPack = packs.find((i) => i.id === selectedSample?.locations?.pack?.id);

    if (findedPack) {
      setSelectedPack(findedPack);
    }
  }, [selectedSample, packs]);

  const getSamplesT = async () => {
    setUnitsList(await getListUnits());
    const loc = await getStorages(false, '');
    loc.push({
      id: undefined,
      type: 1,
      name: 'Глобальное хранилище',
      icon: 'icons/location-01.svg',
      color: '#ad1d1d',
      favorite: false,
      level: '0',
      parent: 0,
      nodes: [],
    });
    setTreeLocation(loc);
    setTypes(await getTypeSamples());

    let patient = [];
    if (selectedSample?.patient_description) {
      for (let i = 0; i < selectedSample.patient_description.length; i++) {
        let item = selectedSample.patient_description[i];
        const newCatalog = await getMappingCatalog(item.catalog);
        let fields = item.fields?.map((field) => {
          return {
            id: field.field,
            value: field.value,
            setting: field.setting,
            files:
              field.files.map((i) => {
                return i;
              }) ?? [],
          };
        });
        if (fields)
          patient.push({
            id: item.catalog,
            fields: fields,
            catalog: newCatalog,
          });
      }
      setPatientDescriptions((prevState) => [...prevState, ...patient]);
    }
  };

  useEffect(() => {
    getSamplesT();
  }, []);

  useEffect(() => {
    const findedUnit = unitsList.find((i) => i.id === selectedSample?.unit);
    if (findedUnit) {
      setSelectedUnit(findedUnit);
    }
  }, [selectedSample, unitsList]);

  const getField = (field) => {
    if (field) {
      const elements = [];

      field?.catalog?.fields?.map((i) => {
        let item = field.fields?.find((j) => j.id === i.id);
        if (item) {
          if (Object.keys(item.setting).length > 0) {
            if (item.files?.length > 0) {
              if (i.type == 'file')
                elements.push(
                  <div className={style.info3 + ' ' + style.info_textCont}>
                    <div className={style.nameField}>{i.name}:</div>
                    <div className={style.userTextW}>
                      {item.files.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={style.fileContainer}
                            onClick={() => loadFile(item)}
                          >{` ${item.split('/').pop()}`}</div>
                        );
                      })}
                    </div>
                  </div>,
                );
              else if (i.type == 'image')
                elements.push(
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{i.name}:</div>
                    <div>
                      {item.files?.map((item, index) => {
                        return (
                          <a
                            key={index}
                            target={'_blank'}
                            href={address_server_short + '' + item}
                            rel="noreferrer"
                          >
                            {item.split('/').reverse()[0]},
                          </a>
                        );
                      })}
                    </div>
                  </div>,
                );
            } else if (i.type == 'progress') {
              elements.push(
                <div className={style.info3 + ' ' + style.colorBlockCont}>
                  <div className={style.nameField}>{i.name}:</div>
                  <Progress
                    percent={parseFloat(item.value ?? 0)}
                    strokeColor={COLORS.color_brand}
                    strokeWidth={22}
                    showInfo={item.setting.percent}
                  />
                </div>,
              );
            } else if (i.type == 'select') {
              elements.push(
                <div className={style.info3 + ' ' + style.colorBlockCont}>
                  <div className={style.nameField}>{i.name}:</div>
                  <div className={style.userTextW}>{item.value}</div>
                </div>,
              );
            } else if (i.type == 'icon') {
              elements.push(
                <div className={style.info3 + ' ' + style.colorBlockCont}>
                  <div className={style.nameField}>{i.name}:</div>
                  <div>
                    <img
                      className={style.iconContainer}
                      src={address_server_short + item?.setting?.file}
                    />
                  </div>
                </div>,
              );
            } else if (i.type == 'color') {
              elements.push(
                <div className={style.info3 + ' ' + style.colorBlockCont}>
                  <div className={style.nameField}>{i.name}:</div>
                  <div
                    className={style.colorBlock}
                    style={{ backgroundColor: `${item.value}` }}
                  ></div>
                </div>,
              );
            } else if (i.type == 'location') {
              elements.push(
                <div className={style.info_pacient_block2}>
                  <div className={style.info_pacient_text}>{i.name}</div>
                  <div className={style.info_pacient_text_right}>{item.value}</div>
                </div>,
              );
            } else {
              elements.push(
                <div className={style.info_pacient_block2}>
                  <div className={style.info_pacient_text}>{i.name}</div>
                  <div className={style.info_pacient_text_right}>
                    {item.setting.items ??
                      item.setting.name ??
                      item.setting.value ??
                      item.setting.result ??
                      moment(item.setting.date).format('YYYY-MM-DD') ??
                      item.setting.file ??
                      item.setting.address ??
                      moment(item.setting.from).format('YYYY-MM-DD')}
                    {item.setting.to && ' - ' + moment(item.setting.to).format('YYYY-MM-DD')}
                  </div>
                </div>,
              );
            }
          } else if (i.type == 'yes_no') {
            elements.push(
              <div className={style.info3 + ' ' + style.colorBlockCont}>
                <div className={style.nameField}>{i.name}:</div>
                <div className={style.userTextW}>{item.value == 'yes' ? 'Да' : 'Нет'}</div>
              </div>,
            );
          } else {
            elements.push(
              <div className={style.info_pacient_block2}>
                <div className={style.info_pacient_text}>{i.name}</div>
                <div>{item.value}</div>
              </div>,
            );
          }
        } else {
          elements.push(
            <div className={style.info_pacient_block2}>
              <div className={style.info_pacient_text}>{i.name}</div>
            </div>,
          );
        }
      });
      return elements;
    }
    return <>1</>;
  };

  const barcodesRef = useRef(null);

  const parseValue = (val) => {
    try {
      return JSON.parse(val);
    } catch {
      return val;
    }
  };

  const loadFile = (file) => {
    const fileExtension = file.split('/').pop();
    const loadFileBD = async () => {
      const res = await getFileSample(file);
      const data = new Blob([res]);
      FileSaver.saveAs(data, fileExtension);
    };
    loadFileBD();
  };

  return (
    <div className={style.addMenu_block} ref={blockRef}>
      <div className={style.block1}>
        <div className={style.block1_text}>ID образца: {selectedSample?.id_general}</div>
      </div>
      <div className={style.block2}>
        <div className={style.icon}>
          {selectedSample?.type?.icons && (
            <img
              className={style.iconType}
              src={`${address_server_short}/${selectedSample?.type?.icons}`}
            />
          )}
        </div>
        <div className={style.number}>
          {selectedSample?.name == null || selectedSample?.name == 'null'
            ? ''
            : selectedSample?.name}
        </div>
      </div>
      <div>
        <div className={style.info_text}>Тип образца: {selectedSample?.type?.name ?? ''}</div>
        <div className={style.info_text}>
          Расположение:{' '}
          {selectedSample?.locations?.path == null
            ? 'Глобальное хранилище'
            : selectedSample?.locations?.path}
        </div>
        <div className={style.info_text}>Штатив: {selectedPack?.name ?? ''}</div>
        <div className={style.info_text}>
          Позиция: {selectedSample?.locations?.pack?.line}/{selectedSample?.locations?.pack?.column}
        </div>
        <div className={style.block_barcode} ref={barcodesRef}>
          {selectedSample?.barcodes?.map((barcode) => (
            <div key={barcode.id}>
              <div className={style.block_barcode_text}>{barcode.value}</div>
              <Barcode
                displayValue={false}
                value={barcode.value}
                height={30}
                background={'#DCE6E9'}
              />
            </div>
          ))}
        </div>
        <div className={style.info2_text}>
          Количество образца: {selectedSample?.volume_all?.amount}{' '}
          {selectedSample?.volume_all?.unit_name}
        </div>
        {selectedSample?.aliquot_info && (
          <>
            <div className={style.info2_text}>
              Количество аликвот: {selectedSample?.aliquot_info?.total}
            </div>
            <div className={style.info2_text}>
              Общее количество образца: {selectedSample?.aliquot_info?.volume}{' '}
              {selectedSample?.volume_all?.unit_name}
            </div>
          </>
        )}

        <div className={style.info3}>
          <div className={style.info3_text}>Дата забора: {selectedSample?.date_create}</div>
          <div className={style.info3_text}>Изменен: {selectedSample?.date_update}</div>
          <div className={style.info3_text}>
            Срок годности:{' '}
            {selectedSample?.expire_date ? selectedSample?.expire_date : 'Не указано'}
          </div>
        </div>

        <div style={{ margin: '25px 0' }} className={style.info2_text}>
          Описание: {selectedSample?.description}
        </div>
        <div style={{ margin: '25px 0' }} className={style.info2_text}>
          Разморозок осталось:{' '}
          {selectedSample?.defreeze != null && selectedSample?.defreeze != undefined
            ? selectedSample?.defreeze
            : 'Не указано'}
        </div>
        <div style={{ margin: '25px 0' }} className={style.info2_text}>
          Разморозок выполнено:{' '}
          {selectedSample?.defreeze_done != null && selectedSample?.defreeze_done != undefined
            ? selectedSample?.defreeze_done
            : '0'}
        </div>
        {selectedSample?.user_fields && selectedSample?.user_fields?.length > 0 && (
          <div className={style.separatingRow}>Пользовательские поля</div>
        )}

        <div className={style.userFieldContainer}>
          {selectedSample?.user_fields &&
            selectedSample?.user_fields?.map((val) => {
              if (val.type.type_name == 'finance')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div className={style.userTextW}>
                      {parseValue(val.value).value
                        ? parseValue(val.value).value + ' ' + parseValue(val.value).setting.postfix
                        : ''}
                    </div>
                  </div>
                );
              if (val.type.type_name == 'color')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div
                      className={style.colorBlock}
                      style={{ backgroundColor: `${parseValue(val.value).value}` }}
                    ></div>
                  </div>
                );
              else if (val.type.type_name == 'select_check')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    {parseValue(val.value).setting?.items !== '' ? (
                      <div className={style.userTextW}>
                        {parseValue(val.value).setting?.items?.replace(',', ', ')}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              else if (val.type.type_name == 'switch')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <SwitchWidget
                      disabled={true}
                      selected={parseValue(val.value).value}
                      onChange={() => {}}
                    />
                  </div>
                );
              else if (val.type.type_name == 'float' || val.type.type_name == 'integer')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div className={style.userTextW}>
                      {(parseValue(val.value).value == undefined
                        ? ''
                        : parseValue(val.value).value) +
                        ' ' +
                        (unitsList?.find((value) => value.id == parseValue(val.setting)?.unit)
                          ?.name == undefined
                          ? ''
                          : unitsList?.find((value) => value.id == parseValue(val.setting)?.unit)
                              ?.name)}
                    </div>
                  </div>
                );
              else if (val.type.type_name == 'icon')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div>
                      <img
                        className={style.iconContainer}
                        src={address_server_short + parseValue(val.value)?.setting?.file}
                      />
                    </div>
                  </div>
                );
              else if (val.type.type_name == 'image')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div>
                      {parseValue(val.value) &&
                        parseValue(val.value)?.map((item) => {
                          return (
                            <a
                              target={'_blank'}
                              href={address_server_short + '' + item}
                              rel="noreferrer"
                            >
                              {item.split('/')[2]},
                            </a>
                          );
                        })}
                    </div>
                  </div>
                );
              else if (val.type.type_name == 'yes_no')
                return (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div className={style.userTextW}>
                      {parseValue(val.value).value == 'yes' ? 'Да' : 'Нет'}
                    </div>
                  </div>
                );
              else if (val.type.type_name == 'progress')
                return parseValue(val.value).setting?.value !== '' ? (
                  <div className={style.info3 + ' ' + style.colorBlockCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <Progress
                      percent={parseFloat(parseValue(val.value).value ?? 0)}
                      strokeColor={
                        parseValue(val.setting)?.find((val) => val.param == 'color')?.color ??
                        COLORS.color_brand
                      }
                      strokeWidth={22}
                      showInfo={
                        parseValue(val.setting)?.find((val) => val.param == 'percentVisible')
                          ?.value || false
                      }
                    />
                  </div>
                ) : (
                  ''
                );
              else if (val.value && val.type.type_name == 'file')
                return (
                  <div className={style.info3 + ' ' + style.info_textCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div className={style.userTextW}>
                      {parseValue(val.value) &&
                        parseValue(val.value).map((item, index) => {
                          if (index != JSON.parse(JSON.stringify(val.value)).length - 1)
                            return (
                              <div
                                className={style.fileContainer}
                                onClick={() => loadFile(item)}
                              >{` ${item.split('/').pop()}, `}</div>
                            );
                          else
                            return (
                              <div
                                className={style.fileContainer}
                                onClick={() => loadFile(item)}
                              >{` ${item.split('/').pop()}`}</div>
                            );
                        })}
                    </div>
                  </div>
                );
              else if (val.value && val.type.type_name == 'barcode')
                return (
                  <div className={style.info3 + ' ' + style.info_textCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div className={style.userTextW}>
                      {parseValue(val.value).setting?.barcode}{' '}
                      {parseValue(val.value).setting?.value}
                    </div>
                  </div>
                );
              else if (val.value || val.value == '')
                return (
                  <div className={style.info3 + ' ' + style.info_textCont}>
                    <div className={style.nameField}>{val.name}:</div>
                    <div className={style.userTextW}>{parseValue(val.value).value}</div>
                  </div>
                );
            })}
        </div>
        {patientDescriptions.length > 0 && (
          <>
            <div className={style.separatingRow}>Дополнительная информация</div>
            <div className={style.info_pacient_block}>
              {patientDescriptions.map((i) => (
                <>{getField(i).map((j) => j)}</>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default SampleInfo;
