import React from 'react';
import ReactDOM from 'react-dom';
import classes from '../../SampleType/SampleTypeFields/fieldModalStyle.module.css';
import styles from '../../../Widgets/ModalInfo/modal.module.css';
import Icon from '../../../../helpers/components/Icon';
import close from '../../../../assets/svg/close-01.svg';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent';
import modalClasses from './exportModalStyle.module.css';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './componentToPrint';
import printJS from 'print-js';

const printSizes = [
  { id: 1, name: '30х10' },
  { id: 2, name: '30x20' },
];

const PrintSizeModal = ({ items, setModalOpened, mode }) => {
  const [size, setSize] = React.useState(1);
  const [view, setView] = React.useState(false);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
          @media print {
            .print-only {
              display: block;
            }
            @page {
                size: landscape; /* Ориентация - книжная */
                margin: 0; /* Без полей */
                font-size: 5px;
              }
          }
         
        `,
  });

  const modal = (
    <div className={classes.modalContainer}>
      <div className={modalClasses.modal}>
        <div className={styles.header}>
          <div className={styles.closeButton} onClick={() => setModalOpened(false)}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={modalClasses.selectTemplate}>
          <div className={modalClasses.selectSizeContainer}>
            <div className={modalClasses.lableNameSize}>Выберите размер: </div>
            <DropdownComponent
              keyItem={'id'}
              valueItem={'name'}
              selectedKey={size}
              onChange={(e) => {
                setSize(e);
              }}
              items={printSizes}
            />
          </div>
        </div>
        <ComponentToPrint
          view={view}
          ref={componentRef}
          items={items}
          size={size}
        ></ComponentToPrint>
        <div className={classes.footer}>
          <button
            className={`button_default save_style`}
            onClick={() => {
              setView(true);
              handlePrint();
            }}
          >
            Печать
          </button>
          <button className={`button_default cancel_style`} onClick={() => setModalOpened(false)}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default PrintSizeModal;
