import React, { useEffect, useState } from 'react';

import styles from './SelectCheckParametersStyles.module.css';

import DropdownComponent from '../../../../../Widgets/Dropdown/DropdownComponent';
import TextareaComponent from '../../../../../Widgets/Textarea/TextareaComponent';

const items = (output) => {
  return (
    output !== [] &&
    output.reduce((acc, item) => {
      if (item.param !== 'multiply') {
        acc += `${item.value}\n`;
      }
      return acc;
    }, '')
  );
};
const Component = ({ parameters, output, setOutput }) => {
  const availableSelect = parameters.select_type;

  const [selectItems, setSelectItems] = useState(
    items(output) !== '' ? items(output) : 'Вариант-1\nВариант-2',
  );

  const [selectMultiply, setSelectMultiply] = useState(
    output ? output.find((item) => item.param === 'multiply')?.value : null,
  );

  const selectedItemsChanged = (val) => {
    setSelectItems(val);
  };
  const multiplyChanged = (val) => {
    setSelectMultiply(val);
  };

  useEffect(() => {
    const result = selectItems
      ? [...selectItems.split('\n')].reduce((acc, item, index) => {
          const param = index + 1;
          const value = item;
          if (param && value) {
            acc.push({
              param,
              value,
            });
          }
          return acc;
        }, [])
      : [];
    setOutput([
      ...result,
      {
        param: 'multiply',
        value: selectMultiply,
      },
    ]);
  }, [selectItems, selectMultiply]);

  return (
    <>
      <div>
        <div className={`${styles.row} ${styles.rowWithTextarea} `}>
          <TextareaComponent
            onChange={(val) => selectedItemsChanged(val)}
            defaultValue={selectItems}
          />
        </div>
        <div className={styles.row}>
          <span>Выбор</span>
          <DropdownComponent
            keyItem={'value'}
            valueItem={'name'}
            items={availableSelect}
            selectedKey={selectMultiply}
            onChange={(val) => multiplyChanged(val)}
          />
        </div>
      </div>
    </>
  );
};

export default Component;
