import BarcodeIcon from '../assets/svg/icons/icon_barcode-01.svg';
import LocationIcon from '../assets/svg/icons/icon_location-01.svg';
import ColorIcon from '../assets/svg/icons/icon_color-01.svg';
import DataBaseIcon from '../assets/svg/icons/icon_data_base-01.svg';
import DateIcon from '../assets/svg/icons/icon_date-01.svg';
import DropListIcon from '../assets/svg/icons/icon_drop_list-01.svg';
import EmailIcon from '../assets/svg/icons/icon_email-01.svg';
import FileIcon from '../assets/svg/icons/icon_file-01.svg';
import FilterOneIcon from '../assets/svg/icons/icon_filter-01.svg';
import FilterTwoIcon from '../assets/svg/icons/icon_filter-02.svg';
import FinanceIcon from '../assets/svg/icons/icon_finance-01.svg';
import FormulaOneIcon from '../assets/svg/icons/icon_formula-01.svg';
import FormulaTwoIcon from '../assets/svg/icons/icon_formula-02.svg';
import IconIcon from '../assets/svg/icons/icon_icon-01.svg';
import ImageIcon from '../assets/svg/icons/icon_image-01.svg';
import LineIcon from '../assets/svg/icons/icon_line-01.svg';
import OneIcon from '../assets/svg/icons/icon_one-01.svg';
import OnePointIcon from '../assets/svg/icons/icon_one_point-01.svg';
import PeriodIcon from '../assets/svg/icons/icon_period-01.svg';
import PhoneOneIcon from '../assets/svg/icons/icon_phone-01.svg';
import PhoneTwoIcon from '../assets/svg/icons/icon_phone-02.svg';
import ProgressBarIcon from '../assets/svg/icons/icon_progress_bar-01.svg';
import RadioOneIcon from '../assets/svg/icons/icon_radio-01.svg';
import RadioTwoIcon from '../assets/svg/icons/icon_radio-02.svg';
import SelectionListIcon from '../assets/svg/icons/icon_selection_list-01.svg';
import SwitchIcon from '../assets/svg/icons/icon_switch-01.svg';
import TextIcon from '../assets/svg/icons/icon_text-01.svg';
import TimeIcon from '../assets/svg/icons/icon_time-01.svg';
import WebIcon from '../assets/svg/icons/icon_web-01.svg';
import YesNoIcon from '../assets/svg/icons/icon_yes_no-01.svg';
import RangeIcon from '../assets/svg/icons/icon_range-01.svg';

import ArrowDownIcon from '../assets/svg/arrow-01.svg';
import TriangleDownIcon from '../assets/svg/triangle-01.svg';
import CloseIcon from '../assets/svg/close-01.svg';
import WindowInfoIcon from '../assets/svg/window_info-01.svg';
import AttentionIcon from '../assets/svg/attention-01.svg';
import IconTime from '../assets/svg/icons/icon_time-light-green.svg';
import IconCalendar from '../assets/svg/icons/icon_period-light-green.svg';
import Settings from '../assets/svg/settings-01.svg';
import Search from '../assets/svg/serch-01.svg';

const list = {
  BarcodeIcon,
  LocationIcon,
  ColorIcon,
  DataBaseIcon,
  DateIcon,
  DropListIcon,
  EmailIcon,
  FileIcon,
  FilterOneIcon,
  FilterTwoIcon,
  FinanceIcon,
  FormulaOneIcon,
  FormulaTwoIcon,
  IconIcon,
  ImageIcon,
  LineIcon,
  OneIcon,
  OnePointIcon,
  PeriodIcon,
  PhoneOneIcon,
  PhoneTwoIcon,
  ProgressBarIcon,
  RadioOneIcon,
  RadioTwoIcon,
  SelectionListIcon,
  SwitchIcon,
  TextIcon,
  TimeIcon,
  WebIcon,
  YesNoIcon,
  RangeIcon,
  ArrowDownIcon,
  CloseIcon,
  TriangleDownIcon,
  WindowInfoIcon,
  AttentionIcon,
  IconTime,
  IconCalendar,
  Settings,
  Search,
};
export default list;

export { BarcodeIcon };
export { LocationIcon };
export { ColorIcon };
export { DataBaseIcon };
export { DateIcon };
export { DropListIcon };
export { EmailIcon };
export { FileIcon };
export { FilterOneIcon };
export { FilterTwoIcon };
export { FinanceIcon };
export { FormulaOneIcon };
export { FormulaTwoIcon };
export { IconIcon };
export { ImageIcon };
export { LineIcon };
export { OneIcon };
export { OnePointIcon };
export { PeriodIcon };
export { PhoneOneIcon };
export { PhoneTwoIcon };
export { ProgressBarIcon };
export { RadioOneIcon };
export { RadioTwoIcon };
export { SelectionListIcon };
export { SwitchIcon };
export { TextIcon };
export { TimeIcon };
export { WebIcon };
export { YesNoIcon };
export { RangeIcon };
export { ArrowDownIcon };
export { CloseIcon };
export { TriangleDownIcon };
export { WindowInfoIcon };
export { AttentionIcon };
export { IconTime };
export { IconCalendar };
export { Settings };
export { Search };
