import React, { useEffect, useMemo, useState } from 'react';
import styles from './ImageParametersStyles.module.css';
import classes from '../../workRightEditManual.module.css';

const Component = ({ parameters, output, setOutput }) => {
  const [maxSize, setMaxSize] = useState(output ? output.max_size : 10);
  const [width, setWidth] = useState(output ? output.width : 200);
  const [height, setHeight] = useState(output ? output.height : 350);

  useEffect(() => {
    setOutput({
      max_size: maxSize,
      width: width,
      height: height,
    });
  }, [maxSize, width, height]);

  return (
    <>
      <div>
        <div className={styles.row}>
          <span>Максимальный размер Мб.</span>
          <input
            className={`${classes.workRightInput} ${styles.sizeInput}`}
            type="number"
            value={maxSize}
            onChange={(ev) => setMaxSize(ev.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>Размер</span>

          <input
            className={`${classes.workRightInput} ${styles.sizeInput}`}
            type="number"
            value={width}
            onChange={(ev) => setWidth(ev.target.value)}
          />
          <span> x </span>
          <input
            className={`${classes.workRightInput} ${styles.sizeInput}`}
            type="number"
            value={height}
            onChange={(ev) => setHeight(ev.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default Component;
