import React, { useContext, useEffect, useState } from 'react';
import WorkRightHeader from '../../WorkRightCommon/WorkRightHeader/workRightHeader';
import WorkRightSelectPage from '../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage';
import WorkRightTable from '../../WorkRightCommon/WorkRightTable/workRightTable';
import WorkRightAddMenu from '../WorkRightAddMenu/workRightAddMenu';
import {
  getMappingCatalog,
  getMappingData,
  getTableCatalogs,
} from '../../../../services/catalog.services';
import classes from './workRightManual.module.css';
import { observer } from 'mobx-react-lite';
import { StructureStore } from '../../../../store/StructureStore';
import Preloader from '../../../Widgets/Preloader/Preloader';
import { getTemplates } from '../../../../services/report.services';

const WorkRightManual = observer(({ sizeX, setWidget, mode }) => {
  const structureStore = useContext(StructureStore);
  const rules = mode.rules ?? {};

  const [sortField, setSortField] = useState({ type: '', field: '' });
  const [page, setPage] = useState(1);
  const [pageAll, setPageAll] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectItem, setSelectItem] = useState({ id: 0, fields: {} });
  const [struct, setStruct] = useState([]);
  const [load, setLoad] = useState(false);
  const [filter, setFilter] = useState();

  const [structHeader, setStructHeader] = React.useState({
    name: true,
    search: true,
    add: false,
    filter: true,
    print: false,
    wrap: true,
  });

  const [rowsButton, setRowsButton] = React.useState({
    print: false,
    copy: false,
    edit: false,
    delete: false,
  });

  React.useEffect(() => {
    setStructHeader({
      name: true,
      search: true,
      add: false,
      filter: true,
      print: false,
      wrap: true,
    });

    setRowsButton({
      print: false,
      copy: false,
      edit: false,
      delete: false,
    });
  }, [mode]);

  useEffect(() => {
    getListRep();
  }, [mode.type, filter, sortField, page, mode.visibleAdd]);

  const getListRep = async () => {
    const sortFields = sortField.type != '' ? JSON.stringify(sortField) : '';
    const res = await getTableCatalogs(mode.type.item.id, page, sortFields, filter);
    const items = res.result;
    setStruct(items.table);
    if (items.pagination?.pages) setPageAll(items.pagination?.pages);
    if (items.pagination?.total) setItemsCount(items.pagination.total);

    let newArray = JSON.parse(JSON.stringify(items.data));

    items?.data?.map((val, index) => {
      for (let key of Object.keys(val)) {
        try {
          newArray[index][key] = JSON.parse(val[key]);
        } catch {
          newArray[index][key] = val[key];
        }
      }
    });

    newArray = newArray?.map((val) => {
      return { value: val, id: val.id_catalog_field };
    });

    setRows(newArray);
  };

  return (
    <div className={classes.workRightManual}>
      {load ? <Preloader /> : ''}
      <WorkRightHeader
        setVisibleAdd={mode.setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        setSelectItem={setSelectItem}
        filter={filter}
        setFilter={setFilter}
      />
      <div className={classes.workRightManual_countRows}>Всего найдено: {itemsCount}</div>
      <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
      <WorkRightTable
        mode={mode}
        struct={struct}
        rows={rows}
        rowsButton={rowsButton}
        setVisibleAdd={mode.setVisibleAdd}
        setSelectItem={setSelectItem}
        setWidget={setWidget}
        sortField={sortField}
        setSortField={setSortField}
        fromOther={true}
      />
      {sizeX === 0 && (
        <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
      )}

      {mode.visibleAdd && rules?.catalog?.upsert_data && (
        <WorkRightAddMenu
          struct={struct}
          sizeX={640}
          selectItem={selectItem}
          setSelectItem={setSelectItem}
          setVisibleAdd={mode.setVisibleAdd}
          setWidget={setWidget}
          mode={mode}
        />
      )}
    </div>
  );
});

export default WorkRightManual;
