import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './header.module.css';
import { getProfile } from '../../services/user.services';
import { logout } from '../../services/auth.services';
import { address_server_short, address_server_socet } from '../../config';
import avatar_logo from '../../assets/png/user_logo.png';
import exitSVG from '../../assets/svg/exit-01.svg';
import profileSVG from '../../assets/svg/profile-01.svg';
import settingsSVG from '../../assets/svg/settings-01.svg';
import usersSVG from '../../assets/svg/users-01.svg';
import { modeContext } from '../Contexts/contexts';
import {
  getNotificationMessage,
  readAllNotification,
  readNotification,
} from '../../services/notification.services';
import Icon from '../../helpers/components/Icon';
import close from '../../assets/svg/close-01.svg';
import { authStore } from 'store/auth.store';
import { observer } from 'mobx-react-lite';

const Header = observer(({ fullMenu, setFullMenu, isVisibleDropdown, setVisibleDropdown }) => {
  const { clear, token } = authStore;
  const { mode } = useContext(modeContext);

  const messagesRef = useRef(null);
  const openMessageRef = useRef(null);

  const [styleButton, setStyleButton] = useState({ transform: 'rotate(90deg)' });
  const [avatar, setAvatar] = useState('');
  const [countNotific, setCountNotific] = useState(null);
  const [notificMessages, setNotificMessages] = useState([]);
  const [openMessages, setOpenMessages] = useState(false);

  function setFM() {
    if (fullMenu) {
      setFullMenu(false);
    } else {
      setFullMenu(true);
    }
  }
  const rootClasses = [classes.Header];
  if (!fullMenu) {
    rootClasses.push(classes.Header_small);
  }
  async function startLogout() {
    const res = await logout();
    if (res) {
      clear();
    }
  }

  useEffect(() => {
    const getProfile_t = async () => {
      const res = await getProfile(0);
      if (res.avatar && res.avatar !== '') setAvatar(address_server_short + res.avatar);
      else setAvatar(avatar_logo);
    };
    getProfile_t();
  }, []);

  useEffect(() => {
    if (!fullMenu) setStyleButton({ transform: 'rotate(-90deg)' });
    else setStyleButton({ transform: 'rotate(90deg)' });
  }, [fullMenu]);

  useEffect(() => {
    const socket = new WebSocket(address_server_socet);

    const message = {
      type: 'token',
      data: token,
    };

    socket.onopen = () => {
      socket.send(JSON.stringify(message));
      console.log('WebSocket открыт');
    };

    socket.onmessage = (event) => {
      setCountNotific(event.data);
    };

    // if (false)
    //   socket.onclose = () => {
    //     console.log('WebSocket закрыт');
    //   };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        messagesRef.current &&
        !messagesRef.current.contains(event.target) &&
        openMessageRef.current &&
        !openMessageRef.current.contains(event.target)
      ) {
        setOpenMessages(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const loadNotificationMessage = async () => {
    const res = await getNotificationMessage();
    setNotificMessages(res.result);
  };

  const handleClick = () => {
    loadNotificationMessage();
    setOpenMessages(true);
  };

  const readMessage = async (val) => {
    await readNotification(val.id_notification);
    await loadNotificationMessage();
  };

  const readAllMessage = async () => {
    await readAllNotification();
    await loadNotificationMessage();
  };

  return (
    <div className={rootClasses.join(' ')}>
      <img
        id={'header_button'}
        className={classes.Header_button_menu}
        alt={'alt'}
        src={'/svg/arrow-01.svg'}
        style={styleButton}
        onClick={() => setFM()}
      />
      <div className={classes.Header_block}>
        <div className={classes.Header_bell_block}>
          <div className={classes.Header_bell} ref={openMessageRef} onClick={handleClick}></div>
          {countNotific && countNotific > 0 && (
            <div className={classes.countNotification}>+{countNotific}</div>
          )}
          {openMessages && (
            <div className={classes.notificWindow} ref={messagesRef}>
              <div className={classes.closeContaiiner}>
                <div>
                  <div className={classes.closeButton} onClick={() => setOpenMessages(false)}>
                    <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
                  </div>
                </div>
              </div>
              <div className={classes.notificTitle}>Уведомления</div>
              <div className={classes.notificBody}>
                {notificMessages &&
                  (notificMessages.length > 0 ? (
                    notificMessages.map((val, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            classes.notificBodyRow +
                            ' ' +
                            (val.active ? classes.notificBodyRowActive : '')
                          }
                          onClick={async () => {
                            await readMessage(val);
                          }}
                        >
                          <div className={classes.notificBodyRowMessage}>{val.message}</div>
                          <div className={classes.notificBodyRowDate}>Дата: {val.created_at}</div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={classes.notificBodyRowEmpty}></div>
                  ))}
              </div>
              <div className={classes.notificFooter}>
                <button
                  className={`button_default save_style`}
                  onClick={async () => {
                    await readAllMessage();
                  }}
                >
                  Прочитать все
                </button>
              </div>
            </div>
          )}
        </div>
        <div
          className={classes.Header_avatar}
          onClick={(e) => {
            e.stopPropagation();
            setVisibleDropdown(!isVisibleDropdown);
          }}
        >
          <img className={classes.Header_avatar_img} src={avatar} alt={'avatar'} />
        </div>
        <button
          className={classes.Header_avatar_arrow}
          onClick={(e) => {
            e.stopPropagation();
            setVisibleDropdown(!isVisibleDropdown);
          }}
        >
          <img src={'/svg/arrow-01.svg'} alt={'search'} />
        </button>
        {isVisibleDropdown ? (
          <div className={classes.dropdownWrapper}>
            <div className={classes.dropdown}>
              <a
                onClick={() => {
                  mode.setPath('Профиль пользователя');
                  setVisibleDropdown(false);
                }}
                className={classes.dropdownItem}
              >
                <img src={profileSVG} className={classes.dropdownImg} />
                Профиль
              </a>
              {mode?.rules?.user?.get && mode?.rules?.user?.get_info && (
                <a
                  href="#"
                  className={classes.dropdownItem}
                  onClick={() => {
                    mode.setPath('Настройки');
                    mode.setType({ mode: 'view', item: '' });
                    mode.setVisibleAdd(false);
                    setVisibleDropdown(false);
                  }}
                >
                  <img src={settingsSVG} className={classes.dropdownImg} />
                  Настройки
                </a>
              )}
              {mode?.rules?.group?.get && (
                <a
                  onClick={() => {
                    mode.setPath('Пользователи');
                    mode.setType({ mode: 'view', item: '' });
                    mode.setVisibleAdd(false);
                    setVisibleDropdown(false);
                  }}
                  className={classes.dropdownItem}
                >
                  <img src={usersSVG} className={classes.dropdownImg} />
                  Пользователи
                </a>
              )}
              <a onClick={() => startLogout()} className={classes.dropdownItem}>
                <img src={exitSVG} className={classes.dropdownImg} />
                Выход
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default Header;
