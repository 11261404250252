import { address_server } from '../config';
import { error } from '../utils/handlerError';
import moment from 'moment';
import { transliterate } from '../helpers/utils';
import { authStore } from 'store/auth.store';

export async function getGroups() {
  const token = authStore.token;
  const res = await (await fetch(address_server + 'user/groups?token=' + token)).json();
  if (res.success) {
    return res.result;
  }
  error('getGroups', res);
  return [];
}

export async function getUsers(idGroup, page, sorting, filter) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  if (page !== null && page !== undefined && page !== '') formData.append('page', page);
  if (filter != null && filter != '') formData.append('search', filter);
  formData.append('group', idGroup);
  if (sorting != '') formData.append('sorting', sorting);

  const res = await (
    await fetch(address_server + 'user/users/table', {
      method: 'POST',
      body: formData,
    })
  ).json();

  return res;
}

export async function getAllUsers() {
  const token = authStore.token;
  const url = address_server + 'user/users?token=' + token + '&mode=list';
  const res = await (await fetch(url)).json();
  if (res.success) {
    return res.result.groups.reduce((acc, item) => {
      acc = [...acc, ...item?.users];
      return acc;
    }, []);
  }
  error('getUsers', res);
  return [];
}

export async function newGroup(group, newName) {
  const token = authStore.token;
  let res;
  if (group !== 0)
    res = await (
      await fetch(address_server + 'user/group', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, id: group, name: newName }),
      })
    ).json();
  else
    res = await (
      await fetch(address_server + 'user/group', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, name: newName }),
      })
    ).json();
  return res;
  //error('newGroup',res);
}

export async function deleteGroup(group) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'user/group/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, id: group }),
    })
  ).json();
  return res;
  //if(res.success){
  //    return res
  //}
  //error('deleteGroup',res);
  //return []
}

export async function getProfile(id) {
  const token = authStore.token;
  let res = '';
  if (id !== 0)
    res = await (await fetch(address_server + 'user/profile?token=' + token + '&id=' + id)).json();
  else res = await (await fetch(address_server + 'user/profile?token=' + token)).json();
  if (res.success) {
    return res.result;
  }
  error('getProfile', res);
  return [];
}

export async function editProfile(id, params) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('group', params.group);
  formData.append('fio', params.fio);
  formData.append('email', params.email);
  formData.append('status', params.status);
  formData.append('position', params.post);
  formData.append('birth', moment(params.birth).format('DD.MM.YYYY'));
  formData.append('comments', params.comments ? params.comments : '');
  formData.append('rules', JSON.stringify(params.rules));
  formData.append('reports', JSON.stringify(params.reports));

  if (params.password !== '') formData.append('password', params.password);

  if (document.getElementById('inputAvatar').files.length === 0) {
    if (params.avatar) formData.append('avatar', params.avatar);
  } else {
    formData.append('avatar', document.getElementById('inputAvatar').files[0]);
  }

  let res;
  if (id !== 0) {
    formData.append('id', id);
    res = await (
      await fetch(address_server + 'user/profile', {
        method: 'POST',
        body: formData,
      })
    ).json();
  } else {
    res = await (
      await fetch(address_server + 'user/profile', {
        method: 'POST',
        body: formData,
      })
    ).json();
  }
  return res;
  //error('editProfile',res);
  //return []
}

export async function editProfileShort(params) {
  const token = authStore.token;
  let birth = '';
  if (params.birth !== '')
    birth =
      params.birth.split('-')[2] +
      '.' +
      params.birth.split('-')[1] +
      '.' +
      params.birth.split('-')[0];
  const formData = new FormData();
  formData.append('token', token);
  formData.append('fio', params.fio);
  //formData.append('position', params.position);
  formData.append('birth', birth);
  //formData.append('email', params.email);
  if (document.getElementById('inputAvatar').files.length === 0) {
    if (params.avatar === '') {
      formData.append('avatar', '');
    }
  } else formData.append('avatar', document.getElementById('inputAvatar').files[0]);
  //formData.append('id', id);
  const res = await (
    await fetch(address_server + 'user/profile', {
      method: 'POST',
      body: formData,
    })
  ).json();
  return res;
}

export async function rePassword(password, newPassword) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'user/re-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, password: password, new_password: newPassword }),
    })
  ).json();

  if (res.success) {
    return res;
  }
  error('rePassword', res);
  return false;
}

export async function deleteUser(id) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'user/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, id: id }),
    })
  ).json();

  if (res.success) {
    return res;
  }
  error('deleteUser', res);
  return [];
}

export async function getUserReports(section) {
  const token = authStore.token;
  const res = await (
    await fetch(address_server + 'user/reports?token=' + token + '&section=' + section)
  ).json();
  if (res.success) {
    return res.result;
  }
  error('getUserReports', res);
  return [];
}

export async function sendSupportMessage(params) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('name', params.name);
  formData.append('email', params.email);
  formData.append('theme', params.theme);

  if (params.files.length > 0) {
    params.files?.map((file, index) => {
      formData.append('files', file, transliterate(file.name));
    });
  }

  formData.append('message', params.message);

  const res = await (
    await fetch(address_server + 'support', {
      method: 'POST',
      body: formData,
    })
  ).json();

  return res;
}

export async function setUserSettingLimits(settings) {
  const token = authStore.token;
  const formData = new FormData();
  formData.append('token', token);
  formData.append('settings', JSON.stringify(settings));

  const res = await (
    await fetch(address_server + 'settings/user/upsert', {
      method: 'POST',
      body: formData,
    })
  ).json();

  return res;
}
