import { FetchState } from 'consts/axios.consts';
import { autorun, makeAutoObservable, runInAction } from 'mobx';
import {
  getListEmail,
  login,
  recovery,
  setNewPasswordRecovery,
  verificationRecovery,
} from 'services/auth.services';
import { setCookie } from 'utils/fun_cookie';

const NAME = 'auth-store';

export class AuthStore {
  token = undefined;
  emailList = [];
  fetchLogin = FetchState.NotLoading;
  fetchRecovery = FetchState.NotLoading;
  fetchSendMail = FetchState.NotLoading;
  fetchSetNewPassword = FetchState.NotLoading;
  msgLoginError = '';
  msgSendMailError = '';
  isNotFoundToken = false;
  recoveryToken = undefined;
  isNewPassword = false;

  constructor() {
    makeAutoObservable(this);

    const storeJSON = localStorage.getItem(NAME);

    if (storeJSON) Object.assign(this, JSON.parse(storeJSON));

    autorun(() => {
      const obj = JSON.parse(JSON.stringify(this));
      localStorage.setItem(NAME, JSON.stringify(obj));
    });

    window.addEventListener('storage', this._updateStore);
  }

  _updateStore = (e) => {
    if (e.key === NAME) {
      const storeJSON = localStorage.getItem(NAME);

      if (storeJSON) Object.assign(this, JSON.parse(storeJSON));
    }
  };

  _dispose = () => {
    window.removeEventListener('storage', this._updateStore);
  };

  clear = () => {
    runInAction(() => {
      this.token = '';
      this.fetchLogin = FetchState.NotLoading;
      this.fetchRecovery = FetchState.NotLoading;
      this.fetchSendMail = FetchState.NotLoading;
      this.msgSendMailError = '';
      this.msgLoginError = '';
      setCookie('token', '');
    });
  };

  setToken = (token) => {
    runInAction(() => {
      this.token = token;
      setCookie('token', token);
    });
  };

  clearToken = () => {
    runInAction(() => {
      this.token = '';
      setCookie('token', '');
    });
  };

  setEmailList = async () => {
    const res = await getListEmail();
    let t = [];
    for (let i = 0; i < res.length; i++) {
      let tt = res[i].users.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      t.push({ group: res[i].group, users: tt });
    }
    runInAction(() => {
      this.emailList = t;
    });
  };

  startLogin = async (email, password) => {
    runInAction(() => {
      this.fetchLogin = FetchState.Pending;
    });

    const res = await login(email, password);
    runInAction(() => {
      if (res.success) {
        if (!res.result.token) {
          this.isNotFoundToken = true;
          this.fetchLogin = FetchState.Rejected;
        } else {
          this.token = res.result.token;
          this.fetchLogin = FetchState.Fulfilled;
        }
      } else {
        this.fetchLogin = FetchState.Rejected;
        if (res.error_code === 2017) {
          this.msgLoginError = 'Неверная лицензия';
        } else if (res.error_code === 2018) {
          this.msgLoginError = 'Максимальное количество пользователей в системе';
        } else {
          this.msgLoginError = 'Произошла ошибка авторизации, обратитесь к администратору';
        }
      }
    });
  };

  setRecoveryToken = async (token) => {
    runInAction(() => {
      this.fetchRecovery = FetchState.Pending;
    });

    const res = await verificationRecovery(token);

    runInAction(() => {
      if (res.success) {
        this.fetchRecovery = FetchState.Fulfilled;
        this.recoveryToken = res.result;
        this.isNewPassword = true;
      } else {
        this.fetchRecovery = FetchState.Rejected;
      }
    });
  };

  sendMail = async (email) => {
    runInAction(() => {
      this.fetchSendMail = FetchState.Pending;
    });
    const res = await recovery(email);
    runInAction(() => {
      if (res?.success) {
        this.fetchSendMail = FetchState.Fulfilled;
      } else {
        this.fetchSendMail = FetchState.Rejected;
        if (res.error_code === 1001 || res.error_code === 2001) {
          this.msgSendMailError = 'Пользователь не найден';
          return;
        }
        this.msgSendMailError = 'Обратитесь к Администратору';
      }
    });
  };

  setNewPassword = async (password) => {
    runInAction(() => {
      this.fetchSetNewPassword = FetchState.Pending;
    });

    const res = await setNewPasswordRecovery(this.recoveryToken, password);

    runInAction(() => {
      if (res?.success) this.fetchSetNewPassword = FetchState.Fulfilled;
      else this.fetchSetNewPassword = FetchState.Rejected;
    });
  };
}

export const authStore = new AuthStore();
