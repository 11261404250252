import React, { useState } from 'react';
import classes from './workRightUserAddMenu.module.css';
import classes2 from '../../WorkRightCommon/WorkRight/workRight.module.css';

const WorkRightUserAddMenuReportsItemItem = ({ item, reports, setReports, openGroup }) => {
  const [value, setValue] = useState(item.value);

  React.useEffect(() => {
    setValue(item.value);
  }, [item]);

  function setSettingValue(v) {
    let s = JSON.parse(JSON.stringify(reports));
    for (let j = 0; j < s.length; j++) {
      for (let i = 0; i < s[j].value.length; i++) {
        if (s[j].value[i].id === item.id) {
          s[j].value[i].value = v;
          break;
        }
      }
    }
    setReports(s);
  }
  return (
    <div className={classes.ReportsItemBlockItem} style={openGroup}>
      <div className={classes.ReportsItemBlockItemText}>
        {item.name.length > 100 ? item.name.slice(0, 100) + '...' : item.name}
      </div>
      <label
        className={classes2.workRightSwitch + ' ' + classes.workRightAddMenuUserBlock4BlockInput}
      >
        <input
          type={'checkbox'}
          checked={value}
          onChange={(e) => {
            setValue(e.target.checked);
            setSettingValue(e.target.checked);
          }}
        />
        <span className={classes2.workRightSlider}></span>
      </label>
    </div>
  );
};

export default WorkRightUserAddMenuReportsItemItem;
