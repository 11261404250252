import { makeAutoObservable, toJS } from 'mobx';
import { createContext } from 'react';
import { editSample, getSample } from '../services/sample.services';
import { getStorage, getStoragePacks } from '../services/storage.services';
import { getListPacks } from '../services/list.services';
import dayjs from 'dayjs';

class AppStore {
  constructor() {
    makeAutoObservable(this, {});
  }

  selectedSample = {};
  selectedSampleId = 0;
  samples = {};
  allPacks = [];
  availablePacks = [];
  selectedPack = {};
  tableLinkFilterFromSampleSource = {
    id_source: null,
  };

  getStore = () => {
    return {
      selectedSample: this.selectedSample,
      selectedSampleId: this.selectedSampleId,
      samples: this.samples,
      allPacks: this.allPacks,
      availablePacks: this.availablePacks,
      selectedPack: this.selectedPack,
    };
  };

  setStore = (data) => {
    this.selectedSample = data.selectedSample;
    this.selectedSampleId = data.selectedSampleId;
    this.samples = data.samples;
    this.allPacks = data.allPacks;
    this.availablePacks = data.availablePacks;
    this.selectedPack = data.selectedPack;
  };

  createSample = async () => {
    return await editSample(0, {
      pack: this.selectedSample.pack,
      line: this.selectedSample.line,
      column: this.selectedSample.column,

      type: this.selectedSample.type,
      description: this.selectedSample.description,
      catalogs: this.selectedSample.catalogs,
      patient_description: this.selectedSample.patient_description,
      name: this.selectedSample.name ?? '',
      barcodes: JSON.stringify(this.selectedSample.barcodes),
      storage: this.selectedSample.storage,
      date: this.selectedSample.date,

      unit: this.selectedSample.unit,
      volume: this.selectedSample.volume,
    });
  };

  updateSample = async () => {
    // const dateCreate = `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} `;

    return await editSample(this.selectedSampleId, {
      pack: this.selectedSample.pack,
      line: this.selectedSample.line,
      column: this.selectedSample.column,

      type: this.selectedSample.type,
      description: this.selectedSample.description,
      catalogs: JSON.stringify(this.selectedSample.catalogs),
      patient_description: JSON.stringify(this.selectedSample.patient_description),
      name: this.selectedSample.name ?? '',
      barcodes: JSON.stringify(this.selectedSample.barcodes),
      storage: this.selectedSample.storage,
      data_create: dayjs(this.selectedSample.date).format('YYYY-MM-DD HH:mm:ss'),

      unit: this.selectedSample.unit,
      volume: this.selectedSample.volume,
    });
  };

  setSelectedSample = async (id, view) => {
    this.selectedSampleId = id;
    const sample = await getSample(id, 'view');
    await this.setAvailablePacks(await getStoragePacks(sample.locations.id));
    this.selectedSample = sample;
    const type = sample.type;
    this.selectedSample.type = type.id;
    this.selectedSample.selectedType = type;

    this.selectedSample.unit = sample.volume_all.unit;
    this.selectedSample.volume = sample.volume_all.amount;

    this.selectedSample.storage = sample.locations.id;
    this.selectedSample.location = sample.locations;
    this.selectedSample.patient_description = sample.patient_description;
    this.selectedSample.path = sample.locations?.path;
    this.selectedSample.pack = sample.locations?.pack.id;
    this.selectedSample.column = sample.locations?.pack.column;
    this.selectedSample.line = sample.locations?.pack.line;
    this.selectedSample.date = sample.date_create;
    this.selectedSample.barcodes = sample.barcodes?.reduce((acc, item) => {
      acc = [
        ...acc,
        {
          id: item.type,
          value: item.value,
        },
      ];
      return acc;
    }, []);

    await this.changeSampleField('storage', sample.locations.id);
    await this.setSelectedPack(sample.locations?.pack.id);
  };

  changeSampleField = async (name, val) => {
    if (name !== 'patient_description') this.selectedSample[name] = val;

    if (name === 'location' && !!val) {
      this.selectedPack = {};
      this.selectedSample.line = '';
      this.selectedSample.column = '';

      await this.setAvailablePacks(await getStoragePacks(val.id));
      await new Promise(async (resolve) => {
        resolve(await getStorage(val.id));
      }).then((result) => {
        // this.selectedSample.location = result.location;
        this.selectedSample['storage'] = val.id;
        this.selectedSample['path'] = `${result.location?.path} ${result.name}`;
      });
      await this.setAllPacks();
    }
    if (name === 'selectedType') {
      this.selectedSample.type = val.id;
    }
    if (name === 'storage' && !!val) {
      this.selectedPack = {};
      const storage = await getStorage(val);
      this.selectedSample.location = storage.location;
      this.selectedSample.path = `${storage.location?.path} ${storage.name}`;
      await this.setAvailablePacks(await getStoragePacks(val));
      await this.setAllPacks();
    }
    if (name === 'catalogs') {
      this.selectedSample.catalogs = val;
    }

    if (name === 'patient_description') {
      if (!this.selectedSample.patient_description) this.selectedSample.patient_description = [];

      if (val === null) {
        this.selectedSample.patient_description = [];
        return;
      }

      let description = this.selectedSample.patient_description.find((i) => i.id === val.id);

      if (description) {
        description.value = val.value;
      } else {
        this.selectedSample.patient_description.push(val);
      }
    }
    if (name === 'pack') {
      await this.setSelectedPack();
    }

    if (name === 'packId') {
      this.selectedSample.pack = val;
    }
    if (name === 'date') {
      this.selectedSample[name] = val[0].value;
    }
  };

  setEmptySample = () => {
    this.selectedSample = {
      pack: null,
      line: '',
      column: '',

      description: '',
      catalogs: [],
      patient_description: [],
      name: null,
      barcodes: [],
      storage: null,
      date: null,

      unit: null,
      volume: null,
    };
    this.selectedSampleId = 0;
  };

  setAllPacks = async () => {
    if (!this.allPacks.length) {
      this.allPacks = await getListPacks();
    }
  };

  setAvailablePacks = async (arr) => {
    this.availablePacks = arr;
  };

  setSelectedPack = async () => {
    let pack = this.availablePacks.find((pack) => pack.id === this.selectedSample.pack);

    if (pack) {
      pack.columns = pack.structura.columns.reduce((acc, item) => {
        acc.push({
          id: item,
          name: item,
        });
        return acc;
      }, []);

      pack.lines = pack.structura.lines.reduce((acc, item) => {
        acc.push({
          id: item,
          name: item,
        });
        return acc;
      }, []);
    }

    this.selectedPack = pack;
  };

  clearSelectedSample = () => {
    this.selectedSample = {};
    this.selectedSampleId = 0;
    this.selectedPack = null;
  };

  setTableLinkFilterFromSampleSource = (id) => {
    this.tableLinkFilterFromSampleSource.id_source = id;
  };
}

export const SamplesStore = createContext(new AppStore());
