import { makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';
import { deleteLicense, getLicensesCourse, saveLicense } from '../../../services/license.services';

class AppStore {
  selectLicenseItem = null;

  constructor() {
    makeAutoObservable(this, {
      selectLicenseItem: observable,
    });
  }

  setSelectLicenseItem = (item) => {
    this.selectLicenseItem = item;
  };

  clearSelectLicenseItem = () => {
    this.selectLicenseItem = null;
  };

  createNewLicense = () => {
    this.selectLicenseItem = {
      version: '',
      company: '',
      extension: 0,
      users: 0,
      expire_date: '',
      support: null,
      id: null,
      key: null,
    };
  };

  saveLicense = async (token) => {
    const res = await saveLicense(token, this.selectLicenseItem);
    this.selectLicenseItem = null;
    return res;
  };

  getLicensesSource = async (token, filter, sortField) => {
    const res = await getLicensesCourse(token, filter, sortField);
    return res;
  };

  changeField = (key, value) => {
    this.selectLicenseItem[key] = value;
  };

  deleteItem = async (token, id) => {
    return await deleteLicense(token, id);
  };
}

export const LicenseStore = createContext(new AppStore());
