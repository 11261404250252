import DateRangePicker from 'rsuite/DateRangePicker';
import { format, parseISO } from 'date-fns';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import ru from 'date-fns/locale/ru';
import classes from './css/PeriodTimePicker.module.css';
import './css/styles.css';
import { ArrowDownIcon } from '../../../helpers/icons';
import Icon from '../../../helpers/components/Icon';
import { useState } from 'react';
import moment from 'moment';
import { COLORS } from '../../../config';
import dayjs from 'dayjs';

export const PeriodTimePicker = ({
  selected,
  onChange,
  isPeriodWithTime = false,
  format = 'YYYY-MM-DD HH:mm:ss',
}) => {
  const [opened, setOpened] = useState(false);

  const predefinedBottomRanges = [
    { label: 'Сегодня', value: [new Date(), new Date()] },
    { label: 'Вчера', value: [addDays(new Date(), -1), addDays(new Date(), -1)] },
    { label: 'Последние 7 дней', value: [subDays(new Date(), 6), new Date()] },
    { label: 'Последние 14 дней', value: [subDays(new Date(), 13), new Date()] },
    { label: 'Последние 30 дней', value: [subDays(new Date(), 29), new Date()] },
    { label: 'Этот месяц', value: [startOfMonth(new Date()), new Date()] },
    { label: 'Этот год', value: [new Date(new Date().getFullYear(), 0, 1), new Date()] },
    { label: 'Lifetime', value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()] },
  ];

  const locale = {
    ok: 'Готово',
    hours: 'часы',
    minutes: 'минуты',
    seconds: 'секунды',
    monday: 'пн',
    tuesday: 'вт',
    wednesday: 'ср',
    thursday: 'чт',
    friday: 'пт',
    saturday: 'сб',
    sunday: 'вс',
  };

  const monthName = (month) => {
    const monthNameSplitted = moment(month).locale('ru').format('MMMM YYYY').split('');
    const uppercaseFirstWord = monthNameSplitted[0].toUpperCase();
    const rest = [...monthNameSplitted];
    rest.splice(0, 1);

    return [uppercaseFirstWord, ...rest].join('');
  };

  return (
    <div className={`${classes.periodTimeContainer} `}>
      <DateRangePicker
        value={
          selected
            ? [
                selected.setting?.from
                  ? new Date(dayjs(selected.setting?.from).format('YYYY-MM-DD HH:mm:ss'))
                  : '',
                new Date(dayjs(selected.setting?.to).format('YYYY-MM-DD HH:mm:ss')),
              ]
            : null
        }
        onChange={(value) => {
          onChange({
            value: `${dayjs(value[0]).format(isPeriodWithTime ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD')} - ${dayjs(value[1]).format(isPeriodWithTime ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD')}`,
            setting: {
              from: new Date(value[0]),
              to: new Date(value[1]),
            },
          });
        }}
        ranges={predefinedBottomRanges}
        format={isPeriodWithTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'}
        placement={'autoVerticalEnd'}
        preventOverflow={true}
        size={'sm'}
        placeholder={'Выберите период..'}
        block
        isoWeek
        locale={locale}
        onExit={() => setOpened(false)}
        onEnter={() => setOpened(true)}
        renderTitle={(month) => monthName(month)}
      />
      <Icon
        src={ArrowDownIcon}
        color={COLORS.text_primary_brand}
        width={12}
        height={7}
        className={classes.arrowIcon + ` ${opened ? classes.opened : ''}`}
      />
    </div>
  );
};
