import { useRef, useState } from 'react';
import Icon from '../../../helpers/components/Icon';
import styles from '../../Widgets/Modal/modal.module.css';
import close from '../../../assets/svg/close-01.svg';
import classes from './ModalPackAdd.module.css';
import { postSavePack } from '../../../services/pucks.services';

const ModalPackAdd = ({ openModal, packData, setWidget, onClose }) => {
  const selfRef = useRef(null);
  const modalBGRef = useRef(null);
  const [data, setData] = useState(JSON.parse(JSON.stringify(packData)));

  const closeModalTappedOnBg = (ev) => {
    if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
      onClose();
    }
  };

  const changeField = (value, key) => {
    let tmpData = JSON.parse(JSON.stringify(data));
    tmpData[key] = value;
    setData(tmpData);
  };

  const savePuck = async () => {
    const res = await postSavePack(data);

    if (res.success) {
      setWidget({
        status: 'Ok',
        text: 'Штатив успешно добавлен!',
        fun: async () => {},
      });
      onClose();
    } else {
      setWidget({
        status: 'Error',
        text: res.error_code,
        fun: async () => {},
      });
    }
  };

  const modal = (
    <>
      <div
        ref={modalBGRef}
        onClick={(ev) => closeModalTappedOnBg(ev)}
        className={styles.modalBG}
        style={{ zIndex: 400 }}
      >
        <div className={styles.model_copy} ref={selfRef}>
          <div className={styles.header}>
            <div className={styles.closeButton} onClick={() => onClose()}>
              <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
            </div>
          </div>
          <div className={styles.body} style={{ margin: '10px 20px' }}>
            <div className={styles.title}>Добавить штатив</div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Наименование</p>
              <input
                className={classes.infoItemValue}
                type="text"
                value={data['name']}
                onChange={(e) => {
                  changeField(e.target.value, 'name');
                }}
              />
            </div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Столбцов</p>
              <input
                className={classes.infoItemValue}
                type="text"
                value={data['column']}
                onChange={(e) => {
                  changeField(e.target.value, 'column');
                }}
              />
            </div>

            <div className={classes.infoItem}>
              <p className={classes.infoItemName}>Строк</p>
              <input
                className={classes.infoItemValue}
                type="text"
                value={data['lines']}
                onChange={(e) => {
                  changeField(e.target.value, 'lines');
                }}
              />
            </div>

            <div className={classes.infoItem_column}>
              <p className={classes.infoItemName + ' ' + classes.mb5}>
                Обозначение столбцов, через запятую
              </p>
              <div className={classes.bgWhite}>
                <input
                  className={classes.infoItemValue}
                  type="text"
                  value={data['column_name']}
                  onChange={(e) => {
                    changeField(e.target.value, 'column_name');
                  }}
                />
              </div>
            </div>

            <div className={classes.infoItem_column}>
              <p className={classes.infoItemName + ' ' + classes.mb5}>
                Обозначение строк, через запятую
              </p>
              <div className={classes.bgWhite}>
                <input
                  className={classes.infoItemValue}
                  type="text"
                  value={data['lines_name']}
                  onChange={(e) => {
                    changeField(e.target.value, 'lines_name');
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button
              className={`button_default save_style`}
              onClick={() => {
                savePuck();
              }}
            >
              Сохранить
            </button>
            <button className={`button_default cancel_style`} onClick={() => onClose()}>
              Отмена
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return openModal ? modal : <></>;
};

export default ModalPackAdd;
